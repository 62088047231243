<template>
    <section>
        <Banner v-if="['home', 'impressum', 'datenschutz'].includes($route.name)" :payload="{
            image: this.$store.getters.settings.dash_image || '',
            title: this.$store.getters.settings.dash_title || '',
            desc: this.$store.getters.settings.dash_content || '',
            btn_title: this.$store.getters.settings.dash_btn_title || '',
            btn_url: this.$store.getters.settings.dash_btn_url || '',
        }" />

        <ImageMenu v-if="$route.name == 'home'" :data="menus" :col="4" />

        <transition name="fade" mode="out-in">
            <router-view></router-view>
        </transition>
    </section>
</template>

<script>
import Banner from "./Banner.vue";
import ImageMenu from "../../../widgets/ImageMenuNew.vue";

export default {
    components:{
        Banner,
        ImageMenu,
    }, 
    data(){
        return {
            menus: [],
            image: this.$store.getters.settings.family1Image || '',
            image2: this.$store.getters.settings.family2Image || '',
        }
    },

    mounted(){
        let menu = []
        let ids = []
        let settings = this.$store.getters.settings
        for (const key in settings) {
            if (Object.hasOwnProperty.call(settings, key)) {
                if (key.slice(0, 9) == 'menuImage' && !ids.includes(key)){ // If Not in ids
                    let splitKey = key.split('_')
                    let id = splitKey[1]
                    ids.push(`menuImage_${id}_img`)
                    ids.push(`menuImage_${id}_t1`)
                    ids.push(`menuImage_${id}_t2`)
                    ids.push(`menuImage_${id}_route`)

                    menu.push({
                        photo: this.getSetting(settings, `menuImage_${id}_img`),
                        title1: this.getSetting(settings, `menuImage_${id}_t1`),
                        title2: this.getSetting(settings, `menuImage_${id}_t2`),
                        route: this.getSetting(settings, `menuImage_${id}_route`),
                        params: {}
                    })
                }
            }
        }
        this.menus = menu
    },

    methods:{
        getSetting(resp, key){
			for (const k in resp) {
				if (Object.hasOwnProperty.call(resp, k)) {
					const value = resp[k];
					if (k == key) return value
				}
			}
		},
    }
}
</script>