<template>
    <section>
      <Header @open-login-modal="showLogin = true" @open-register-modal="showRegister = true" />

      <v-container class="max-width">
        <TextWithImage
            :cols="[8,4]"
            image-col="2"
            :title="payload.title"
            :text="payload.content"
            image="/assets/img/home/5.png"
            image-width="500"
            button=""
        >
        </TextWithImage>
<!--        <v-row class="mt-10">-->
<!--          <v-col cols="12">-->
<!--            <h1>{{ payload.title }}</h1>-->
<!--          </v-col>-->
<!--        </v-row>-->
<!--        <v-row>-->
<!--          <v-col cols="12" class="main-font">-->
<!--            <div v-html="payload.content"></div>-->
<!--          </v-col>-->
<!--        </v-row>-->
      </v-container>

      <Footer></Footer>

      <login-dialog @close-login-modal="showLogin = false" @open-register-modal="showRegister = true" v-model="showLogin"></login-dialog>
      <register-dialog @close-register-modal="showRegister = false" @open-login-modal="showLogin = true" v-model="showRegister"></register-dialog>

    </section>
</template>

<script>

import Header from "./Header.vue";
import TextArea from "../../widgets/TextArea.vue";
import Footer from "../../components/public/Footer.vue";
import LoginDialog from "../../widgets/LoginDialog.vue";
import RegisterDialog from "../../widgets/RegisterDialog.vue";
import TextWithImage from "../../widgets/TextWithImage";

export default {
    components:{
      TextWithImage,
      Header,
      TextArea,
      Footer,
      LoginDialog,
      RegisterDialog
    }, 
    data(){
        return {
            payload: {
                title: this.$t('impressum'),
                content: this.$store.getters.settings.impressum || '',
                icon: null,
                photo: null,
                images: null,
                title2: null,                                
                margin_top: '0',
                padding: '40px 0 40px'
            },

            payload_4: {
                title: this.$store.getters.settings.txt_area_4_title || '',
                content: this.$store.getters.settings.txt_area_4_content || '',
                icon: null,
                images: null,
                title2: this.$store.getters.settings.top_footer || '',
                margin_top: '11px',
                padding: '40px 0 0'
            },
          showLogin: false,
          showRegister: false
        }
    },
    created() {
      this.setMetaTags()
    },
}
</script>