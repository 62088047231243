<template>
<section>
<!--    <v-container>-->
        <v-row>
          <v-col cols="12" md="3" sm="6" v-for="product in products" :key="product.id">
            <v-hover
                v-slot="{ hover }"
            >
              <v-card
                  :elevation="hover ? 12 : 4"
                  class="mx-auto"
                  :href="product.link"
                  :target="product.link.includes('up-geras24') ? '_blank' : ''"
              >
                <v-img
                    class="white--text align-end"
                    :src="product.image"
                    height="280"
                >
                </v-img>

                <v-card-text class="text-center bg-turquoise text-white font-weight-bold">
                  {{ product.title }}
                </v-card-text>

              </v-card>
            </v-hover>
          </v-col>
        </v-row>
<!--    </v-container>-->
</section>
</template>

<script>
export default {
  props: ['exclude'],
  data(){
    return {
      products: [
        {
          name: 'persoenliche-botschaft',
          title: 'Persönliche Botschaft',
          description: 'Manches sagt man viel zu selten oder aus verschiedenen Gründen gar nie zu Lebzeiten: Ein gutgemeinter Rat, eine Weisheit, vielleicht sogar die Bitte um Vergebung, eine Liebeserklärung oder auch nur das lange behütete Geheimrezept für den weltbesten Apfelkuchen. Im Bereich Persönliche Botschaft hast du die Möglichkeit, individuelle Nachrichten zu hinterlassen – sei es für deinen Partner, deine Kinder oder deine besten Freunde. Ob klassisch als Brief oder in Form eines Videos, hier kannst du alles hochladen, was du deinem engsten Kreis noch auf den Weg geben möchtest. Ebenso können Fotos hochgeladen und von dir kommentiert werden. Änderungen kannst du jederzeit und von überall vornehmen. Und deine Lieben haben Erinnerungen, die niemals verloren gehen, sondern auf ewig bleiben.',
          image: '/assets/img/home/1.png',
          link: '/produkte/persoenliche-botschaft'
        },
        {
          name: 'checkliste',
          title: 'Die Checkliste',
          description: 'In unserem Leben schließen wir so einige Versicherungen ab – gewiss auch solche, die den Tod betreffen. Mithilfe unserer Checkliste kannst du nachprüfen, ob du für deine Liebsten bereits ausreichend vorgesorgt und eine optimale Absicherung in die Wege geleitet hast.\n' +
              'Ebenso findest du hier eine Checkliste, die dir im Todesfall eines Familienmitgliedes nützlich sein kann. Verstirbt jemand aus dem engsten Kreis, müssen trotz der Trauer viele bürokratische Angelegenheiten geklärt werden. In dieser emotionalen Ausnahmesituation droht die Bürokratie uns meist zu überfordern. Die Liste dient bei der Bewältigung von Formalitäten als geeignete Hilfestellung. Du findest in ihr alle potentiell bestehenden Versicherungen, Verträge, Abonnements sowie Mitgliedschaften samt Fristen und besonderen Bedingungen kompakt und übersichtlich aufgestellt. Auf diesem Wege gelingt eine schnelle und strukturierte Abarbeitung der nötigen bürokratischen Dinge.',
          image: '/assets/img/home/2.png',
          link: '/produkte/checkliste'
        },
        {
          name: 'tresor',
          title: 'Dein Tresor',
          description: 'Nach einem Todesfall beginnt für die Hinterbliebenen die oftmals verzweifelte Suche nach den notwendigen Dokumenten und Ordnern. Ob Testament, Lebensversicherung, Verträge, Konten, Vollmachten oder Mitgliedschaften – all diese Unterlagen müssen für bürokratische Angelegenheiten möglichst zügig griffbereit sein. Für die Angehörigen entwickelt sich der Papierkram dabei häufig zu einem organisatorischen Albtraum. Nichts ist schlimmer, als in all der Trauer mit zahlreichen offenen Fragen zu bürokratischen Angelegenheiten konfrontiert zu werden. Um deinen Lieben den zeitlichen Aufwand für diese unangenehmen, aber doch notwendigen Aufgaben zu verkürzen, kannst du im Tresor bei Geras24 all deine wichtigen Dokumente digital an einem Ort hinterlegen und jederzeit verwalten. Sicherheit hat bei uns oberste Priorität. Deine Daten sind stets vor Außenstehenden geschützt, denn sie werden bei Geras24 sensibel behandelt und zudem hochverschlüsselt gespeichert. Nur die von dir erwählten Vertrauenspersonen erhalten bei Bedarf schnellen und einfachen Zugriff.',
          image: '/assets/img/home/3.png',
          link: '/produkte/tresor'
        },
        {
          name: 'der-letzte-wunsch',
          title: 'Der letzte Wunsch',
          description: 'Über den eigenen Tod oder gar die eigene Bestattung nachzudenken, ist weder ein leichtes noch ein angenehmes Unterfangen. Doch deine Lieben werden es letztendlich sein, die an diesem Tag Abschied von dir nehmen müssen. In der Regel sind auch sie diejenigen, die trotz Trauer deine Beerdigung planen müssen und mit den Fragen des Bestatters konfrontiert werden. Wäre es da nicht gut, wenn du selbst bereits vorab regeln könntest, wie deine Beerdigung verlaufen und wo deine Erinnerungsstätte liegen soll? Und wäre es nicht schön zu wissen, dass deine Lieben später Gewissheit darüber haben, alles nach deinen Wünschen gestaltet zu haben? Wenn du das Meer liebst, kommt eventuell eine Seebestattung für dich infrage. Oder bevorzugst du einen Friedwald? Oder wäre es dein sehnlichster Wunsch, in deiner Heimat begraben zu werden? Wen hättest du gerne bei deinem Abschied dabei, welches Lied soll gespielt und welches Essen serviert werden? Du hast die Möglichkeit, frühzeitig Entscheidungen zu fällen, sodass deine Familie sich nicht mehr mit solchen Fragen auseinandersetzen muss. All deine Überlegungen kannst du im Bereich Der letzte Wunsch hinterlegen und selbstverständlich jederzeit bearbeiten. Auf diesem Wege übernimmst du einen Großteil der Organisation und deine Lieben können in Ruhe Abschied nehmen.',
          image: '/assets/img/home/4.png',
          link: '/produkte/der-letzte-wunsch'
        },
        {
          name: 'up',
          title: 'Geras24 Up',
          description: 'Soziale Medien bieten uns die Möglichkeit, Erlebnisse, Erfahrungen und Augenblicke mit den Menschen zu teilen, denen wir uns verbunden fühlen – unabhängig von Zeit und Ort. Mit dem digitalen Himmel bei Geras24 wollen wir ebenso eine solche Verbundenheit schaffen. Up ist ein Raum der liebevollen und dankbaren Erinnerung. Der digitale Himmel kann mit Dingen gefüllt werden, die wir lieben. Mit Bildern und Erlebnissen, die uns ausmachen. Wie möchten wir in Erinnerung behalten werden? Was soll für uns bleiben? Was möchten wir unseren Lieben hinterlassen und welchen Rat noch mitgeben? \n' +
              'Menschen, die wir zurücklassen, geben wir mit Up einen Ort, an dem man sich zusammen erinnern kann – sei es, um gemeinsam Erlebtes zu teilen oder in der Gemeinschaft zu gedenken. So kann am Trauertag beispielsweise eine Kerze angezündet oder sich in Dankbarkeit einander zugeprostet werden – jeder dort, wo er ist, und doch verbunden in Gedanken.\n',
          image: '/assets/img/home/5.png',
          link: 'https://www.up-geras24.de/'
        }
      ]
    }
  },
  mounted() {
    let self = this

    this.products = this.products.filter(function( obj ) {
      return obj.name !== self.exclude;
    });
  },
};
</script>