<template>
  <v-container
      fluid
      tag="section"
      id="last-wish"
  >
    <!-- Main Section -->
    <v-row justify="center" class="pt-3 px-8 pb-8">
      <v-col cols="12">
        <p class="text-h5 main-font-bold main-title-color">{{ $t('last_wish_title') }}</p>
        <div class="subtitle-1 font-weight-light main-grey">
          {{ $t('last_wish_desc') }}
        </div>
      </v-col>
    </v-row>

    <v-row justify="center" class="pt-3 px-8 pb-8">
      <v-col cols="12">
        <p class="text-h6 main-font-bold main-color">Die schönste Beerdigung der Welt</p>
        <p>Die schönste Beerdigung der Welt ist die, die man sich selbst schenkt. Jeder Mensch hat einen anderen Weg, um mit dem Tod umzugehen. Für mich ist es wichtig, dass ich meiner Familie und meinen Freunden zeige, wie sehr ich sie liebe und wie dankbar ich bin, sie in meinem Leben gehabt zu haben. Deshalb plane ich für meinen eigenen Tod eine Beerdigung, bei der ich so viel Freude wie möglich mit den Menschen teilen kann, die mir am Herzen liegen.</p>
      </v-col>
    </v-row>

    <v-row justify="center" class="px-8">
      <v-col cols="12" md="8" align="right" justify="end">
<!--        <v-btn color="primary" :disabled="id == null" @click="$refs.html2Pdf.generatePdf(); isDownloading=true"><v-icon color="white">mdi-file-pdf</v-icon> PDF herunterladen</v-btn>-->
        <v-btn color="primary" :disabled="id == null" @click="generatePdf(); isDownloading=true"><v-icon color="white">mdi-file-pdf</v-icon> PDF herunterladen</v-btn>
      </v-col>
    </v-row>


      <v-row justify="center" class="px-8 pb-8">
        <v-col cols="12" md="8">
          <v-card
              class="pa-4"
          >
            <vue-html2pdf
                units="in"
                :margin="50"
                :show-layout="true"
                :float-layout="false"
                :enable-download="true"
                :preview-modal="false"
                filename="mein-letzter-wunsch"
                :pdf-quality="2"
                pdf-format="a4"
                pdf-orientation="portrait"
                pdf-content-width="100%"
                :manual-pagination="true"
                ref="html2Pdf"
            >
            <v-card-text slot="pdf-content">
              <v-row>
                <v-col cols="10">
                  <div class="label">
                    Vorgesorgt
                  </div>
                  <v-radio-group
                      v-model="data.prevention"
                      row
                  >
                    <v-radio
                        label="ja"
                        :value="1"
                    ></v-radio>
                    <v-radio
                        label="nein"
                        :value="0"
                    ></v-radio>
                  </v-radio-group>
                  <v-text-field
                      v-if="data.prevention === 1"
                      class="pa-0"
                      v-model="data.undertaker"
                      outlined
                      label="Meine wünsche liegen bei folgendem Bestatter"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="10">
                  <div class="label">
                    Mein Bestattungswunsch
                  </div>
                  <v-radio-group
                      v-model="data.funeral_type"
                  >
                    <v-radio
                        v-for="type in funeralTypes"
                        :key="type.id"
                        :label="type.name"
                        :value="type.id"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row v-if="data.funeral_type">
                <v-col cols="10">
                  <div class="label mb-5">
                    Details {{ funeralTypes.find(x => x.id === data.funeral_type).name }}
                  </div>
                  <v-text-field
                      v-if="[1,2,4].includes(data.funeral_type)"
                      class="pa-0"
                      v-model="data.cemetary"
                      outlined
                      label="Friedhof"
                  ></v-text-field>
                  <v-text-field
                      v-if="data.funeral_type === 5"
                      class="pa-0"
                      v-model="data.sea_location"
                      outlined
                      label="Ort"
                  ></v-text-field>
                  <v-text-field
                      v-if="data.funeral_type === 1"
                      class="pa-0"
                      v-model="data.coffin_type"
                      outlined
                      label="Sarg-Art"
                  ></v-text-field>
                  <v-radio-group
                      v-if="data.funeral_type === 2"
                      v-model="data.fire_funeral_container"
                      row
                  >
                    <template v-slot:label>
                      <div>Behälter-Art</div>
                    </template>
                    <v-radio
                        label="Urne"
                        :value="1"
                    ></v-radio>
                    <v-radio
                        label="Aschenkapsel"
                        :value="2"
                    ></v-radio>
                  </v-radio-group>
                  <v-text-field
                      v-if="data.funeral_type === 3"
                      class="pa-0"
                      v-model="data.woods"
                      outlined
                      label="Speziell ausgewiesene Wälder"
                  ></v-text-field>
                  <v-radio-group
                      v-if="data.funeral_type === 6"
                      v-model="data.anonymous_location"
                      row
                  >
                    <template v-slot:label>
                      <div>Bestattungsort</div>
                    </template>
                    <v-radio
                        label="Friedhof"
                        :value="1"
                    ></v-radio>
                    <v-radio
                        label="Wald"
                        :value="2"
                    ></v-radio>
                    <v-radio
                        label="Meer"
                        :value="3"
                    ></v-radio>
                  </v-radio-group>
                  <v-text-field
                      v-if="data.fire_funeral_container === 1 || [3,4,5,6].includes(data.funeral_type)"
                      class="pa-0"
                      v-model="data.fire_funeral_container_type"
                      outlined
                      label="Urnen-Art"
                  ></v-text-field>
                  <v-text-field
                      v-if="[1,2].includes(data.funeral_type)"
                      class="pa-0"
                      v-model="data.coffin_stone_type"
                      outlined
                      label="Grabstein-Art"
                  ></v-text-field>
                  <v-radio-group
                      v-if="[2,3,4,5].includes(data.funeral_type)"
                      v-model="data.anonymous"
                      row
                  >
                    <template v-slot:label>
                      <div>Anonym</div>
                    </template>
                    <v-radio
                        label="ja"
                        :value="1"
                    ></v-radio>
                    <v-radio
                        label="nein"
                        :value="0"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="10">
                  <div class="label mb-3">
                    Das möchte ich bei meiner Beerdigung anhaben
                  </div>
                  <v-textarea
                      class="pa-0"
                      v-model="data.clothing"
                      outlined
                  ></v-textarea>
                </v-col>
              </v-row>
              <div class="html2pdf__page-break" />
              <v-row>
                <v-col cols="10">
                  <div class="label mb-2">
                    Die Beisetzung soll stattfinden
                  </div>
                  <v-radio-group
                      v-model="data.funeral_with_audience"
                      row
                  >
                    <v-radio
                        label="mit Angehörigen"
                        :value="1"
                    ></v-radio>
                    <v-radio
                        label="ohne Angehörige"
                        :value="0"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="10">
                  <div class="label mb-3">
                    Einladung
                  </div>
                  <v-text-field
                      class="pa-0"
                      v-model="data.invitation_type"
                      outlined
                      label="Einladungs-Art"
                  ></v-text-field>
                  <v-textarea
                      class="pa-0"
                      v-model="data.invitation_text"
                      outlined
                      label="Meine Worte"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="10">
                  <div class="label mb-3">
                    Geldspenden
                  </div>
                  <v-radio-group
                      v-model="data.donation"
                      row
                  >
                    <v-radio
                        label="ja"
                        :value="1"
                    ></v-radio>
                    <v-radio
                        label="nein"
                        :value="0"
                    ></v-radio>
                  </v-radio-group>
                  <v-text-field
                      v-if="data.donation === 1"
                      class="pa-0"
                      v-model="data.donation_recipient"
                      outlined
                      label="Spendenempfänger"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="10">
                  <div class="label mb-3">
                    Trauerrede
                  </div>
                  <v-radio-group
                      v-model="data.eulogy"
                      row
                  >
                    <v-radio
                        label="von Angehörigen"
                        :value="1"
                    ></v-radio>
                    <v-radio
                        label="Kirchliche Trauerrede"
                        :value="2"
                    ></v-radio>
                    <v-radio
                        label="keine Trauerrede"
                        :value="0"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="10">
                  <div class="label mb-3">
                    Trauerfeier in der Kapelle
                  </div>
                  <v-radio-group
                      v-model="data.chapel"
                      row
                  >
                    <v-radio
                        label="ja"
                        :value="1"
                    ></v-radio>
                    <v-radio
                        label="nein"
                        :value="0"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <div class="html2pdf__page-break" />
              <v-row>
                <v-col cols="10">
                  <div class="label mb-3">
                    Blumen
                  </div>
                  <v-radio-group
                      v-model="data.flowers"
                      row
                  >
                    <v-radio
                        label="Trauerstrauß"
                        :value="1"
                    ></v-radio>
                    <v-radio
                        label="Trauerkranz"
                        :value="2"
                    ></v-radio>
                    <v-radio
                        label="Trauergesteck"
                        :value="3"
                    ></v-radio>
                    <v-radio
                        label="keine Blumen"
                        :value="0"
                    ></v-radio>
                  </v-radio-group>
                  <v-text-field
                      v-if="data.flowers && data.flowers !== 0"
                      class="pa-0"
                      v-model="data.flowers_type"
                      outlined
                      label="Blumenart"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="10">
                  <div class="label mb-3">
                    Musik
                  </div>
                  <v-radio-group
                      v-model="data.music"
                      row
                  >
                    <v-radio
                        label="ja"
                        :value="1"
                    ></v-radio>
                    <v-radio
                        label="nein"
                        :value="0"
                    ></v-radio>
                  </v-radio-group>
                  <v-text-field
                      v-if="data.music === 1"
                      class="pa-0"
                      v-model="data.music_type"
                      outlined
                      label="Musikrichtung"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="10">
                  <div class="label mb-3">
                    Live-Übertragung der Trauerfeier
                  </div>
                  <v-radio-group
                      v-model="data.live"
                      row
                  >
                    <v-radio
                        label="ja"
                        :value="1"
                    ></v-radio>
                    <v-radio
                        label="nein"
                        :value="0"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="10">
                  <div class="label mb-3">
                    Traueranzeige in einer Zeitung
                  </div>
                  <v-radio-group
                      v-model="data.newspaper"
                      row
                  >
                    <v-radio
                        label="ja"
                        :value="1"
                    ></v-radio>
                    <v-radio
                        label="nein"
                        :value="0"
                    ></v-radio>
                  </v-radio-group>
                  <v-text-field
                      v-if="data.newspaper === 1"
                      class="pa-0"
                      v-model="data.newspaper_name"
                      outlined
                      label="Zeitung"
                  ></v-text-field>
                  <v-textarea
                      v-if="data.newspaper === 1"
                      class="pa-0"
                      v-model="data.newspaper_words"
                      outlined
                      label="Meine Worte"
                  ></v-textarea>
                </v-col>
              </v-row>
              <div class="html2pdf__page-break" />
              <v-row>
                <v-col cols="10">
                  <div class="label mb-3">
                    Erinnerungsschmuck
                  </div>
                  <v-radio-group
                      v-model="data.jewelry"
                      row
                  >
                    <v-radio
                        label="ja"
                        :value="1"
                    ></v-radio>
                    <v-radio
                        label="nein"
                        :value="0"
                    ></v-radio>
                  </v-radio-group>
                  <v-text-field
                      v-if="data.jewelry === 1"
                      class="pa-0"
                      v-model="data.jewelry_type"
                      outlined
                      label="Welches Schmuckstück"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="10">
                  <div class="label mb-3">
                    Video-Aufzeichnung der Trauerfeier
                  </div>
                  <v-radio-group
                      v-model="data.video"
                      row
                  >
                    <v-radio
                        label="ja"
                        :value="1"
                    ></v-radio>
                    <v-radio
                        label="nein"
                        :value="0"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="10">
                  <div class="label mb-3">
                    Leichenschmaus
                  </div>
                  <v-radio-group
                      v-model="data.feast"
                      row
                  >
                    <v-radio
                        label="ja"
                        :value="1"
                    ></v-radio>
                    <v-radio
                        label="nein"
                        :value="0"
                    ></v-radio>
                  </v-radio-group>
                  <v-text-field
                      v-if="data.feast === 1"
                      class="pa-0"
                      v-model="data.feast_location"
                      outlined
                      label="Restaurant"
                  ></v-text-field>
                  <v-textarea
                      v-if="data.feast === 1"
                      class="pa-0"
                      v-model="data.feast_meal"
                      outlined
                      label="Essen"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
            </vue-html2pdf>
            <v-card-actions class="pt-0">
              <v-btn
                  color="primary"
                  @click="saveLastWish"
                  class="px-5"
                  :disabled="disabledButton"
              >
                Speichern
                <!--              <v-icon right>-->
                <!--                mdi-save-->
                <!--              </v-icon>-->
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>


  </v-container>
</template>

<script>
import Vue from "vue";
import {mapState} from "vuex";

export default {
  data() {
    return {
      id: null,
      data: {
        prevention: 0,
        funeral_type: 0,
        clothing: '',
        funeral_with_audience: 0,
        donation: 0,
        eulogy: 0,
        chapel: 0,
        flowers: 0,
        music: 0,
        live: 0,
        newspaper: 0,
        jewelry: 0,
        video: 0,
        feast: 0
      },
      funeralTypes: [
        {
          id: 1,
          name: 'Erdbestattung'
        },
        {
          id: 2,
          name: 'Feuerbestattung'
        },
        {
          id: 3,
          name: 'Baumbestattung'
        },
        {
          id: 4,
          name: 'Grüne Wiese Bestattung'
        },
        {
          id: 5,
          name: 'Seebestattung'
        },
        {
          id: 6,
          name: 'Anonyme Bestattung'
        },
      ],
      htmlToPdfOptions: {
        margin: 50,

        filename: `checklist.pdf`,

        image: {
          type: 'jpeg',
          quality: 0.98
        },

        enableLinks: false,

        html2canvas: {
          scale: 1,
          useCORS: true
        },

      }
    }
  },
  mounted() {
    this.getLastWishData()
  },
  computed: {
    disabledButton() {
      if(this.checkField('prevention', true, 'undertaker')
          && this.checkField('funeral_with_audience', false, '')
          && this.checkField('donation', true, 'donation_recipient')
          && this.checkField('eulogy', false, '')
          && this.checkField('chapel', false, '')
          && this.checkField('flowers', true, 'flowers_type')
          && this.checkField('music', true, 'music_type')
          && this.checkField('live', false, '')
          && this.checkField('jewelry', true, 'jewelry_type')
          && this.checkField('video', false, '')
      ) return false
      return true
    }
  },
  methods: {
    getLastWishData() {
      this.$store.dispatch('getLastWishData')
          .then(resp => {
            if(resp.response) {
              this.id = resp.data.id
              this.data = resp.data.content
            }
          })
    },
    saveLastWish() {
      this.$store.dispatch('saveLastWishData', {'id': this.id, 'data': this.data})
          .then(resp => {
            Vue.$toast.success('Dein letzter Wunsch wurde gespeichert!')
          })
    },
    generatePdf() {
      this.$store.dispatch('generateLastWishPdf')
          .then(resp => {
            if(resp) {
              var fileName = "letzter_wunsch.pdf";
              var a = document.createElement("a");
              document.body.appendChild(a);

              var file = new Blob([resp], {type: 'application/pdf'});
              var fileURL = URL.createObjectURL(file);
              a.href = fileURL;
              a.download = fileName;
              a.click();
              // window.open(fileURL);
              // this.id = resp.data.id
              // this.data = resp.data.content
            }
          })
    },
    checkField(name, hasDependency, depName) {
      var check = true

      if(hasDependency) {
        if(typeof(this.data[name]) === 'undefined' || (this.data[name] !== 0 && (typeof(this.data[depName]) === 'undefined' || this.data[depName] === ''))) check = false
      } else {
        if(typeof(this.data[name]) === 'undefined') check = false
      }

      return check
    }
  }
}
</script>