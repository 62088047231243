<template>
    <v-container
        fluid
        tag="section"
    >
        <v-row justify="center" class="pl-8 pr-8 pb-8 pt-3">
            <v-col cols="12" md="5">
<!--                <div class="text-h6 main-font-bold main-title-color">{{ $t('plans') }}</div>-->
<!--                <div class="subtitle-1 font-weight-light main-grey mt-0 pt-0">{{ $t('plans_subtitle') }}</div>                -->
                <div class="text-h6 main-font-bold main-title-color">{{ $t('your_abos') }}</div>
                <div class="subtitle-1 font-weight-light main-grey mt-0 pt-0">{{ $t('your_abos_subtitle') }}</div>
            </v-col>
            <!-- User Active Plan Infos  -->
            <v-col cols="12" md="7" align="center" justify="center">
                <v-row v-if="$store.getters.user.user.subscription" align="right" justify="end">
                    <!-- Duration -->
                    <v-col align="center" justify="center">
                        <v-progress-circular
                            :rotate="360"
                            :size="80"
                            :width="8"
                            :value="($store.getters.user.user.subscription.remainingDays * 100) / $store.getters.user.user.subscription.duration"
                            color="teal"
                        >
                            {{ $store.getters.user.user.subscription.remainingDays }} {{ $t('days') }}
                        </v-progress-circular>
                        <div class="text-subtitle-1 mt-2">{{ $t('remaining_days') }}</div>
                    </v-col>
                    <!-- File Size -->
                    <v-col align="center" justify="center">
                        <v-progress-circular
                            :rotate="360"
                            :size="80"
                            :width="8"
                            :value="((getUsage('files.size') * 100) / getUsageMain('files.size') - 100) * (-1)"
                            color="teal"
                        >
                            {{ getUsageMain('files.size') - getUsage('files.size') }} Mb
                        </v-progress-circular>
                        <div class="text-subtitle-1 mt-2">{{ $t('storage') }}</div>
                    </v-col>
                    <!-- Tasks Counter -->
                    <!-- <v-col align="center" justify="center">
                        <v-progress-circular
                            :rotate="360"
                            :size="80"
                            :width="8"
                            :value="((getUsage('tasks.count') * 100) / getUsageMain('tasks.count') - 100) * (-1)"
                            color="teal"
                        >
                            {{ getUsage('tasks.count') }} / {{ getUsageMain('tasks.count') }}
                        </v-progress-circular>
                        <div class="text-subtitle-1 mt-2">{{ $t('task_limit') }}</div>
                    </v-col> -->
                    <!-- Trusters Counter -->
                    <v-col align="center" justify="center">
                        <v-progress-circular
                            :rotate="360"
                            :size="80"
                            :width="8"
                            :value="((getUsage('trusted.count') * 100) / getUsageMain('trusted.count') - 100) * (-1)"
                            color="teal"
                        >
                            {{ getUsage('trusted.count') }} / {{ getUsageMain('trusted.count') }}
                        </v-progress-circular>
                        <div class="text-subtitle-1 mt-2">{{ $t('trusted_limit') }}</div>
                    </v-col>
<!--                    <v-col align="center" justify="center">-->
<!--                        <v-progress-circular-->
<!--                            :rotate="360"-->
<!--                            :size="80"-->
<!--                            :width="8"-->
<!--                            :value="(((getUsage('duplicate_subscription') + 1) * 100) / (getUsageMain('duplicate_subscription') + 1) - 100) * (-1)"-->
<!--                            color="teal"-->
<!--                        >-->
<!--                            {{ (getUsage('duplicate_subscription') + 1) }} / {{ getUsageMain('duplicate_subscription') + 1 }}-->
<!--                        </v-progress-circular>-->
<!--                        <div class="text-subtitle-1 mt-2">{{ $t('users') }}</div>-->
<!--                    </v-col>-->
                </v-row>
            </v-col>
          </v-row>
          <!-- Pays (NEW: Abos) -->
          <v-row justify="center" class="pl-8 pr-8 pb-8 pt-3">
            <v-col cols="12">
              <div class="text-h6 main-font-bold main-title-color">{{ $t('your_abos_list') }}</div>
              <!--                <div class="subtitle-1 font-weight-light main-grey mt-0 pt-0">{{ $t('your_pays_desc') }}</div>-->
            </v-col>
            <v-col cols="12" v-if="!paysLoading">
              <v-simple-table v-if="pays.length && !codeFromAbo && $store.getters.user.user.subscription">
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left">#</th>
                    <th class="text-left">{{ $t('abonnement') }}</th>
                    <th class="text-left">{{ $t('amount') }}</th>
                    <th class="text-left">{{ $t('date') }}</th>
                    <th class="text-left">{{ $t('status') }}</th>
                    <th></th>
                    <th></th>
                    <!--                                <th class="text-left">{{ $t('ref') }}</th>-->
                    <!--                                <th class="text-center">{{ $t('actions') }}</th>-->
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(pay, i) in pays" :key="i">
                    <td>{{ i + 1 }}</td>
                    <td>{{ pay.plan.name }}</td>
                    <td>{{ pay.amount }} {{ pay.currency }}</td>
                    <td>{{ pay.created_at }}</td>
                    <td>
                      <v-chip v-if="pay.status == 'success'" color="success">
                        {{ $t('activated') }}
                      </v-chip>
                      <v-chip v-else color="info">
                        {{ $t('not_activated') }}
                      </v-chip>
                    </td>
                    <td v-if="pay.status !== 'success'">
                      <v-text-field
                          class="text-center"
                          placeholder="Abo-Code"
                          v-model="licenseCode"
                          :loading="sending"
                          :disabled="sending"
                          outlined
                          dense
                          hide-details
                      ></v-text-field>
                    </td>
                    <td v-if="pay.status !== 'success'">
                      <v-btn
                          :disabled="subscribeLoading"
                          :loading="subscribeLoading"
                          color="primary"
                          @click="redeemLicenseCode(pay.id, pay.plan.id, licenseCode)"
                      >
                        Abo aktivieren
                      </v-btn>
                    </td>
                    <td>
                      <v-btn
                          :disabled="subscribeLoading"
                          :loading="subscribeLoading"
                          color="primary"
                          @click="activateCodeField(true)"
                      >
                        Weiteren Code einlösen
                      </v-btn>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div v-else class="text-center">
                <span v-if="!codeFromAbo" v-html="$t('no_abos')"></span>

                <div v-if="!hasGivenCode" class="text-center pt-15">
                  <v-btn
                      :disabled="subscribeLoading"
                      :loading="subscribeLoading"
                      color="primary"
                      @click="activateCodeField(false)"
                  >
                    Ich habe bereits einen Code
                  </v-btn>
                </div>
                <div v-else class="text-center pt-15">
                  <v-container class="max-width-600" fill-height fluid>
                    <v-row align="center" justify="center">
                      <v-col class="col-12 col-md-6">
                        <v-text-field
                            class="text-center"
                            placeholder="Abo-Code"
                            v-model="licenseCode"
                            :loading="sending"
                            :disabled="sending"
                            outlined
                            dense
                            hide-details
                        ></v-text-field>
                      </v-col>
                    <v-col class="col-12 col-md-6">
                        <v-btn
                            :disabled="subscribeLoading"
                            :loading="subscribeLoading"
                            color="primary"
                            @click="redeemGivenLicenseCode(licenseCode)"
                        >
                          Abo aktivieren
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </div>
            </v-col>

            <v-col v-else class="mb-5 mt-5" :key="0">
              <v-progress-linear indeterminate color="primary"></v-progress-linear>
            </v-col>
          </v-row>
          <v-row justify="center" class="pl-8 pr-8 pb-8 pt-3" v-if="!paysLoading && (!pays.length || !$store.getters.user.user.subscription)">
            <v-col cols="12" class="mt-2">
                <section id="prices"  v-if="!loading && plans">

                    <plans @open-register-modal="openRegisterModal" :only-paid="true"></plans>

<!--                    <v-col cols="12" md="3" v-for="plan in plans" :key="plan.id" class="flex d-flex flex-column">-->
<!--                        <v-hover v-slot="{hover}">-->
<!--                            <v-card elevation="5" :color="(hover || (selectedPlan ? selectedPlan.id == plan.id : false)) ? 'primary' : 'white'"  class="flex d-flex flex-column card-outter">-->
<!--                                <v-card-text align="center" justify="center">-->
<!--                                    <v-icon x-large :color="(hover || (selectedPlan ? selectedPlan.id == plan.id : false)) ? 'white' : 'primary'" v-if="$store.getters.user.user.subscription.name != plan.name">mdi-certificate</v-icon>-->
<!--                                    <v-icon x-large :color="(hover || (selectedPlan ? selectedPlan.id == plan.id : false)) ? 'white' : 'success'" v-else>mdi-check-decagram</v-icon>-->
<!--                                </v-card-text>-->
<!--                                <v-card-text align="center" justify="center">-->
<!--                                    <div class="text-subtitle-1 font-weight-bold" :class="{'white&#45;&#45;text': (hover || (selectedPlan ? selectedPlan.id == plan.id : false))}">{{ plan.name }}</div>-->
<!--                                    <div :class="{'white&#45;&#45;text': (hover || (selectedPlan ? selectedPlan.id == plan.id : false))}">{{ plan.description }}</div>-->
<!--                                </v-card-text>-->

<!--                                <v-card-text>-->
<!--                                    &lt;!&ndash; Duration &ndash;&gt;-->
<!--                                    <v-row>-->
<!--                                        <v-col :class="{'white&#45;&#45;text': (hover || (selectedPlan ? selectedPlan.id == plan.id : false))}">-->
<!--                                            <v-icon -->
<!--                                                :color="(hover || (selectedPlan ? selectedPlan.id == plan.id : false)) ? 'white' : 'primary'"-->
<!--                                            >-->
<!--                                                mdi-clock-outline-->
<!--                                            </v-icon> {{ $t('duration') }}-->
<!--                                        </v-col>-->
<!--                                        <v-col align="right" class="font-weight-bold" :class="{'white&#45;&#45;text': (hover || (selectedPlan ? selectedPlan.id == plan.id : false)), 'primary&#45;&#45;text': (!hover && !selectedPlan)}">{{ plan.duration ?(plan.duration > 400 ? 'Lebenslang' : plan.duration+' Tage') : 'Unlimited' }}</v-col>-->
<!--                                    </v-row>-->
<!--                                    &lt;!&ndash; Price &ndash;&gt;-->
<!--                                    <v-row>-->
<!--                                        <v-col :class="{'white&#45;&#45;text': (hover || (selectedPlan ? selectedPlan.id == plan.id : false))}">-->
<!--                                            <v-icon -->
<!--                                                :color="(hover || (selectedPlan ? selectedPlan.id == plan.id : false)) ? 'white' : 'primary'"-->
<!--                                            >-->
<!--                                                mdi-cash-->
<!--                                            </v-icon> {{ $t('price') }}-->
<!--                                        </v-col>-->
<!--                                        <v-col align="right" class="font-weight-bold" :class="{'white&#45;&#45;text': (hover || (selectedPlan ? selectedPlan.id == plan.id : false)), 'primary&#45;&#45;text': (!hover && !selectedPlan)}">{{ plan.price ? plan.price+ ' '+ plan.currency : '-&#45;&#45;' }}</v-col>-->
<!--                                    </v-row>-->
<!--                                    &lt;!&ndash; Features &ndash;&gt;-->
<!--                                    <v-row>-->
<!--                                        <v-col cols="12" align="center" justify="center" class="pb-0 mb-0">-->
<!--                                            <div :class="{'white&#45;&#45;text': (hover || (selectedPlan ? selectedPlan.id == plan.id : false))}">{{ $t('features') }}</div>-->
<!--                                        </v-col>-->
<!--                                        <v-col cols="12" align="center" justify="center" class="pt-0 mt-0">-->
<!--                                            <div><v-icon :color="(hover || (selectedPlan ? selectedPlan.id == plan.id : false)) ? 'white' : 'dark'">mdi-arrow-down</v-icon></div>-->
<!--                                        </v-col>-->
<!--                                    </v-row>-->
<!--                                    &lt;!&ndash; Features &ndash;&gt;-->
<!--                                    <v-row v-for="feature in plan.features" :key="feature.code">-->
<!--                                        <v-col cols="auto" :class="{'white&#45;&#45;text': (hover || (selectedPlan ? selectedPlan.id == plan.id : false))}">-->
<!--                                            <v-tooltip top> -->
<!--                                                <template v-slot:activator="{ on, attrs }">-->
<!--                                                    <section-->
<!--                                                    v-bind="attrs"-->
<!--                                                    v-on="on"-->
<!--                                                    >-->
<!--                                                    <v-icon             -->
<!--                                                        :color="(hover || (selectedPlan ? selectedPlan.id == plan.id : false)) ? 'white' : 'primary'"-->
<!--                                                    >-->
<!--                                                        mdi-check-decagram-->
<!--                                                    </v-icon>-->
<!--                                                    {{ feature.name }}-->
<!--                                                    </section>-->
<!--                                                </template>-->
<!--                                                <span>{{ feature.description }}</span>-->
<!--                                            </v-tooltip>-->
<!--                                        </v-col>-->
<!--                                        <v-col align="right" class="font-weight-bold" :class="{'white&#45;&#45;text': (hover || (selectedPlan ? selectedPlan.id == plan.id : false)), 'primary&#45;&#45;text': (!hover && !selectedPlan)}">-->
<!--                                            {{ feature.type == 'limit' ? (feature.code == 'duplicate_subscription' ? feature.limit + 1 : feature.limit)+ ' '+ getUnit(feature) : '' }}-->
<!--                                            <v-icon       -->
<!--                                                v-if="feature.type != 'limit'"      -->
<!--                                                :color="(hover || (selectedPlan ? selectedPlan.id == plan.id : false)) ? 'white' : 'primary'"-->
<!--                                            >-->
<!--                                                mdi-check-->
<!--                                            </v-icon>-->
<!--                                        </v-col>-->
<!--                                    </v-row>-->
<!--                                    &lt;!&ndash; Non Features &ndash;&gt;-->
<!--                                    <v-row v-for="feature in plan.non_features" :key="feature.code">-->
<!--                                        <v-col cols="auto" :class="{'white&#45;&#45;text': (hover || (selectedPlan ? selectedPlan.id == plan.id : false))}">-->
<!--                                            <v-tooltip top> -->
<!--                                                <template v-slot:activator="{ on, attrs }">-->
<!--                                                    <section-->
<!--                                                    v-bind="attrs"-->
<!--                                                    v-on="on"-->
<!--                                                    >-->
<!--                                                    <v-icon             -->
<!--                                                        :color="(hover || (selectedPlan ? selectedPlan.id == plan.id : false)) ? 'white' : 'grey'"-->
<!--                                                    >-->
<!--                                                        mdi-check-decagram-->
<!--                                                    </v-icon>-->
<!--                                                    {{ feature.name }}-->
<!--                                                    </section>-->
<!--                                                </template>-->
<!--                                                <span>{{ feature.description }}</span>-->
<!--                                            </v-tooltip>-->
<!--                                        </v-col>-->
<!--                                        <v-col align="right" class="font-weight-bold" :class="{'white&#45;&#45;text': (hover || (selectedPlan ? selectedPlan.id == plan.id : false)), 'primary&#45;&#45;text': (!hover && !selectedPlan)}">-->
<!--                                            {{ feature.type == 'limit' ? feature.limit+ ' '+ getUnit(feature) : '' }}-->
<!--                                            <v-icon       -->
<!--                                                v-if="feature.type != 'limit'"      -->
<!--                                                :color="(hover || (selectedPlan ? selectedPlan.id == plan.id : false)) ? 'white' : 'error'"-->
<!--                                            >-->
<!--                                                mdi-close-->
<!--                                            </v-icon>-->
<!--                                        </v-col>-->
<!--                                    </v-row>-->
<!--                                </v-card-text>-->

<!--&lt;!&ndash;                              @click="requestPlan(plan.id, null)"&ndash;&gt;-->

<!--                                <v-card-actions class="card-actions ma-4" v-if="plan.is_free != 1">-->
<!--                                    <v-row align="center" justify="center">-->
<!--                                        <v-btn -->
<!--                                            v-if="plan.upgradeable"-->
<!--                                            :color="hover ? 'white' : 'primary'"-->
<!--                                            @click="paymentMethodSelect(plan.id)"-->
<!--                                            :loading="subscribeLoading == plan.id"-->
<!--                                            :disabled="subscribeLoading ? true : false"-->
<!--                                        >-->
<!--                                            <v-icon>mdi-cart</v-icon>-->
<!--                                          {{ $t('buy') }}-->
<!--                                        </v-btn>-->
<!--                                    </v-row>-->
<!--                                </v-card-actions>-->
<!--                            </v-card>-->
<!--                        </v-hover>-->
<!--                    </v-col>-->
<!--                    <v-col v-if="!plans.length" justify="center" align="center" cols="12">-->
<!--                        <v-icon x-large color="grey">mdi-alert-rhombus-outline</v-icon>-->
<!--                        <div class="text-subtitle-1 mt-5 main-grey">{{ $t('plan_empty') }}</div>-->
<!--                    </v-col>-->
                </section>
                <v-row v-else class="mb-5 mt-5" :key="0">
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-row>
            </v-col>
        </v-row>

      <!-- Payment Method Dialog -->
      <v-dialog
          v-model="paymentMethodDialog"
          persistent
          max-width="600px"
      >
        <v-card>
          <v-card-title class="text-h5">
            Mitgliedschaft buchen
          </v-card-title>

          <v-card-text class="mt-5">
            <v-col>
              <v-row>
                <div>{{ $t('payment_plan') }}</div>
                <v-spacer></v-spacer>
                <div class="primary--text font-weight-bold">{{ payment.plan ? payment.plan.name : '' }}</div>
              </v-row>
            </v-col>
          </v-card-text>

          <v-card-text class="mt-5">
            <v-col>
              <v-row>
                <div>{{ $t('payment_plan_price') }}</div>
                <v-spacer></v-spacer>
                <div>{{ payment.plan_price }} {{ payment.currency }}</div>
              </v-row>
            </v-col>
          </v-card-text>

          <v-card-text>
            <v-col>
              <v-row>
                <div>{{ $t('payment_amount') }}</div>
                <v-spacer></v-spacer>
                <div class="primary--text font-weight-bold">{{ payment.amount }} {{ payment.currency }}</div>
              </v-row>
            </v-col>
          </v-card-text>

          <v-card-text>
            <v-card
                class="mx-auto"
            >
              <v-list class="pa-0">
                <v-list-item-group
                    v-model="paymentMethod"
                    color="indigo"
                    mandatory
                >

                  <template v-for="(method, i) in paymentMethods">
                    <v-list-item
                        :key="i"
                        :value="method"
                    >
                      <v-row>
                        <v-col cols="6" class="my-auto">
                          <v-list-item-title v-text="method.name"></v-list-item-title>
                        </v-col>
                        <v-col cols="6" class="text-right">
                          <img :src="method.image" />
                        </v-col>
                      </v-row>
                    </v-list-item>

                    <v-divider
                        v-if="i < paymentMethods.length-1"
                        :key="`${i}-divider`"
                    ></v-divider>

                  </template>

                </v-list-item-group>
              </v-list>
            </v-card>

          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                v-if="paymentMethod"
                color="primary darken-1"
                large
                @click="requestPlan(payment.plan.id, null, paymentMethod.key)"
                :loading="subscribeLoading == payment.plan.id"
                :disabled="subscribeLoading ? true : false"
            >
              Jetzt per {{ paymentMethod.name }} bezahlen
            </v-btn>

            <v-btn
                color="error darken-1"
                text
                @click="paymentMethodDialog = false;"
                :disabled="paymentLoading"
            >
              {{ $t('cancel') }}
            </v-btn>

          </v-card-actions>
        </v-card>
      </v-dialog>

        <!-- Payment Confirm Dialog -->
        <v-dialog
            v-model="paymentDialog"
            persistent
            max-width="400"
        >
            <v-card>
                <v-card-title class="text-h5">
                    {{ $t('payment_title') }}
                </v-card-title>

                <v-card-text class="mt-5">
                    <v-col>
                        <v-row>
                            <div>{{ $t('payment_plan') }}</div>
                            <v-spacer></v-spacer>
                            <div class="primary--text font-weight-bold">{{ payment.plan ? payment.plan.name : '' }}</div>
                        </v-row>
                    </v-col>
                </v-card-text>

                <v-card-text class="mt-5">
                    <v-col>
                        <v-row>
                            <div>{{ $t('payment_plan_price') }}</div>
                            <v-spacer></v-spacer>
                            <div>{{ payment.plan_price }} {{ payment.currency }}</div>
                        </v-row>
                    </v-col>
                </v-card-text>

                <v-card-text>
                    <v-col>
                        <v-row>
                            <div>{{ $t('payment_amount') }}</div>
                            <v-spacer></v-spacer>
                            <div class="primary--text font-weight-bold">{{ payment.amount }} {{ payment.currency }}</div>
                        </v-row>
                    </v-col>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="error darken-1"
                        text
                        @click="paymentDialog = false; payment = {}; getPays();"
                        :disabled="paymentLoading"
                    >
                        {{ $t('cancel') }}
                    </v-btn>

                    <v-btn
                        color="primary darken-1"
                        large
                        @click="goToBank"
                        :loading="paymentLoading"
                    >
                        {{ $t('paymeny_go_bank') }}
                    </v-btn>

                </v-card-actions>
            </v-card>
        </v-dialog>

        <register-dialog :price-model="priceModel" @close-register-modal="showRegister = false" @get-pays="getPays" @open-popup-blocked-modal="showPopupBlocked = true" v-model="showRegister"></register-dialog>
        <popup-blocked-dialog :price-model="priceModel" v-model="showPopupBlocked"></popup-blocked-dialog>

    </v-container>

</template>

<script>

import RegisterDialog from "../../../../widgets/RegisterDialog";
import Plans from "../../../../widgets/Plans";
import PopupBlockedDialog from "../../../../widgets/PopupBlockedDialog";

export default {
    components: {
      RegisterDialog,
      Plans,
      PopupBlockedDialog
    },
    data(){
        return {
            loading: true, 
            paysLoading: true,           
            plans: null,
            selectedPlan: null,
            subscribeLoading: false,
            payment: {},
            paymentDialog: false,
            paymentLoading: false,
            paymentMethodDialog: false,
            paymentMethod: null,
            paymentMethods: [
              {
                key: 'paypal',
                name: 'PayPal',
                image: '/assets/img/payment/de-pp-logo-100px.png'
              },
              // {
              //   key: 'creditCard',
              //   name: 'Kreditkarte',
              //   image: '/assets/img/payment/de-pp-logo-100px.png'
              // },
              // {
              //   key: 'klarna',
              //   name: 'Klarna',
              //   image: '/assets/img/payment/de-pp-logo-100px.png'
              // },
            ],
            licenseCode: null,
            sending: false,
            showRegister: false,
            showPopupBlocked: false,
            priceModel: null,
            hasGivenCode: false,
            codeFromAbo: false,
            pays: {}
        }
    },
    mounted(){
        this.getPlans()
        this.getPays()
    },
    methods:{
        getPlans(){
            this.loading = true
            this.$store.dispatch('getPlans')
            .then(resp => {

                let temp = []
                let result = []
                for (let j = 0; j < resp.length; j++) {
                    const plan = resp[j];
                    for (let index = 0; index < plan.features.length; index++) {
                        const item = plan.features[index];
                        if (item.code != 'tasks.count' && item.code != 'trusted.count')
                            temp.push(item)
                    }
                    plan.features = temp
                    result.push(plan)
                    temp = []
                }
                
                this.plans = result
                if (this.$route.params.id)
                    this.requestPlan(this.$route.params.id)
                else 
                    this.loading = false
            })
        },

        getPays(){
            this.paysLoading = true
            this.$store.dispatch('getPays')
            .then(resp => {
               this.pays = resp
               this.pays.splice(1);
               this.paysLoading = false
            })
        },

        paymentMethodSelect(id) {
          for (let index = 0; index < this.plans.length; index++) {
            const element = this.plans[index];
            if (element.id == id) {
              this.payment.plan = element
              this.payment.plan_price = this.payment.amount = element.price
              this.payment.currency = element.currency
            }
          }

          this.paymentMethodDialog = true
        },

        requestPlan(id, plan_id, method){
            this.subscribeLoading = id
            this.$store.dispatch('getPlanSubsRequest', {
                id: id,
                plan_id: plan_id,
                method: method
            })
            .then(resp => {
                this.payment = resp
                for (let index = 0; index < this.plans.length; index++) {
                    const element = this.plans[index];
                    if (element.id == id) this.payment.plan = element
                }
                // this.paymentDialog = true
                // this.subscribeLoading = false
                this.$helpers.showMessage('info', this.$t('redirect_payment_gateway'))
                window.location.href = resp.url
            })
            .catch(err => {
                this.$helpers.showError(err)
                this.subscribeLoading = false
            })
        },

        goToBank(){
            this.$helpers.showMessage('info', this.$t('redirect_payment_gateway'))
            window.location.href = this.payment.url
            this.paymentLoading = true
        },

        getUnit(feature){
            switch (feature.code) {
                case 'files.size':
                    return 'Mb'
                case 'tasks.count':
                    return 'No.'
                default:
                    return '';
            }
        },

        getUsage(code){
           let usages = this.$store.getters.user.user.subscription.usages

           for (let index = 0; index < usages.length; index++) {
               const usage = usages[index];
               if (usage.code == code) return usage.used
           }
        },

        getUsageMain(code){
            let usages = this.$store.getters.user.user.subscription.usages

           for (let index = 0; index < usages.length; index++) {
               const usage = usages[index];
               if (usage.code == code) return usage.limit
           }
        },

        redeemLicenseCode(pay_id, plan_id, code) {
          this.subscribeLoading = true
          this.$store.dispatch('redeemCode', {
            id: pay_id,
            plan_id: plan_id,
            code: code
          })
          .then(resp => {
            if(resp) {
              this.$helpers.showMessage('success', this.$t('redeem_code_success'))
            } else {
              this.$helpers.showMessage('error', this.$t('redeem_code_error'))
            }

            this.subscribeLoading = false

            this.getPays();
          })
          .catch(err => {
            this.$helpers.showError(err)
            this.subscribeLoading = false
          })
        },
        redeemGivenLicenseCode(code) {
          this.subscribeLoading = true
          this.$store.dispatch('redeemGivenCode', {
            code: code
          })
              .then(resp => {
                if(resp.result) {
                  if(resp.is_promo_code && typeof fbq !== "undefined") window.fbq('track', 'Lead')
                  this.$helpers.showMessage('success', this.$t('redeem_code_success'))
                } else {
                  this.$helpers.showMessage('error', this.$t('redeem_code_error'))
                }

                this.subscribeLoading = false
                this.codeFromAbo = false

                this.getPays();
              })
              .catch(err => {
                this.$helpers.showError(err)
                this.subscribeLoading = false
              })
        },
        openRegisterModal(model) {
          this.priceModel = model
          this.showRegister = true
        },
        activateCodeField(fromAbo) {
          this.hasGivenCode = true;
          if(fromAbo) this.codeFromAbo = true
        }
    }
};
</script>

<style>
.card-outter {
    position: relative;
    padding-bottom: 50px;
}
.card-actions {
    position: absolute;
    right: 0;
    bottom: 0;
}
</style>