<template>
  <section>
    <Header @open-login-modal="showLogin = true" @open-register-modal="showRegister = true" />
    <v-container class="text-container">
      <v-row>
        <v-col
            cols="12"
            md="8"
            justify="center"
        >
          <h1>Versicherungsvergleich</h1>
          <div class="main-font">
            Geras24 hat es sich zur Aufgabe gemacht, dich und deine Familie perfekt in der Absicherung zu beraten.<br>
            Ein wichtiger Baustein der Absicherung sind Versicherungen.<br>
            Informiere dich hier über die wichtigsten Versicherungen, die man abschließen sollte.<br><br>
            Unsere Partner FIDELIS FINANZEN empfehlen dir das Wichtigste.<br><br>
            Schau dir den Versicherungsvergleich an und entscheide dich für die beste Versicherung für dich und deine Familie.
          </div>
          <div class="py-10 text-center">
            <v-row justify="center">
              <v-col cols="12" md="6">
                <v-btn
                    elevation="5"
                    class="btn-support"
                    min-width="250"
                    text
                    href="https://www.maklerinfo.biz/rechner/bd/30w8g1/risikoleben/74ed"
                    target="_blank"
                >
                  <span>Risikolebensversicherung</span>
                </v-btn>
              </v-col>
              <v-col cols="12" md="6">
                <v-btn
                    elevation="5"
                    class="btn-support"
                    min-width="250"
                    text
                    href="https://www.maklerinfo.biz/rechner/bd/30w8g1/sterbegeld/74ed"
                    target="_blank"
                >
                  <span>Sterbegeldversicherung</span>
                </v-btn>
              </v-col>
<!--            </v-row>-->
<!--            <v-row>-->
              <v-col cols="12" md="6">
                <v-btn
                    elevation="5"
                    class="btn-support"
                    min-width="250"
                    text
                    href="https://www.maklerinfo.biz/rechner/bd/30w8g1/hausrat/74ed"
                    target="_blank"
                >
                  <span>Hausratversicherung</span>
                </v-btn>
              </v-col>
              <v-col cols="12" md="6">
                <v-btn
                    elevation="5"
                    class="btn-support"
                    min-width="250"
                    text
                    href="https://www.maklerinfo.biz/rechner/bd/30w8g1/privathaftpflicht/74ed"
                    target="_blank"
                >
                  <span>Privathaftpflichtversicherung</span>
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col
            cols="12"
            md="4"
            align="center"
            justify="center"
        >
          <v-img src="/assets/img/versicherungsvergleich.jpg" width="500" ></v-img>
        </v-col>
      </v-row>
    </v-container>

    <Footer></Footer>

    <login-dialog @close-login-modal="showLogin = false" @open-register-modal="showRegister = true" v-model="showLogin"></login-dialog>
    <register-dialog @close-register-modal="showRegister = false" @open-login-modal="showLogin = true" v-model="showRegister"></register-dialog>

  </section>
</template>

<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import LoginDialog from "../../widgets/LoginDialog.vue";
import RegisterDialog from "../../widgets/RegisterDialog.vue";

export default {
    components:{
      Header,
      Footer,
      LoginDialog,
      RegisterDialog
    }, 
    data(){
        return {
          showLogin: false,
          showRegister: false
        }
    },
    created() {
      this.setMetaTags()
    },
    mounted() {
    },
    methods: {
    }
}
</script>