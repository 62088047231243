<template>
    <section>
      <Header @open-login-modal="showLogin = true" @open-register-modal="showRegister = true" />

      <v-container>
        <v-row class="mt-10">
            <v-col cols="12">
              <h1>Erklärfilme</h1>
            </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="main-font">
            Geras24 ist der digitale Ort der Zukunft. Unsere Vision übertrifft die Vorstellung von vielen Menschen. Um unsere Idee näher zu bringen, haben wir einige Videos für dich. Schau dir unsere Mission an und frage uns gerne, wenn dir noch etwas unklar ist.
          </v-col>
        </v-row>
        <v-row class="mb-10">
          <v-col>
            <v-card v-for="(video, i) in videos" :key="i" class="mt-10">
              <v-card-text>
                <v-row>
                  <v-col cols="6" class="my-auto">
                    <h2>{{ video.title }}</h2>
                    <div class="main-font mt-5">{{ video.description }}</div>
                  </v-col>
                  <v-col cols="6">
                    <WideVideo :src="video.path" :cover="video.cover" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <Footer></Footer>

      <login-dialog @close-login-modal="showLogin = false" @open-register-modal="showRegister = true" v-model="showLogin"></login-dialog>
      <register-dialog @close-register-modal="showRegister = false" @open-login-modal="showLogin = true" v-model="showRegister"></register-dialog>
    </section>
</template>

<script>

import Header from "./Header.vue";
import WideVideo from "../../widgets/WideVideoHeader.vue";
import Footer from "../../components/public/Footer.vue";
import LoginDialog from "../../widgets/LoginDialog.vue";
import RegisterDialog from "../../widgets/RegisterDialog.vue";

export default {
    components: {
      Header,
      WideVideo,
      Footer,
      LoginDialog,
      RegisterDialog
    }, 
    data() {
        return {
          videos: [
              {
                title: 'Als junger Familienvater sicherst du deine Familie ab',
                description: 'Du bist gerade Familienvater geworden und überlegst, wie du dich und deine Familie absichern kannst. Dann bist du bei Geras24 genau richtig. Wir helfen dir dabei dich und deine Familie abzusichern. Auch für die schlimmsten Fälle bist du mit Geras24 sicher.',
                path: 'assets/video/explain/video_1.mp4',
                cover: 'assets/video/explain/cover_1.png'
              },
            {
              title: 'Das Hier und Jetzt sorgenfrei geniessen',
              description: 'Die meisten Menschen möchten sich nicht mit der Zukunft auseinandersetzen, weil sie Angst davor haben. Genau diese Angst nimmt dir Geras24. Mit uns gestaltest du dein Morgen und sicherst deine Hinterbliebenen ab, damit du das Jetzt sorgenfrei und ohne Angst genießen kannst.',
              path: 'assets/video/explain/video_2.mp4',
              cover: 'assets/video/explain/cover_2.png'
            },{
              title: 'Der digitale Himmel – der Tod der Zukunft',
              description: 'Irgendwann muss jeder Mensch sterben, dessen sind wir uns bewusst. Doch in all deinen Planungen hört alles mit deinem Tod auf. Geras24 macht genau da weiter. Du kannst zu Lebzeiten schon alles planen, damit deine Hinterbliebenen keine Bürokratie und unangenehme Termine haben. Das kannst du alles jetzt schon regeln. Der digitale Himmel Geras24- Up ist dafür da, in Ruhe zu trauern. Im Up schenken dir alle Menschen, die dich lieben, deine Aufmerksamkeit, die du verdient hast. ',
              path: 'assets/video/explain/video_3.mp4',
              cover: 'assets/video/explain/cover_3.png'
            },
          ],
          showLogin: false,
          showRegister: false,
        }
    },
    created() {
      this.setMetaTags()
    },
    mounted() {

    },
    methods: {

    }
}
</script>