import store from '../../../store/index'

export default (to, from, next) => {
    if (store.getters.isLoggedIn) {
        if (store.getters.user.user){
            next({name: 'dashboard/profile/info'})
        }
        else next()
        return false; 
    } 
    else next()
}