<template>
    <v-container
        fluid
        tag="section"
    >
        <!-- Main Section -->
        <v-row justify="center" class="px-8 lt-3 pb-8">
            <v-col cols="12" md="9">
                <p class="text-h5 main-font-bold main-title-color">{{ $t('truster_users') }}</p>
                <div class="subtitle-1 font-weight-light main-grey">
                    {{ $t('truster_users_subtitle') }}
                </div>
                <div class="subtitle-1 font-weight-light main-grey">
                    <v-icon color="warning" class="mr-2">mdi-cloud-upload</v-icon> {{ $t('truster_users_subtitle2') }}
                </div>
            </v-col>

            <v-col cols="12" md="3">
                <transition name="slide" mode="out-in">
                    <v-btn block v-if="uploadedCertificates.length" color="primary" outlined @click="uploadedCertificatesDialog = true">
                        <v-icon class="mr-2">mdi-format-list-checks</v-icon>
                        {{ $t('uploaded_certificates') }}
                    </v-btn>
                </transition>
            </v-col>
           
            <v-col cols="12" class="mt-5">
                <v-row v-if="!loading">
                    <v-col cols="12" md="3" v-for="user in users" :key="user.id">
                        <v-hover v-slot="{hover}">
                            <v-card elevation="5" :color="(hover || (selectedUser ? selectedUser.id == user.id : false)) ? 'primary' : (user.status == 'requested' ? 'grey' : 'white')" class="cursor-pointer" @click="selectedUser = user">
                                <v-card-text align="center" justify="center">
                                    <v-avatar size="70" class="mt-10">
                                        <v-img :src="user.avatar ? user.avatar : '/assets/img/avatar.png'">
                                            <template v-slot:placeholder>
                                                <v-row
                                                class="fill-height ma-0"
                                                align="center"
                                                justify="center"
                                                >
                                                <v-progress-circular
                                                    indeterminate
                                                    color="primary"
                                                ></v-progress-circular>
                                                </v-row>
                                            </template>
                                        </v-img>
                                    </v-avatar>
                                </v-card-text>
                                <v-card-text align="center" justify="center">
                                    <div class="text-subtitle-1" :class="{'white--text': (user.status == 'requested' || hover || (selectedUser ? selectedUser.id == user.id : false))}">{{ user.firstname }} {{ user.name }}</div>
                                    <div class="text-caption" :class="{'white--text': (user.status == 'requested' || hover || (selectedUser ? selectedUser.id == user.id : false))}">@{{ user.username ? user.username : '----' }}</div>
                                    <div class="text-caption mt-10 font-weight-bold" :class="{'primary--text': !(hover || (selectedUser ? selectedUser.id == user.id : false)), 'white--text': (user.status == 'requested' || hover || (selectedUser ? selectedUser.id == user.id : false))}"><span>  </span><span v-for="(group, i) in user.groups" :key="group.id">{{ i > 0 ? ' | ' : '' }} {{ group.name }}</span></div>
                                </v-card-text>
                                <v-card-actions v-if="user.status == 'requested'">
                                    <v-row>
                                        <v-col cols="12" align="center" justify="center">
                                            <div class="text-subtitle-2 white--text">{{ $t('confirm_trusted') }}</div>
                                        </v-col>
                                        <v-col cols="12" align="center" justify="center">
                                            <v-btn
                                                color="error"
                                                fab
                                                small
                                                class="mx-3"
                                                :disabled="rejectUserLoading ? true : false"
                                                :loading="rejectUserLoading ? rejectUserLoading == user.id : false"
                                                @click="rejectUser(user.id)"
                                            >
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                            
                                            <v-btn
                                                color="success"
                                                fab
                                                small
                                                class="mx-3"
                                                :disabled="acceptUserLoading ? true : false"
                                                :loading="acceptUserLoading ? acceptUserLoading == user.id : false"
                                                @click="acceptUser(user.id)"
                                            >
                                                <v-icon>mdi-check</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    
                                </v-card-actions>

                                <v-card-actions v-else-if="user.status == 'accepted'">
                                    <v-btn
                                        :color="(hover || (selectedUser ? selectedUser.id == user.id : false)) ? 'white' : 'grey'"
                                        text
                                        fab
                                        :disabled="rejectUserLoading ? true : false"
                                        :loading="rejectUserLoading ? rejectUserLoading == user.id : false"
                                        @click="rejectUser(user.id)"
                                    >
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                    <v-btn
                                        :color="(hover || (selectedUser ? selectedUser.id == user.id : false)) ? 'white' : 'warning'"
                                        text
                                        fab
                                        v-if="user.certificateUploader"
                                        :disabled="certificateFileLoading ? true : false"
                                        :loading="certificateFileLoading ? certificateFileLoading == user.id : false"
                                        @click="certificateUploadRequest(user.id)"
                                    >
                                        <v-icon>mdi-cloud-upload</v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-hover>
                    </v-col>
                    <v-col v-if="!users.length" justify="center" align="center" cols="12">
                        <v-icon x-large color="grey">mdi-alert-rhombus-outline</v-icon>
                        <div class="text-subtitle-1 mt-5 main-grey">{{ $t('empty_truster') }}</div>
                    </v-col>
                </v-row>
                <v-row v-else class="mb-5 mt-5" :key="0">
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-row>
            </v-col>

            <transition name="slide" mode="out-in">
                <v-col cols="12" class="mt-10" v-if="selectedUser">
                    <v-row>
                        <!-- Trusted User Informations -->
                        <v-col cols="9">
                            <v-row>
                                <v-col cols="12">
                                    <div class="text-h6 main-font-bold main-title-color">{{ selectedUser.name }}</div>
                                    <div class="subtitle-1 font-weight-light main-grey mt-0 pt-0">{{ $t('personal_information') }}</div>
                                </v-col>
                                <v-col cols="12">
                                    <v-row>
                                        <v-col cols="3">
                                            <div class="text-subtitle-1 main-title-color">{{ $t('name') }}</div>
                                            <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0">{{ selectedUser.name }}</div>
                                        </v-col>
                                        <v-col cols="3">
                                            <div class="text-subtitle-1 main-title-color">{{ $t('gender') }}</div>
                                            <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0">{{ selectedUser.gender || '---' }}</div>
                                        </v-col>
                                        <v-col cols="3">
                                            <div class="text-subtitle-1 main-title-color">{{ $t('martial_status') }}</div>
                                            <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0">{{ selectedUser.martial_status == 1 ? 'Verheiratet' : selectedUser.martial_status == 0 ? 'Single' : '---' }}</div>
                                        </v-col>
                                        <v-col cols="3">
                                            <div class="text-subtitle-1 main-title-color">{{ $t('email') }}</div>
                                            <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0">{{ selectedUser.email }}</div>
                                        </v-col>

                                        <v-col cols="3">
                                            <div class="text-subtitle-1 main-title-color">{{ $t('address') }}</div>
                                            <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0">{{ selectedUser.address || '---' }}</div>
                                        </v-col>
                                        <v-col cols="3">
                                            <div class="text-subtitle-1 main-title-color">{{ $t('nationality') }}</div>
                                            <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0">{{ selectedUser.nationality || '---' }}</div>
                                        </v-col>
                                        <v-col cols="3">
                                            <div class="text-subtitle-1 main-title-color">{{ $t('age') }}</div>
                                            <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0">{{ selectedUser.age  ? selectedUser.age : '--' }}</div>
                                        </v-col>
                                        <v-col cols="3">
                                            <div class="text-subtitle-1 main-title-color">{{ $t('phone') }}</div>
                                            <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0">{{ selectedUser.phone || '---' }}</div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="3">
                            <v-img src="/assets/img/dashboard/vector1.png" contain max-height="300px">
                            </v-img>
                        </v-col>
                    </v-row>
                </v-col>
            </transition>
        </v-row>

        <!-- Certificate Upload Confirm Dialog -->
        <v-dialog
            v-model="certificateUploadConfirmDialog"
            persistent
            max-width="390"
        >
            <v-card>
                <v-card-title class="text-h5">
                    {{ $t('are_you_sure') }}
                </v-card-title>

                <v-card-text>
                    {{ $t('upload_certificates_confirm_dialog') }}
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="error darken-1"
                        text
                        @click="certificateUploadRequest(selectedUser)"
                    >
                        {{ $t('yes') }}
                    </v-btn>

                    <v-btn
                        color="primary darken-1"
                        text
                        @click="certificateUploadConfirmDialog = false; selectedUser = null;"
                    >
                        {{ $t('no') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
        <!-- Certificate File Upload Dialog -->
        <v-dialog
            v-model="certificateUploadDialog"
            persistent
            max-width="700"
        >
            <v-card
                class="pa-4"
            >
                <v-card-title class="text-h5">
                    {{ $t('upload_certificate_dialog_title') }}
                </v-card-title>
                <v-card-text>
                    <span class="gery--text">
                        {{ $t('upload_certificates_dialog') }}
                    </span>
                </v-card-text>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-file-input
                                v-model="certificateFile"
                                color="warning"
                                :label="$t('certificate_file')"
                                :placeholder="$t('select_file')"
                                prepend-icon="mdi-paperclip"
                                outlined
                                :disabled="certificateFileLoading"
                                :show-size="1024"
                            ></v-file-input>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea
                                v-model="certificateFileDesc"
                                color="warning"
                                :label="$t('description')"
                                :placeholder="$t('certificate_file_desc')"
                                prepend-icon="mdi-message"
                                outlined
                                :disabled="certificateFileLoading"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="pt-0">
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        text
                        :disabled="certificateFileLoading ? true : false"
                        @click="certificateUploaderLoading = false; certificateUploadConfirmDialog = false; certificateUploadDialog = false; selectedUser = null;"
                    >
                        {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                        :disabled="!certificateFile || !certificateFileDesc || (certificateFileLoading ? true : false)"
                        :loading="(certificateFileLoading ? true : false)"
                        color="primary darken-3"
                        @click="certificateUpload"
                    >
                        {{ $t('upload') }}
                        <v-icon right class="ml-2">
                            mdi-cloud-upload
                        </v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Uploaded Certificates Dialog -->
        <v-dialog
            v-model="uploadedCertificatesDialog"
            max-width="900"
        >
            <v-card
                class="pa-4"
            >
                <v-card-title class="text-h5">
                    {{ $t('uploaded_certificates') }}
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                           <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">#</th>
                                            <th class="text-left">{{ $t('truster') }}</th>
                                            <th class="text-left"></th>
                                            <th class="text-left">{{ $t('date') }}</th>
                                            <th class="text-left">{{ $t('status') }}</th>
                                            <th class="text-center">{{ $t('actions') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(file, i) in uploadedCertificates" :key="file.id">
                                            <td>{{ i + 1 }}</td>
                                            <td class="pa-1"><v-avatar size="60"><v-img :src="file.user.avatar || '/assets/img/avatar.png'"></v-img></v-avatar></td>
                                            <td>{{ file.user.name }}</td>
                                            <td>{{ file.created_at }}</td>
                                            <td>{{ file.status }}</td>
                                            <td class="text-center">
                                                <a 
                                                    v-auth-href
                                                    :href="file.url">
                                                    <v-btn 
                                                        medium 
                                                        text 
                                                        fab 
                                                        color="grey" 
                                                    >
                                                        <v-icon>mdi-cloud-download</v-icon>
                                                    </v-btn>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="pt-0">
                    <v-spacer></v-spacer>
                    <v-btn
                        color="grey"
                        text
                        @click="uploadedCertificatesDialog = false"
                    >
                        {{ $t('close') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            loading: true,
            users: null,

            activeTab: false,
            selectedUser: null,
            acceptUserLoading: false,
            rejectUserLoading: false,

            certificateUploaderLoading: false,
            certificateUploadDialog: false,
            certificateUploadConfirmDialog: false,
            certificateFile: null,
            certificateFileDesc: null,
            certificateFileLoading: false,

            uploadedCertificates: [],
            uploadedCertificatesDialog: false,
            uploadedCertificatesDownloadLoading: false,
        };
    },
    mounted(){
        this.getTrusters()
        this.getUploadedCertificates()
    },
    methods: {
        getTrusters(){
            this.loading = true
            this.$store.dispatch('getTrusterUsers')
            .then(resp => {
                this.users = resp
                this.loading = false
                this.selectedUser = null
            })
        },

        rejectUser(id){
            this.rejectUserLoading = id
            this.$store.dispatch('setRejectTruster', { trusterUser: id })
            .then(resp => {
                this.$store.dispatch('getTrusterUsers')
                .then(resp => {
                    this.users = resp
                    this.rejectUserLoading = false
                    this.selectedUser = null
                })
            })
        },

        acceptUser(id){
            this.acceptUserLoading = id
            this.$store.dispatch('setAcceptTruster', { trusterUser: id })
            .then(resp => {
                this.$store.dispatch('getTrusterUsers')
                .then(resp => {
                    this.users = resp
                    this.acceptUserLoading = false
                    this.selectedUser = null
                })
            })
        },

        certificateUploadRequest(user){
            if (this.selectedUser){
                this.certificateUploaderLoading = true
                this.certificateUploadDialog = true
            }
            else {
                this.selectedUser = user
                this.certificateUploadConfirmDialog = true
            }
        },

        certificateUpload(){
            if (!this.certificateFile) {
                this.$helpers.showMessage('error', this.$t('enter_certificate_file'))
                return
            }
            if (!this.certificateFileDesc) {
                this.$helpers.showMessage('error', this.$t('enter_certificate_file_desc'))
                return
            }
            this.certificateFileLoading = this.selectedUser

            let data = new FormData()
            data.append('file', this.certificateFile)
            data.append('desc', this.certificateFileDesc)
            data.append('user', this.selectedUser.id)

            this.$store.dispatch('certificateUpload', data)
            .then(resp => {
                this.selectedUser = null
                this.certificateUploaderLoading = false
                this.certificateUploadDialog = false
                this.certificateUploadConfirmDialog = false
                this.certificateFile = null
                this.certificateFileLoading = false
            })
            .catch(err => {
                this.$helpers.showError(err)
                this.certificateFileLoading = false
            })
        },

        getUploadedCertificates(){
            this.$store.dispatch('getUploadedCertificates')
            .then(resp => {
                this.uploadedCertificates = resp
            })
        },

        downloadCertificateFile(file){
            this.uploadedCertificatesDownloadLoading = file.id
            var element = document.createElement('a');
            element.setAttribute('href', file.url);
            element.setAttribute('download', file.user.name);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
            this.uploadedCertificatesDownloadLoading = false
        },
    }
};
</script>