<template>
    <v-container class="auth-form">
        <v-row class="mt-1">
            <h1 class="auth-title">{{ $t('register') }}</h1>    
        </v-row>

        <v-row class="mt-1">
            <v-text-field
                :label="$t('name')"
                :disabled="loading"
                filled
                rounded
                :name="Math.random()"
                v-model="form.name"
            ></v-text-field>
        </v-row>
        <v-row class="mt-1">
            <v-text-field
                :label="$t('username')"
                :disabled="loading"
                filled
                rounded
                :name="Math.random()"
                v-model="form.username"
            ></v-text-field>
        </v-row>
        <v-row class="mt-1">
            <v-text-field
                :label="$t('email')"
                :disabled="loading"
                filled
                rounded
                :name="Math.random()"
                v-model="form.email"
            ></v-text-field>
        </v-row>
        <v-row class="mt-1">
            <v-text-field
                :label="$t('password')"
                type="password"
                :disabled="loading"
                filled
                rounded
                :name="Math.random()"
                v-model="form.password"
            ></v-text-field>
        </v-row>
        <v-row class="mt-1">
            <v-text-field
                :label="$t('password_confirm')"
                type="password"
                :disabled="loading"
                filled
                rounded
                :name="Math.random()"
                v-model="form.password_confirmation"
            ></v-text-field>
        </v-row>
<!--        <v-row class="mt-1">-->
<!--            <v-text-field-->
<!--                :label="$t('referral_code')"-->
<!--                :disabled="loading || $route.query.c"-->
<!--                filled-->
<!--                rounded-->
<!--                :name="Math.random()"-->
<!--                v-model="form.referred_by"-->
<!--            ></v-text-field>-->
<!--        </v-row>-->
        <v-row class="mt-2" justify="center">
            <v-btn
                :loading="loading"
                :disabled="loading"
                color="white"
                block
                rounded
                @click="register"
            >

              Registrieren
<!--                <v-icon light>-->
<!--                    mdi-lock-->
<!--                </v-icon>-->
            </v-btn>
        </v-row>
      <v-row>
        <v-col>
          <v-checkbox
              label="Ich habe die <a href='/datenschutz'>Datenschsutzerklärung</a> zur Kenntnis genommen"
              color="indigo"
              :value="false"
              hide-details
          ></v-checkbox>
        </v-col>
      </v-row>
        <v-row class="mt-2">
            <v-col justify="center" align="center">
                <p><router-link :to="{name: 'login', params: { id: this.$route.params.id || null }}" class="primary--text">{{ $t('login') }}</router-link> {{ $t('with_your_account') }}</p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Vue from "vue";

export default {
    data(){
        return {
            loading:false,
            form: {
                name: null,
                username: null,
                email: null,
                password: null,
                password_confirmation: null,
                referred_by: this.$route.query.c || null
            }
        }
    },
    methods: {
        register(){
            if (!this.form.name){
                Vue.$toast.error(this.$t('enter_name'))
                return
            }
            if (!this.form.email){
                Vue.$toast.error(this.$t('enter_email'))
                return
            }
            if (!this.form.password){
                Vue.$toast.error(this.$t('enter_password'))
                return
            }
            if (!this.form.password_confirmation){
                Vue.$toast.error(this.$t('enter_password_confirm'))
                return
            }
            if (this.form.password != this.form.password_confirmation){
                Vue.$toast.error(this.$t('password_confirm_error'))
                return
            }
            this.loading = true

            if (this.$route.params.id) this.form.plan_id = this.$route.params.id

            this.$store.dispatch('register', this.form)
            .then(resp => {
                Vue.$toast.success(this.$t('register_send_activation_email'))
                this.form = {}
                this.$router.push({name: 'login'})
                this.loading = false
            })
            .catch(err => {
                this.$helpers.showError(err)
                this.loading= false
            })
        }
    }
}
</script>