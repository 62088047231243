<template>
    <v-container
        fluid
        tag="section"
    >
        <v-row justify="center" class="pl-8 pr-8 pb-8 pt-3">
            <v-col cols="12">
                <div class="text-h6 main-font-bold main-title-color">{{ $t('pay_verify') }}</div>
            </v-col>
            <v-col cols="12">
                <v-row v-if="!loading">
                    <v-col cols="6" offset="3">
                        <v-card elevation="4">
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" align="center" justify="center">
                                        <v-icon x-large color="success" v-if="result">mdi-check-decagram</v-icon>
                                        <v-icon x-large color="error" v-else>mdi-close</v-icon>
                                    </v-col>
                                    <v-col cols="12" align="center" justify="center">
                                        <span class="text-subtitle-1 font-weight-bold" v-if="result">{{ $t('pay_success') }}</span>
                                        <span class="text-subtitle-1 font-weight-bold" v-else>{{ $t('pay_error') }}</span>
                                    </v-col>
                                    <v-col cols="12" align="center" justify="center">
                                        <router-link :to="{ name: 'profilePlans' }">
                                            <v-btn color="white" fab>
                                                <span class="primary--text"><v-icon>mdi-arrow-left</v-icon></span>
                                            </v-btn>
                                        </router-link>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row v-else class="mb-5 mt-5" :key="0">
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data(){
        return {
            loading: true,    
            result: null        
        }
    },
    mounted(){
        this.verify()
    },
    methods:{
        verify(){
            var params = null
            for (const [key, value] of Object.entries(this.$route.query)) {
                params = params ? `${params}&${key}=${value}` : `${key}=${value}` 
            }

            this.$store.dispatch('getPaymentVerify', {
                id: this.$route.params.id,
                status: this.$route.params.status,
                params: params,
            })
            .then(resp => {
                this.loading = false
                if (this.$route.params.status == 'success') {
                    this.result = true
                    this.$store.dispatch('getMe')
                }                
                else this.result = false
            })
            .catch(err => {
                this.result = false
                this.loading = false
            })
        },
    }
};
</script>
