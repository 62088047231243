<template>
    <section>
      <Header @open-login-modal="showLogin = true" @open-register-modal="showRegister = true" />
      <v-container>
        <v-row class="mt-10">
          <v-col cols="12">
            <h1>Kontakt</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col justify="center">
            <v-card
                class="pa-4 mx-auto mb-5"
                max-width="600"
            >
              <!--          <v-card-title class="text-h4">-->
              <!--            Schreiben Sie uns:-->
              <!--          </v-card-title>-->
              <!--          <v-card-text class="primary" v-if="!editingTrustedUser">-->
              <!--                    <span class="white&#45;&#45;text">-->
              <!--                        {{ $t('add_trusted_user_desc') }}-->
              <!--                    </span>-->
              <!--          </v-card-text>-->

              <v-card-text>
                <h3>Schreib uns eine Nachricht:</h3>
              </v-card-text>
              <v-card-text class="mt-1">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        class="pa-0"
                        placeholder="Name"
                        v-model="contact.name"
                        :loading="sending"
                        :disabled="sending"
                        outlined
                    ></v-text-field>
                    <v-text-field
                        class="pa-0"
                        placeholder="E-Mail"
                        v-model="contact.email"
                        :loading="sending"
                        :disabled="sending"
                        outlined
                        @keyup="validateEmail"
                    ></v-text-field>
                    <v-textarea
                        class="pa-0"
                        placeholder="Nachricht"
                        v-model="contact.message"
                        :loading="sending"
                        :disabled="sending"
                        outlined
                    ></v-textarea>
                    <vue-honeypot ref="honeypot" />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="pt-0">
                <v-spacer></v-spacer>
                <v-btn
                    :disabled="!contact.name ||
                        !contact.email ||
                        !emailIsValid ||
                        !contact.message ||
                        sending"
                    :loading="sending"
                    color="primary"
                    @click="sendMessage()"
                >
                  Nachricht senden
                  <v-icon right>
                    mdi-send
                  </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <Footer></Footer>

      <login-dialog @close-login-modal="showLogin = false" @open-register-modal="showRegister = true" v-model="showLogin"></login-dialog>
      <register-dialog @close-register-modal="showRegister = false" @open-login-modal="showLogin = true" v-model="showRegister"></register-dialog>

    </section>
</template>

<script>
import Header from "./Header.vue"
import Footer from "./Footer.vue"
import LoginDialog from "../../widgets/LoginDialog.vue"
import RegisterDialog from "../../widgets/RegisterDialog.vue"
import VueHoneypot from 'vue-honeypot'

export default {
    components:{
      Header,
      Footer,
      LoginDialog,
      RegisterDialog,
      VueHoneypot
    }, 
    data(){
        return {
          showLogin: false,
          showRegister: false,
          contact: {},
          emailIsValid: false,
          sending: false
        }
    },
    created() {
      this.setMetaTags()
    },
    mounted() {
    },
    methods: {
      sendMessage() {
        this.sending = true

        try {
          this.$refs.honeypot.validate()

          this.$store.dispatch('sendContactEmail', this.contact)
              .then(resp => {
                this.sending = false
                this.contact = {}
                this.$helpers.showMessage('success', this.$t('send_contact_email_success'))
              })
              .catch(err => {
                this.sending = false
                this.$helpers.showError(err)
              })
        } catch (error) {
          this.$helpers.showError(error)
        }
      },
      validateEmail() {
        if(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.contact.email)) {
          this.emailIsValid = true
        } else {
          this.emailIsValid = false
        }
      }
    }
}
</script>