<template>
    <v-container
        fluid
        tag="section"
    >
        <v-row justify="center" class="pl-8 pr-8 pb-8 pt-3">
            <v-col cols="12">
                <v-row v-if="user">
                    <v-col cols="12">
                        <div class="text-h6 main-font-bold main-title-color">{{ $t('account_info') }}</div>
                    </v-col>
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12" md="4" class="py-2">
                                <div class="text-subtitle-1 main-title-color">{{ $t('username') }} <v-icon color="primary" class="ml-2">mdi-edit</v-icon></div>
                                <transition name="slide" mode="out-in">
                                    <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4" v-if="active != 'name'" @click="activeLoading ? null :active = 'name'">{{ user.name  ? user.name : $t('tap_to_edit') }}</div>
                                    <v-text-field 
                                        dense
                                        hide-details
                                        class="pa-0"
                                        v-else append-outer-icon="mdi-check" 
                                        v-model="user.name" 
                                        :loading="activeLoading == 'name'"
                                        :disabled="activeLoading == 'name'"
                                        @click:clear="active = false"
                                        @click:append-outer="updateProfile('name')"
                                        @keyup.enter="updateProfile('name')"
                                    ></v-text-field>
                                </transition>
                            </v-col>

                            <v-col cols="12" md="8" class="py-2">
                                <div class="text-subtitle-1 main-title-color">{{ $t('password') }}</div>
                                <transition name="slide" mode="out-in">
                                    <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4" v-if="active != 'password'" @click="activeLoading ? null : active = 'password'">{{ $t('tap_to_edit') }}</div>
                                    <v-row v-else :key="1">
                                        <v-col cols="12">
                                            <v-text-field 
                                                dense
                                                hide-details
                                                class="pa-0"
                                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                                :type="show1 ? 'text' : 'password'"
                                                :placeholder="$t('current_password')"
                                                v-model="password.password" 
                                                :loading="activeLoading == 'password'"
                                                :disabled="activeLoading == 'password'"
                                                @click:append="show1 = !show1"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col>
                                            <v-text-field 
                                                dense
                                                hide-details
                                                class="pa-0"
                                                :placeholder="$t('new_password')"
                                                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                                                :type="show2 ? 'text' : 'password'"
                                                :hint="$t('password_hint')"
                                                v-model="password.new_password" 
                                                :loading="activeLoading == 'password'"
                                                :disabled="activeLoading == 'password'"
                                                @click:append="show2 = !show2"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col>
                                            <v-text-field 
                                                dense
                                                hide-details
                                                class="pa-0"
                                                :append-outer-icon="(
                                                    password.password || 
                                                    password.new_password || 
                                                    password.new_password_confirmation
                                                ) ? 'mdi-check' : 'mdi-close'" 
                                                :placeholder="$t('confirm_new_password')"
                                                :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                                                :type="show3 ? 'text' : 'password'"
                                                :hint="$t('password_hint')"
                                                v-model="password.new_password_confirmation" 
                                                :loading="activeLoading == 'password'"
                                                :disabled="activeLoading == 'password'"
                                                @click:append-outer="(
                                                    password.password || 
                                                    password.new_password || 
                                                    password.new_password_confirmation
                                                ) ? changePass() : active = false"
                                                @keyup.enter="changePass"
                                                @click:append="show3 = !show3"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </transition>
                            </v-col>
                            
                        </v-row>
                    </v-col>
                </v-row>

                <v-row class="mt-16" align="center" justify="center">
                    <v-col cols="12">
                        <div class="text-h6 main-font-bold main-title-color">
                            <v-icon class="mr-4">mdi-qrcode-scan</v-icon>
                          Zwei-Faktor-Authentifizierung
                        </div>
                    </v-col>
                    <v-col cols='12' md="3">
                        <v-row>
                            <v-col cols="12" v-if="$store.getters.user.user.otp_status == 'disabled'">
                                <v-card align="center">
                                    <v-card-text v-html="secret.qrcode"></v-card-text>

                                    <v-card-text>
                                        <div>Secret Code:</div>
                                        <div class="text-h5">{{ secret.secret }}</div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field 
                                    class="pa-0"
                                    :placeholder="$t('otp_code')"
                                    :hint="$store.getters.user.user.otp_status == 'enabled' ? 'Sicherheitscode eingeben um 2FA zu deaktivieren' : 'Sicherheitscode eingeben um 2FA zu aktivieren'"
                                    persistent-hint
                                    v-model="otp" 
                                    :loading="loadingOtp"
                                    :disabled="loadingOtp"
                                ></v-text-field> 
                            </v-col>
                            <v-col cols="12" >
                                <v-btn
                                    :loading="loadingOtp"
                                    :disabled="loadingOtp || !otp"
                                    color="primary"
                                    block
                                    v-if="$store.getters.user.user.otp_status == 'disabled'"
                                    @click="verify"
                                >
                                Aktivieren
                                </v-btn> 
                                <v-btn
                                    :loading="loadingOtp"
                                    :disabled="loadingOtp || (!otp && !this.$store.getters.secondUser)"
                                    color="primary"
                                    block
                                    v-if="$store.getters.user.user.otp_status == 'enabled'"
                                    @click="changeOtp"
                                >
                                Deaktivieren
                                </v-btn> 
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data(){
        return {
            loading: true,            
            user: null,
            active: false,
            activeLoading: false,
            password: {},
            show1: false,
            show2: false,
            show3: false,
            tfaSwitch: true,
            loadingOtp: false,
            otp: null,
            secret: {}
        }
    },
    mounted(){
        this.getMe()
    },
    methods:{
        getMe(){
            this.loading = true
            this.$store.dispatch('getMe')
            .then(resp => {
                this.user = resp
                this.loading = false
                this.active = false
                this.activeLoading = false

                if (this.$store.getters.user.user.otp_status == 'disabled') this.getSecret()
            })
        },

        getSecret(){
            this.$store.dispatch('getOtpSecret', this.$store.getters.token)
            .then(resp => {
                this.secret = resp
            })
            .catch(err => {
                this.$helpers.showError(err)
            })
        },

        updateProfile(tag){
            this.activeLoading = tag
            this.$store.dispatch('updateProfile', this.user)
            .then(resp => {
                this.getMe()
            })
            .catch(error => {
                this.$helpers.showError(error)
                this.activeLoading = false
            })
        },

        changePass(){
            this.activeLoading = 'password'
            this.$store.dispatch('updatePassword', this.password)
            .then(resp => {
                this.loading = false
                this.active = false
                this.activeLoading = false
                this.password = {}
                this.show1 = false
                this.show2 = false
                this.show3 = false
            })
            .catch(error => {
                this.$helpers.showError(error)
                this.activeLoading = false
            })
        },

        changeOtp(event){
            if (!this.otp && !this.$store.getters.secondUser) {
                this.$helpers.showMessage('error', this.$t('enter_otp'))
                return
            }
            this.loadingOtp = true

            this.$store.dispatch('disableOtp', {
                otp: this.otp
            })
            .then(resp => {
                this.$store.dispatch('getMe')
                .then(resp => {
                    this.loadingOtp = false
                })
                this.$helpers.showMessage('success', this.$t('success_otp_disabled'))
                
                
            })
            .catch(error => {
                this.$helpers.showError(error)
                this.loadingOtp = false
            })
        }, 

        verify(){
            if (!this.otp){
                this.$helpers.showMessage('error', this.$t('enter_otp'))
                return
            }
        
            this.loadingOtp = true
            this.$store.dispatch('verifyOtp', {
                otp: this.otp,
                secret: this.secret.secret,
                token: this.$store.getters.token
            })
            .then(resp => {
                this.$helpers.showMessage('success', this.$t('success_otp_enabled'))
                this.$store.dispatch('getMe')
                .then(resp => {
                    this.loadingOtp = false
                })
            })
            .catch(err => {
                this.$helpers.showError(err)
                this.loadingOtp= false
            })
        }
    }
};
</script>