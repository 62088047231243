<template>
    <v-container
        fluid
        tag="section"
    >
        <v-row>
            <v-col cols="auto">
                <p class="text-h5 main-font-bold">{{ $t('upload_new_file') }}</p>
            </v-col>
            <v-col align="right" justify="end">
                <router-link :to="{ name: 'advisorIndex' }">
                    <v-btn color="white" fab>
                        <span class="primary--text"><v-icon>mdi-arrow-right</v-icon></span>
                    </v-btn>
                </router-link>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12">
                <v-form v-if="!loading">
                    <v-container class="py-5 pt-10">
                        <v-row>
                            <!-- File Input -->
                            <v-col cols="12" md="6">
                                <v-file-input
                                    v-model="file"
                                    color="primary darken-1"
                                    :label="$t('new_file')"
                                    :placeholder="$t('select_file')"
                                    prepend-icon="mdi-paperclip"
                                    outlined
                                    :disabled="loading"
                                    :show-size="1024"
                                    :error="!file && fileError"
                                >
                                    <template v-slot:selection="{ index, text }">
                                        <v-chip
                                            v-if="index < 2"
                                            color="primary darken-1"
                                            dark
                                            label
                                            small
                                        >
                                            {{ text }}
                                        </v-chip>

                                        <span
                                            v-else-if="index === 2"
                                            class="text-overline grey--text text--darken-3 mx-2"
                                        >
                                            +{{ files.length - 2 }} {{ $t('file') }}(s)
                                        </span>
                                    </template>
                                </v-file-input>
                            </v-col>

                            <!-- File Desc -->
                            <v-col cols="12" md="6">
                                <v-text-field
                                    outlined
                                    :label="$t('file_desc')"
                                    prepend-icon="mdi-card-text-outline"
                                    maxlength="200"
                                    counter
                                    :disabled="loading"
                                    v-model="desc"
                                    :error="!desc && descError"
                                ></v-text-field>
                            </v-col>

                            <!-- Buttons -->
                            <v-col
                                cols="12"
                                justify="right"
                                align="right"
                            >
                                <v-btn
                                    large
                                    :loading="uploading"
                                    :disabled="uploading"
                                    color="blue-grey"
                                    class="ma-2 white--text px-10"
                                    @click="uploadFile"
                                >
                                    {{ $t('upload') }}
                                    <v-icon
                                        right
                                        dark
                                    >
                                        mdi-cloud-upload
                                    </v-icon>
                                </v-btn>
                            </v-col>

                            <transition
                                name="slide"
                                mode="out-in"
                            >
                                <v-col
                                    v-if="loading"
                                    cols="12"
                                    align="center"
                                    justify="center"
                                >
                                    <v-progress-linear
                                        :value="percent"
                                        height="25"
                                        striped
                                    >
                                        <strong>{{ Math.ceil(percent) }}%</strong>
                                    </v-progress-linear>
                                    <v-row class="mt-3">
                                        <v-col
                                            align="center"
                                            justify="center"
                                        >
                                            <p>
                                                {{ $t('uploading') }} (<span class="error--text">{{ $t('dont_close_page') }}</span>)
                                            </p>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </transition>
                        </v-row>
                    </v-container>
                </v-form>
                <v-row v-else class="mb-5 mt-5" :key="0">
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Vue from "vue";

export default {
    data() {
        return {
            loading: false,
            percent: 0,

            desc: null,
            descError: false,
            fileError: false,
            file: null,
            uploading: false,
        };
    },
    methods: {
        uploadFile(){
            if (!this.file){
                this.fileError = true
                setTimeout(() => {
                    this.fileError = false
                }, 2000);
                return
            }
            if (!this.desc){
                this.descError = true
                setTimeout(() => {
                    this.descError = false
                }, 2000);
                return
            }
            this.uploading = true
            var formData = new FormData();
            formData.append('file', this.file, this.file.name);
            formData.append('desc', this.desc);
            formData.append('category_id', this.$route.params.category_id);
            
            this.$store.dispatch('uploadAdvisorFile', {data: formData} )
            .then(resp => {
                Vue.$toast.success(this.$t('file_upload_success'))
                this.$router.push({name: 'advisorIndex', params: { category_id: this.$route.params.category_id }})
            })
            .catch(err => {
                this.uploading = false
                this.$helpers.showError(err)
            })
        },
        onProgress(percent) {
            this.percent = percent
        },
    }
};
</script>