<template>
    <v-container
        fluid
        tag="section"
    >
        <!-- Main Section -->
        <v-row v-if="show" justify="center" class="pt-3 px-8 pb-8">
            <v-col cols="12">
                <p class="text-h5 main-font-bold">{{ $t('up') }}</p>
                <div class="subtitle-1 font-weight-light">
                  <v-btn
                      color="primary"
                      elevation="5"
                      href="https://www.up-geras24.de/account"
                      target="_blank"
                  >
                    <span class="white--text">Hier geht's zum Up</span>
                  </v-btn>
<!--                    {{ $t('up_subtitle') }}-->
                </div>
            </v-col>
        </v-row>
      <v-row justify="center">
        <v-col cols="12" class="up-background">
        </v-col>
      </v-row>

      <!-- Payment Required Dialog -->
      <v-dialog
          v-model="paymentRequireDialog"
          max-width="450"
          persistent
      >
        <v-card>
          <v-card-title class="text-h5">
            {{ $t('need_payment') }}
          </v-card-title>

          <v-card-text>
            {{ $t('need_payment_desc') }}
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                color="error darken-1"
                text
                @click="$router.push({ name: 'profileInfo' })"
            >
              {{ $t('later') }}
            </v-btn>

            <v-btn
                color="primary darken-1"
                text
                @click="$router.push({ name: 'profilePlans' })"
            >
              {{ $t('buy_now') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-container>
</template>

<script>
export default {
  data(){
    return {
      loading: true,
      paymentRequireDialog: false,
      show: false
    }
  },
  mounted(){
    this.checkUpFeature()
  },
  methods: {
    checkUpFeature() {
      this.loading = true
      this.$store.dispatch('checkUpFeature')
          .then(resp => {
            this.loading = false
            this.show = true
          })
          .catch(err => {
            this.loading = false
            if (err.response.status === 402) this.paymentRequireDialog = true
            else this.$helpers.showError(err)
          })
    },
  }
};
</script>