<template>
    <section>
        <v-app-bar
            id="app-bar"
            fixed
            app
            color="white"
            flat
            style="left: 0;"
            height="100"
        >

            <router-link :to="{name: 'upMain'}" v-if="!$helpers.isMobile()">
                <v-img src="/assets/img/icons/up.svg" width="70px"></v-img>
            </router-link>

            <v-spacer  v-if="!$helpers.isMobile()"/>

            <router-link :to="{name: 'home'}" :style="`margin-left: ${$store.getters.isLoggedIn && !$helpers.isMobile() ? '360' : '30'}px`">
                <v-img :src="$store.getters.settings.logo_d || ''" :width="$helpers.isMobile() ? '100px' : '215px'"></v-img>
            </router-link>

            <v-spacer/>

            <div>
                <v-btn
                    v-if="!$store.getters.isLoggedIn" 
                    class="ml-2"
                    min-width="0"
                    text
                    @click="$router.push({name: 'login'})"
                >
                    <span class="text-caption">{{ $t('login') }}</span> <v-icon small class="ml-2">mdi-lock</v-icon>
                </v-btn>
                <v-btn
                    v-else
                    class="ml-2"
                    min-width="0"
                    text
                    @click="logout()"
                    :loading="logouting"
                    :disabled="logouting"
                >
                    <span>{{ $t('logout') }}</span> <v-icon small class="ml-2">mdi-logout</v-icon>
                </v-btn>
            </div>

            <v-menu
                v-if="$store.getters.isLoggedIn && !$helpers.isMobile()"
                bottom
                left
                offset-y
                origin="top right"
                transition="scale-transition"
            >
                <template v-slot:activator="{ attrs, on }">
                    <v-btn
                        min-width="0"
                        text
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon small>mdi-bell</v-icon>
                    </v-btn>
                </template>

                <v-list
                    :tile="false"
                    nav
                >
<!--                    <div>-->
<!--                        <app-bar-item-->
<!--                            v-for="(n, i) in notifications"-->
<!--                            :key="`item-${i}`"-->
<!--                        >-->
<!--                            <v-list-item-title v-text="n" />-->
<!--                        </app-bar-item>-->
<!--                    </div>-->
                </v-list>
            </v-menu>

            <v-btn
                v-if="$store.getters.isLoggedIn && !$helpers.isMobile()"
                min-width="0"
                text
                @click="$router.push({name: 'profile'})"
            >
                <v-icon small>mdi-cog</v-icon>
            </v-btn>

            <v-btn
                v-if="$store.getters.isLoggedIn && $store.getters.user.user ? $store.getters.user.user.role == 'Owner' : false"
                class="ml-2"
                color="error"
                min-width="0"
                text
                @click="$router.push({name: 'profile'})"
            >
                <v-avatar size="48">
                    <img
                        :src="$store.getters.user.user.avatar || '/assets/img/avatar.png'"
                    >
                </v-avatar>
                <div v-if="!$helpers.isMobile()" class="ml-5 text-body-1 main-title-color">{{ $store.getters.user.user.firstname }} {{ $store.getters.user.user.name }}</div>
            </v-btn>

            <a v-else-if="$store.getters.isLoggedIn" href="/admin/dashboard">
                <v-btn
                    class="ml-2"
                    color="error"
                    min-width="0"
                    text
                >
                    <v-avatar size="48">
                        <img
                            :src="$store.getters.user.user.avatar || '/assets/img/avatar.png'"
                        >
                    </v-avatar>
                    <div v-if="!$helpers.isMobile()" class="ml-5 text-body-1 main-title-color">{{ $store.getters.user.user.firstname }} {{ $store.getters.user.user.name }}</div>
                </v-btn>
            </a>
        </v-app-bar>
    </section>
</template>

<script>
// Components
import { VHover, VListItem } from "vuetify/lib";

// Utilities
import { mapState, mapMutations } from "vuex";

export default {
    name: "DashboardCoreAppBar",

    components: {
        AppBarItem: {
            render(h) {
                return h(VHover, {
                    scopedSlots: {
                        default: ({ hover }) => {
                            return h(
                                VListItem,
                                {
                                    attrs: this.$attrs,
                                    class: {
                                        "black--text": !hover,
                                        "white--text secondary elevation-12": hover
                                    },
                                    props: {
                                        activeClass: "",
                                        dark: hover,
                                        link: true,
                                        ...this.$attrs
                                    }
                                },
                                this.$slots.default
                            );
                        }
                    }
                });
            }
        }
    },

    props: ['payload'],

    data: () => ({
        notifications: [
            "Mike John Responded to your email",
            "You have 5 new tasks",
            "You're now friends with Andrew",
            "Another Notification",
            "Another one"
        ],
        logouting: false
    }),

    computed: {
        ...mapState(["drawer"])
    },

    methods: {
        appBarImage(){
            switch (this.$route.name) {
                // case 'tresor':
                //     return '/assets/img/dashboard/app-banner.png'
                default:
                    return `/assets/img/dashboard/app-banner${Math.floor(Math.random() * (3 - 1 + 1) ) + 1}.png`
            }
        },

        ...mapMutations({
            setDrawer: "SET_DRAWER"
        }),

        logout(){
            this.logouting = true
            this.$store.dispatch('logout')
            .then(resp => {
                this.$router.go({name: 'home'})
            })
            .catch(err => {
                this.logouting = false
                this.$helpers.showError(err)
            })
        }
    }
};
</script>
