<template>
    <section>
      <Header @open-login-modal="showLogin = true" @open-register-modal="showRegister = true" />

      <v-container class="max-width mb-16">
        <v-row class="mt-10">
          <v-col cols="12">
            <h1>Allgemeine Verkaufsbedingungen</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="main-font">
            <div v-html="content"></div>
          </v-col>
        </v-row>
      </v-container>

      <Footer></Footer>

      <login-dialog @close-login-modal="showLogin = false" @open-register-modal="showRegister = true" v-model="showLogin"></login-dialog>
      <register-dialog @close-register-modal="showRegister = false" @open-login-modal="showLogin = true" v-model="showRegister"></register-dialog>

    </section>
</template>

<script>
import TextArea from "../../widgets/TextArea.vue";
import Header from "./Header.vue";
import Footer from "../../components/public/Footer.vue";
import LoginDialog from "../../widgets/LoginDialog.vue";
import RegisterDialog from "../../widgets/RegisterDialog.vue";

export default {
    components:{
      TextArea,
      Header,
      Footer,
      LoginDialog,
      RegisterDialog
    }, 
    data(){
        return {
            content:
                "<p>\n" +
                "    <strong>Geltungsbereich</strong>\n" +
                "</p>\n" +
                "<p>\n" +
                "    Diese Geschäftsbedingungen gelten für alle Käufe bei Geras24 vertreten\n" +
                "    durch die <a href='/impressum' class='dark'>Geras24 GmbH</a>, die von Privatkunden\n" +
                "    getätigt werden.\n" +
                "</p>\n" +
                "<p>\n" +
                "    Privatkunden in diesem Sinne sind Personen mit Wohnsitz und Lieferadresse\n" +
                "    in der Bundesrepublik Deutschland, Östereich und der Schweiz soweit die von\n" +
                "    ihnen bestellten Waren weder ihrer gewerblichen noch ihrer selbstständigen\n" +
                "    beruflichen Tätigkeit zugerechnet werden können. <strong> </strong>\n" +
                "</p>\n" +
                "<p>\n" +
                "    <strong>Vertragsabschluss</strong>\n" +
                "</p>\n" +
                "<p>\n" +
                    "Die Präsentation unserer Digitalen Dienstleistung  und der Einräumung der Möglichkeit zur Bestellung stellt unsererseits ein konkretes Angebot zum Abschluss eines Kaufvertrages dar.\n" +
                    "\n" +
                    "Durch Ihre Bestellung nehmen Sie das Angebot an und der Kaufvertrag ist zustande gekommen.\n" +
                    "\n" +
                    "Hierüber erhalten Sie eine Bestätigung per E-Mail an die von Ihnen angegebene E-Mail-Adresse." +
                "</p>\n" +
                "<p>\n" +
                "    <strong>Preise und Versandkosten</strong>\n" +
                "</p>\n" +
                "<p>\n" +
                "    Die ausgezeichneten Preise sind Endpreise inkl. Umsatzsteuer. Es gilt der\n" +
                "    Betrag, der jeweils zum Zeitpunkt der verbindlichen Bestellung ausgewiesen\n" +
                "    ist. <br><a href='/preise' class='dark'>Unsere Preise</a>\n" +
                "</p>\n" +
                "<p>\n" +
                "    <strong>Zahlung</strong>\n" +
                "</p>\n" +
                "<p>\n" +
                "    Die Bezahlung erfolgt bei Lieferung und Nutzung und ist sofort fällig.\n" +
                "</p>\n" +
                "<p>\n" +
                "    Es sind alle Zahlungsmethoden die im Preismodel vorzufinden sind erlaubt.\n" +
                "</p>\n" +
                "<p>\n" +
                "    <strong>Zahlungsverzug</strong>\n" +
                "</p>\n" +
                "<p>\n" +
                "    Kommen Sie in Zahlungsverzug, so ist die Geras24\n" +
                "    GmbH berechtigt, Verzugszinsen in Höhe von 5 Prozentpunkten über dem von\n" +
                "    der Deutschen Bundesbank für den Zeitpunkt der Bestellung bekannt gegebenen\n" +
                "    Basiszinssatz p.a. zu fordern. Falls Geras24 ein höherer Verzugsschaden\n" +
                "    nachweisbar entstanden ist, ist Geras24 berechtigt, diesen geltend zu\n" +
                "    machen.\n" +
                "</p>\n" +
                "<p>\n" +
                "    <strong> </strong>\n" +
                "</p>\n" +
                "<p>\n" +
                "    <strong> </strong>\n" +
                "</p>\n" +
                "<p>\n" +
                "    <strong>Zurückbehaltungsrecht</strong>\n" +
                "</p>\n" +
                "<p>\n" +
                "    Zur Ausübung eines Zurückbehaltungsrechts ist der Kunde nur insoweit\n" +
                "    befugt, als sein Gegenanspruch auf demselben Vertragsverhältnis beruht.\n" +
                "</p>\n" +
                "<p>\n" +
                "    <strong>Lieferung</strong>\n" +
                "</p>\n" +
                "<p>\n" +
                "    (1) Die Lieferung erfolgt an die vom Kunden angegebene Lieferanschrift,\n" +
                "    innerhalb von\n" +
                "</p>\n" +
                "<p>\n" +
                "    - Deutschland, Österreich und der Schweiz\n" +
                "    <br/><br>\n" +
                "    (2) Macht höhere Gewalt (Naturkatastrophen, Krieg, Bürgerkrieg,\n" +
                "    Terroranschlag) die Lieferung oder eine sonstige Leistung dauerhaft\n" +
                "    unmöglich, ist eine Leistungspflicht von Geras24\n" +
                "    ausgeschlossen. Bereits gezahlte Beträge werden von Geras24\n" +
                "    unverzüglich erstattet.\n" +
                "</p>\n" +
                "<p>\n" +
                "    (3) Geras24 kann außerdem die Leistung verweigern, soweit diese einen\n" +
                "    Aufwand erfordert, der unter Beachtung des Inhalts des Kaufvertrages und\n" +
                "    der Gebote von Treu und Glauben in einem groben Missverhältnis zu dem\n" +
                "    Interesse des Kunden an der Erfüllung des Kaufvertrages steht.\n" +
                "</p>\n" +
                "<p>\n" +
                "    <strong>Mängelrechte</strong>\n" +
                "</p>\n" +
                "<p>\n" +
                "    (1) Ein bereits bei der Lieferung mangelhaftes Produkt\n" +
                "    (Gewährleistungsfall) wird Geras24 nach\n" +
                "    Wahl des Kunden auf Kosten von Geras24\n" +
                "    durch ein mangelfreies ersetzen oder fachgerecht reparieren lassen\n" +
                "    (Nacherfüllung). Der Kunde wird darauf hingewiesen, dass kein\n" +
                "    Gewährleistungsfall vorliegt, wenn das Produkt bei Gefahrübergang die\n" +
                "    vereinbarte Beschaffenheit hatte. Ein Gewährleistungsfall liegt\n" +
                "    insbesondere in folgenden Fällen nicht vor:\n" +
                "</p>\n" +
                "<p>\n" +
                "    (7) Der Kunde kann nach seiner Wahl vom Vertrag zurücktreten oder den\n" +
                "    Kaufpreis mindern, wenn die Reparatur oder Ersatzlieferung innerhalb einer\n" +
                "    angemessenen Frist nicht zu einem vertragsgerechten Zustand des Produktes\n" +
                "    geführt hat.\n" +
                "</p>\n" +
                "<p>\n" +
                "    (8) Darüber hinaus können auch Ansprüche gegen den Hersteller im Rahmen\n" +
                "    einer von diesem eingeräumten\n" +
                "</p>\n" +
                "<p>\n" +
                "<strong>Kündigung</strong>\n" +
                "</p>\n" +
                "<p>\n" +
                "Der Abonnementvertrag verlängert sich nach Ablauf der Mindestvertragslaufzeit von einen Jahr auf unbestimmte Zeit und ist mit einer Frist von einem Monat in Textform (per Briefpost, Fax oder E-Mail) gegenüber der Geras24 GmbH kündbar.\n<br>" +
                "Direkt Link: <a href='mailto:kuendigung@geras24.de' class='dark'>kuendigung@geras24.de</a>" +
                "</p>\n" +
                "<p>\n" +
                "<strong>Widerrufsrecht</strong>\n" +
                "</p>\n" +
                "<p>\n" +
                "Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.\n" +
                "Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsabschlusses.\n<br>" +
                "Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (Geras24 GmbH, Am Rahmer Bach 50 b, 47269 Duisburg, 0211 158 97728, info@geras24.de) mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief oder eine E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren.\n" +
                "Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.\n" +
                "</p>\n" +
                "<p>\n" +
                "<strong>Folgen des Widerrufs</strong>\n" +
                "</p>\n" +
                "<p>\n" +
                "Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet." +
                "</p>\n" +
                "<p>\n" +
                "    <strong>Haftung</strong>\n" +
                "</p>\n" +
                "<p>\n" +
                "    (1) Bei leichter Fahrlässigkeit haftet Geras24\n" +
                "    nur bei der Verletzung vertragswesentlicher Pflichten und beschränkt\n" +
                "    auf den vorhersehbaren Schaden. Diese Beschränkung gilt nicht bei der\n" +
                "    Verletzung von Leben, Körper und Gesundheit. Für sonstige leicht fahrlässig\n" +
                "    durch einen Mangel des Kaufgegenstandes verursachte Schäden haftet Geras24\n" +
                "    nicht.\n" +
                "</p>\n" +
                "<p>\n" +
                "    (2) Unabhängig von einem Verschulden von Geras24\n" +
                "    bleibt eine Haftung von Geras24 bei arglistigem\n" +
                "    Verschweigen des Mangels oder aus der Übernahme einer Garantie unberührt.\n" +
                "    Die Herstellergarantie ist eine Garantie des Herstellers und stellt keine\n" +
                "    Übernahme einer Garantie durch Geras24 dar.\n" +
                "</p>\n" +
                "<p>\n" +
                "    (3) Geras24 ist auch für die während ihres\n" +
                "    Verzugs durch Zufall eintretende Unmöglichkeit der Lieferung\n" +
                "    verantwortlich, es sei denn, dass der Schaden auch bei rechtzeitiger\n" +
                "    Lieferung eingetreten wäre.\n" +
                "</p>\n" +
                "<p>\n" +
                "    (4) Ausgeschlossen ist die persönliche Haftung der gesetzlichen Vertreter,\n" +
                "    Erfüllungsgehilfen und Betriebsangehörigen von Geras24\n" +
                "    für von ihnen durch leichte Fahrlässigkeit verursachte Schäden.\n" +
                "</p>\n" +
                "<p>\n" +
                "    <strong>Anwendbares Recht</strong>\n" +
                "</p>\n" +
                "<p>\n" +
                "    Der zwischen Ihnen und Geras24 GmbH abgeschlossene Vertrag unterliegt\n" +
                "    ausschließlich dem Recht der Bundesrepublik Deutschland unter\n" +
                "    ausdrücklichem Ausschluss des UN-Kaufrechts. Unberührt davon bleiben die\n" +
                "    zwingenden Bestimmungen des Staates, in dem Sie Ihren gewöhnlichen\n" +
                "    Aufenthalt haben.\n" +
                "</p>\n" +
                "<p>\n" +
                "    <strong>Gerichtsstand</strong>\n" +
                "</p>\n" +
                "<p>\n" +
                "    Sofern Sie entgegen Ihren Angaben bei der Bestellung keinen Wohnsitz in der\n" +
                "    Bundesrepublik Deutschland haben oder nach Vertragsabschluss Ihren Wohnsitz\n" +
                "    ins Ausland verlegen oder Ihr Wohnsitz zum Zeitpunkt der Klageerhebung\n" +
                "    nicht bekannt ist, ist Gerichtsstand für alle Streitigkeiten aus und im\n" +
                "    Zusammenhang mit dem Vertragsverhältnis ist Duisburg Nordrhein Westfalen.\n" +
                "</p>\n" +
                "<p>\n" +
                "    <strong>Streitbeilegung</strong>\n" +
                "</p>\n" +
                "<p>\n" +
                "    Allgemeine Informationspflichten zur alternativen Streitbeilegung nach Art.\n" +
                "    14 Abs. 1 ODR-VO und § 36 VSBG (Verbraucherstreitbeilegungsgesetz):\n" +
                "</p>\n" +
                "<p>\n" +
                "    Die europäische Kommission stellt eine Plattform zur Online-Streitbelegung\n" +
                "    (OS) zur Verfügung, die Sie unter dieser Adresse finden:\n" +
                "    <a href=\"http://ec.europa.eu/consumers/odr/\" class='dark'>\n" +
                "        http://ec.europa.eu/consumers/odr/\n" +
                "    </a>\n" +
                "    . Zur Teilnahme an einem Streitbeilegungsverfahren vor einer\n" +
                "    Verbraucherschlichtungsstelle sind wir nicht verpflichtet und auch nicht\n" +
                "    bereit.\n" +
                "</p>\n" +
                "<p>\n" +
                "    <strong>Schlussbestimmungen</strong>\n" +
                "</p>\n" +
                "<p>\n" +
                "    (1) Sollten einzelne Bestimmungen dieses Vertrages ganz oder teilweise\n" +
                "    unwirksam oder nichtig sein oder werden, so wird dadurch die Wirksamkeit\n" +
                "    des Vertrages im Übrigen nicht berührt, insoweit ein Vertragspartner\n" +
                "    hierdurch nicht unangemessen benachteiligt wird.\n" +
                "</p>\n" +
                "<p>\n" +
                "    (2) Änderungen oder Ergänzungen dieses Vertrages bedürfen der Schriftform.\n" +
                "</p>",
          showLogin: false,
          showRegister: false,
        }
    },
    created() {
      this.setMetaTags()
    },
}
</script>