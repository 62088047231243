<template>
    <section id="faq">
      <Header @open-login-modal="showLogin = true" @open-register-modal="showRegister = true" />

      <v-container>
        <v-row class="mt-10">
            <v-col cols="12">
              <h1>News und Presse</h1>
            </v-col>
        </v-row>
        <v-row class="mb-10">
          <v-col>
            <iframe style="width: 100%; height: 870px;" src="https://www.lifepr.de/presscorner/firma/geras24-gmbh?language=all" scrolling="yes" frameborder="0"></iframe>
<!--            <br /><br />Powered by <a href="https://www.lifepr.de" target="_blank">https://www.lifepr.de</a>-->
<!--            <v-card-->
<!--                v-for="(item,i) in news"-->
<!--                :key="i"-->
<!--                class="mx-auto mt-5"-->
<!--            >-->
<!--              <v-card-text>-->
<!--                <div>{{ item.date }}</div>-->
<!--                <p class="text-h5 primary-text">-->
<!--                  {{ item.title }}-->
<!--                </p>-->
<!--                <p v-if="item.subtitle" :class="{ 'mb-0' : !item.content }"><b>{{ item.subtitle }}</b></p>-->
<!--                <div class="text&#45;&#45;primary" v-if="item.content" v-html="item.content"></div>-->
<!--              </v-card-text>-->
<!--              <v-card-actions>-->
<!--                <v-btn-->
<!--                    v-if="item.link"-->
<!--                    text-->
<!--                    color="primary"-->
<!--                    :href="item.link"-->
<!--                    target="_blank"-->
<!--                >-->
<!--                  {{ item.linkText }}-->
<!--                </v-btn>-->
<!--              </v-card-actions>-->
<!--            </v-card>-->
          </v-col>
        </v-row>
      </v-container>

      <Footer></Footer>

      <login-dialog @close-login-modal="showLogin = false" @open-register-modal="showRegister = true" v-model="showLogin"></login-dialog>
      <register-dialog @close-register-modal="showRegister = false" @open-login-modal="showLogin = true" v-model="showRegister"></register-dialog>
    </section>
</template>

<script>

import Header from "./Header.vue";
import Footer from "../../components/public/Footer.vue";
import LoginDialog from "../../widgets/LoginDialog.vue";
import RegisterDialog from "../../widgets/RegisterDialog.vue";

export default {
    components: {
      Header,
      Footer,
      LoginDialog,
      RegisterDialog
    }, 
    data() {
        return {
          // news: [
          //   {
          //     date: '04. August 2022',
          //     title: 'Geras auf rp-online.de',
          //     subtitle: 'Online',
          //     content: '',
          //     link: 'https://rp-online.de/nrw/staedte/duesseldorf/stadtgespraech/duesseldorf-duesseldorfer-gruendet-digitalen-ort-der-erinnerung_aid-74599883?utm_medium=Social&utm_source=Facebook&fbclid=IwAR1z8FdZ55l2BJY2K3uxe7urC4iklzivnuIYYjiivNFEi_0mfF1q5wj74IQ&fs=e&s=cl#Echobox=1659960788',
          //     linkText: 'Zum Artikel'
          //   },
          //   {
          //     date: '12. Mai 2022',
          //     title: 'Geras auf papaseite.de',
          //     subtitle: 'Online',
          //     content: '',
          //     link: 'https://papaseite.de/lebensabsicherung-mit-geras24/',
          //     linkText: 'Zum Artikel'
          //   },
          //   {
          //     date: '01. Mai 2022',
          //     title: 'Geras im Stadtteil-Magazin',
          //     subtitle: 'Print',
          //     content: '',
          //     link: '/assets/file/press/GerasStadtteil.pdf',
          //     linkText: 'Artikel herunterladen'
          //   }
          // ],
          showLogin: false,
          showRegister: false,
        }
    },
    created() {
      this.setMetaTags()
    },
    mounted() {

    },
    methods: {

    }
}
</script>