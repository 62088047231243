<template>
    <section>
        <!-- Navigation Icons $ Logo -->
        <div v-if="false" class="header-bar">
            <v-row no-gutters>
                <!-- Social Medias -->
                <v-col align="center" class="white--text mx-auto" justify="center">
                    <v-row align="center" class="white--text mx-auto" justify="center">
                        <v-container class="spacing-playground pa-4 mt-6">
                            <v-row class="white--text mx-auto align-middle pt-3 pl-15">
                                <a :href="$store.getters.settings.socials ? $store.getters.settings.socials.fb : '#'" class="mr-15">
                                    <v-icon>mdi-facebook</v-icon>
                                </a>
                                <a :href="$store.getters.settings.socials ? $store.getters.settings.socials.ins : '#'" class="mr-15">
                                    <v-icon>mdi-instagram</v-icon>
                                </a>
                                <a :href="'javascript:void(0)'" class="mr-15">
                                    <v-img src="/assets/img/icons/up.svg" max-width="50px"></v-img>
                                </a>
                            </v-row>
                        </v-container>
                    </v-row>
                </v-col>
                <!-- Logo -->
                <v-col>
                    <v-row>
                        <v-container class="spacing-playground pa-6">
                            <v-row align="center" class="white--text mx-auto mt-6" justify="center">
                                <router-link :to="{name: 'home'}"><v-img src="/assets/img/logo.png" :max-height="'85px'" :max-width="'200px'"></v-img></router-link>
                            </v-row>
                        </v-container>
                    </v-row>
                </v-col>
                <!-- Cart & Menu -->
                <v-col class="white--text" dir="rtl">
                    <v-row class="white--text">
                        <v-container class="spacing-playground pa-4 mt-6">
                            <v-row class="white--text pt-3 pr-15 text-right">
                                <!-- Menu -->
                                <a href="javascript:void(0);" @click.prevent="menu = !menu" class="mr-15 pt-1">
                                    <v-icon>mdi-menu</v-icon>
                                </a>
                                 <router-link v-if="!$store.getters.isLoggedIn" :to="{name:'login'}" tag="button" class="mr-15 btn-light">{{ $t('login') }}</router-link>
                                 <router-link v-else :to="{name:'dashboard'}" tag="button" class="mr-15 btn-light">{{ this.$store.getters.user.user.name }}</router-link>
                            </v-row>
                        </v-container>
                    </v-row>
                </v-col>
            </v-row>
        </div>
        <div v-if="['login', 'register'].includes($route.name)" class="auth-header">
            <v-row no-gutters>
                <v-col>
                    <v-row>
                        <v-container class="pa-4 mt-6">
                            <v-row class="align-middle pt-3 pl-15 pa-5" :align="$helpers.isMobile() ? 'right' : 'left'" :justify="$helpers.isMobile() ? 'end' : 'start'">
                                
                                <router-link :to="{name: 'home'}" class="mr-15">
                                    <v-btn
                                        color="primary"
                                        class="ma-2 white--text"
                                        fab
                                        @click="$router.push({name: 'home'})"
                                    >
                                        <v-icon>
                                            mdi-home
                                        </v-icon>
                                    </v-btn>
                                </router-link>
                            </v-row>
                        </v-container>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </section>
</template>

<script>

export default {
}
</script>