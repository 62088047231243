<template>
  <section id="promo-codes">
    <Header @open-login-modal="showLogin = true" @open-register-modal="showRegister = true" />
    <v-container>
      <v-row class="mt-10">
        <v-col>
          <h1>Geras24 Promo Codes</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            cols="12"
            md="7"
            justify="center"
        >
          <div class="main-font">
            <h3 class="pb-5">Kostenloses Geras24 Probe-Abo</h3>
            <span class="font-weight-bold pr-5">Code: </span>
            <v-chip color="primary-text" text-color="white" class="font-weight-bold">
              <span class="inv-text">qfgmkVws57FgjLG7Mal-PRM</span>
              <v-divider
                  class="mx-4"
                  vertical
                  light
              ></v-divider>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span @click="copyCode('qfgmkVws57FgjLG7Mal-PRM')" style="cursor: pointer;" v-bind="attrs" v-on="on">Kopieren</span>
                </template>
                <span>Code kopieren</span>
              </v-tooltip>
            </v-chip>
            <div class="pt-5">
              Mit diesem Code kannst Du für ein ganzes Jahr lang kostenlos die Funktionen des Geras24-Abonnements "Sorglos" nutzen.<br><br>
              Registriere Dich dazu einfach kostenlos, gehe nach dem Du Deine E-Mail-Adresse bestätigt und Dich eingeloggt hast auf Dein Profil und klicke unter "Abonnements" auf den Button "Ich habe bereits einen Code".<br>
              Dann kannst Du den kopierten Code eingeben und Dein kostenloses Probe-Abo aktivieren.
            </div>
          </div>
          <div class="pt-10">
            <v-btn
                elevation="5"
                class="btn-support"
                min-width="0"
                text
                @click="showRegister = true"
            >
              <span>Kostenlos registrieren</span>
            </v-btn>
          </div>
        </v-col>
        <v-col
            cols="12"
            md="5"
            align="center"
            justify="center"
        >
          <v-img src="/assets/img/price_model/price_model_2.jpg" width="350" ></v-img>
        </v-col>
      </v-row>
      <v-row class="py-10">
        <v-col
            cols="12"
            md="7"
            justify="center"
        >
          <div class="main-font">
            <h3 class="pb-5">Kostenloser Download Geras24 Notfallordner Digital</h3>
            <span class="font-weight-bold pr-5">Code: </span>
            <v-chip color="primary-text" text-color="white" class="font-weight-bold">
              <span class="inv-text">vzs7ds87</span>
              <v-divider
                  class="mx-4"
                  vertical
                  light
              ></v-divider>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span @click="copyCode('vzs7ds87')" style="cursor: pointer;" v-bind="attrs" v-on="on">Kopieren</span>
                </template>
                <span>Code kopieren</span>
              </v-tooltip>
            </v-chip>
            <div class="pt-5">
              Mit diesem Gutscheincode kannst Du in unserem Shop den Geras24 Notfallordner Digital kostenlos herunterladen.<br><br>
              Klicke dazu unten auf den Button "Notfallordner kostenlos herunterladen" und du wirst zum Shop weitergeleitet.
              Dort kannst du den Notfallordner Digital in den Warenkorb legen und während des Checkouts den kopierten Gutscheincode einlösen.
              Nach der kostenlosen Bestellung kannst du ihn dann herunterladen.
            </div>
          </div>
          <div class="pt-10">
            <v-btn
                elevation="5"
                class="btn-support"
                min-width="0"
                text
                @click="redirectToShop"
            >
              <span>Notfallordner kostenlos herunterladen</span>
            </v-btn>
          </div>
        </v-col>
        <v-col
            cols="12"
            md="5"
            align="center"
            justify="center"
        >
          <v-img src="/assets/img/notfallordner_digital.png" width="350" ></v-img>
        </v-col>
      </v-row>
    </v-container>

    <Footer></Footer>

    <login-dialog @close-login-modal="showLogin = false" @open-register-modal="showRegister = true" v-model="showLogin"></login-dialog>
    <register-dialog @close-register-modal="showRegister = false" @open-login-modal="showLogin = true" v-model="showRegister"></register-dialog>

  </section>
</template>

<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import LoginDialog from "../../widgets/LoginDialog.vue";
import RegisterDialog from "../../widgets/RegisterDialog.vue";

export default {
    components:{
      Header,
      Footer,
      LoginDialog,
      RegisterDialog
    }, 
    data(){
        return {
          showLogin: false,
          showRegister: false
        }
    },
    created() {
      this.setMetaTags()
    },
    mounted() {
    },
    methods: {
      redirectToShop() {
        window.open('https://shop.geras24.de/produkt/notfallordner-digital/', '_blank');
      },
      async copyCode(text) {
        await navigator.clipboard.writeText(text);
      }
    }
}
</script>