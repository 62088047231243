<template>
    <section id="prices">
      <Header @open-login-modal="showLogin = true" @open-register-modal="showRegister = true" />

      <v-container>
        <v-row class="mt-10">
            <v-col cols="12">
              <h1>Geras24 – Unsere Mitgliedsbeiträge</h1>
            </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="main-font">
            Im Folgenden findest du unsere verschiedenen Modelle, um Mitglied zu werden. Sichere dir jetzt ein Stück Sicherheit für dein Leben. Die Leistungen sind aufgeführt. Werde jetzt Mitglied.
          </v-col>
        </v-row>

        <plans @open-register-modal="openRegisterModal"></plans>

      </v-container>

      <Footer></Footer>

      <login-dialog @close-login-modal="showLogin = false" @open-register-modal="showRegister = true" v-model="showLogin"></login-dialog>
      <register-dialog :price-model="priceModel" @close-register-modal="showRegister = false" @open-login-modal="showLogin = true" @open-popup-blocked-modal="showPopupBlocked = true" v-model="showRegister"></register-dialog>
      <popup-blocked-dialog :price-model="priceModel" v-model="showPopupBlocked"></popup-blocked-dialog>
    </section>
</template>

<script>

import Header from "./Header.vue";
import Footer from "../../components/public/Footer.vue";
import LoginDialog from "../../widgets/LoginDialog.vue";
import RegisterDialog from "../../widgets/RegisterDialog.vue";
import Plans from "../../widgets/Plans.vue";
import PopupBlockedDialog from "../../widgets/PopupBlockedDialog";

export default {
    components: {
      PopupBlockedDialog,
      Header,
      Footer,
      LoginDialog,
      RegisterDialog,
      Plans
    }, 
    data() {
        return {
          showLogin: false,
          showRegister: false,
          showPopupBlocked: false,
          priceModel: null
        }
    },
    created() {
      this.setMetaTags()
    },
    mounted() {
      // this.getFeatures()
    },
    methods: {
      openRegisterModal(model) {
        this.priceModel = model
        this.showRegister = true
      }
    }
}
</script>