<template>
    <section>
        
        <ProfileAppBar />

        <transition name="hslide" mode="out-in">
            <router-view />
        </transition>
    </section>
</template>

<script>
export default {
    components: {
        ProfileAppBar: () => import("./AppBar"),
    },
}
</script>