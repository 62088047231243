<template>
    <v-container
        fluid
        tag="section"
    >
        <v-row>
            <v-col cols="auto">
                <p class="text-h5 main-font-bold">{{ $t('upload_new_file') }}</p>
            </v-col>
            <v-col align="right" justify="end">
                <router-link :to="{ name: 'tresorIndex' }">
                    <v-btn color="white" fab>
                        <span class="primary--text"><v-icon>mdi-arrow-right</v-icon></span>
                    </v-btn>
                </router-link>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12">
                <v-form v-if="!loading">
                    <v-container class="py-5 pt-10">
                        <v-row>
                            <!-- File Input -->
                            <v-col cols="12">
                                <v-file-input
                                    v-model="file"
                                    color="primary darken-1"
                                    :label="$t('new_file')"
                                    :placeholder="$t('select_file')"
                                    prepend-icon="mdi-paperclip"
                                    outlined
                                    :disabled="loading"
                                    :show-size="1024"
                                    :error="!file && fileError"
                                >
                                    <template v-slot:selection="{ index, text }">
                                        <v-chip
                                            v-if="index < 2"
                                            color="primary darken-1"
                                            dark
                                            label
                                            small
                                        >
                                            {{ text }}
                                        </v-chip>

                                        <span
                                            v-else-if="index === 2"
                                            class="text-overline grey--text text--darken-3 mx-2"
                                        >
                                            +{{ files.length - 2 }} {{ $t('file') }}(s)
                                        </span>
                                    </template>
                                </v-file-input>
                            </v-col>

                            <!-- File Desc -->
                            <v-col cols="12">
                                <v-text-field
                                    outlined
                                    :label="$t('file_desc')"
                                    prepend-icon="mdi-card-text-outline"
                                    maxlength="200"
                                    counter
                                    :disabled="loading"
                                    v-model="desc"
                                    :error="!desc && descError"
                                ></v-text-field>
                            </v-col>

                            <!-- Buttons -->
                            <v-col
                                cols="12"
                                justify="right"
                                align="right"
                            >
                                <v-btn
                                    large
                                    :loading="loading"
                                    color="blue-grey"
                                    class="ma-2 white--text px-10"
                                    @click="encryptProcess"
                                >
                                    {{ $t('upload') }}
                                    <v-icon
                                        right
                                        dark
                                    >
                                        mdi-cloud-upload
                                    </v-icon>
                                </v-btn>
                            </v-col>

                            <transition
                                name="slide"
                                mode="out-in"
                            >
                                <v-col
                                    v-if="loading"
                                    cols="12"
                                    align="center"
                                    justify="center"
                                >
                                    <v-progress-linear
                                        :value="percent"
                                        height="25"
                                        striped
                                    >
                                        <strong>{{ Math.ceil(percent) }}%</strong>
                                    </v-progress-linear>
                                    <v-row class="mt-3">
                                        <v-col
                                            align="center"
                                            justify="center"
                                        >
                                            <p>
                                                {{ $t('uploading') }} (<span class="error--text">{{ $t('dont_close_page') }}</span>)
                                            </p>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </transition>
                        </v-row>
                    </v-container>
                </v-form>
                <v-row v-else class="mb-5 mt-5" :key="0">
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-row>
            </v-col>
        </v-row>

        <!-- init keys Dialog -->
        <v-dialog
            v-model="initKeys"
            persistent
            max-width="600"
        >
            <v-card>
                <v-card-title class="text-h5">
                    {{ $t('no_keys') }}
                </v-card-title>

                <v-card-text>
                    {{ $t('no_keys_subtitle') }}
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="error darken-1"
                        text
                        @click="$router.push({ name: 'profileInfo' })"
                    >
                        {{ $t('later') }}
                    </v-btn>

                    <v-btn
                        color="primary darken-1"
                        text
                        @click="$router.push({ name: 'keys' })"
                    >
                        {{ $t('create_now') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Otp Dialog -->
        <v-dialog
            v-model="otpDialog"
            persistent
            max-width="600"
        >
            <v-card>
                <v-card-title class="text-h5">
                    {{ $t('otp_title') }}
                </v-card-title>

                <v-card-text align="center" class="mt-5">
                    <v-text-field 
                        class="pa-0"
                        :placeholder="$t('otp_code')"
                        v-model="otp" 
                        :loading="otpLoading"
                        :disabled="otpLoading"
                        outlined
                    ></v-text-field>
                </v-card-text>

                <v-card-text align="center" justify="center">
                    <v-btn fab color="primary" class="ma-2" @click="loginOtp" :disabled="otpLoading || !otp">
                        <v-icon>mdi-lock</v-icon>
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import Vue from "vue";
import {Decrypt,Encrypt} from '@/plugins/utils.js'

export default {
    data() {
        return {
            loading: true,
            percent: 0,

            desc: null,
            name: null,
            nameError: false,
            descError: false,
            fileError: false,
            pemError: false,
            file: null,
            prvKeyFile: null,

            fileEncoded: null,
            encryptedFile: null,
            decryptedFile: null,
            encryptedKeys: null,
            decryptedKeys: null,
            keys: null,
            prvKey: null,
            prvKeyText: null,
            pubKeyText: null,
            pubKey: this.$store.getters.user.user.public_key,
            generateKeysDialg: false,

            key: null,
            encryptedKey: null,
            iv: null,
            encryptedIv: null,

            pubKeyPEM: null,

            initKeys: false,
            otpLoading: false,
            otpDialog: false,
            otp: null
        };
    },
    mounted(){
        if (!this.$store.getters.tresorToken) this.otpDialog = true
        else this.getMe()
    },
    methods: {
        getMe(){
            this.loading = true
            this.$store.dispatch('getMe')
            .then(resp => {
                this.pubKeyPEM = resp.public_key
                if (resp.public_key) this.getPrvKey()
                else {
                    this.initKeys = true
                    this.initSecurity()
                }
            })
        },
        uploadFile(){
                var formData = new FormData();
                formData.append('file', new File([new Blob([this.encryptedFile])], this.file.name));
                formData.append('key', this.encryptedKeys);
                formData.append('desc', this.desc);
                
                this.$store.dispatch('uploadTresorFile', formData )
                .then(resp => {
                    Vue.$toast.success(this.$t('file_upload_success'))
                    this.$router.push({name: 'tresorIndex'})
                })
                .catch(err => {
                    this.loading = false
                    this.$helpers.showError(err)
                })
        },
        onProgress(percent) {
            this.percent = percent
        },
        encryptProcess() {
            if (!this.file) {
                this.fileError = true
                return
            }
            if (!this.desc) {
                this.descError = true
                return
            }
            if (!this.prvKeyText) {
                this.pemError = true
                return
            }

            this.loading = true
            this.encodingFile()
        },

        encryptFile(){
            this.key = this.$helpers.generatePassword(64)
            this.iv = this.$helpers.generatePassword(64)
            this.keys = JSON.stringify({
                key: this.key,
                iv: this.iv
            })

            this.encryptedFile = Encrypt(this.fileEncoded, this.key, this.iv)
            this.decryptFile()
            setTimeout(() => {
                this.encryptKeys()
            }, 2000);
        },

        decryptFile(){
            setTimeout(() => {
                if (this.encryptedFile) this.decryptedFile = Decrypt(this.encryptedFile, this.key, this.iv)
                else this.decryptFile()
            }, 2000);
        },

        encodingFile(){
            var reader = new FileReader()
            reader.readAsDataURL(this.file)
            reader.onload = ()=> {
                this.fileEncoded = reader.result;
                this.encryptFile()
            };
        },

        encryptKeys(){
            this.encryptedKey = this.encryptedData(this.pubKeyPEM, this.key);
            this.encryptedIv = this.encryptedData(this.pubKeyPEM, this.iv);
            this.encryptedKeys = JSON.stringify({
                key: this.encryptedKey,
                iv: this.encryptedIv
            })
            this.uploadFile()
            this.decryptKeys()
        },

        decryptKeys(){
            let key = this.decryptData(this.prvKeyText, this.encryptedKey);
            let iv = this.decryptData(this.prvKeyText, this.encryptedIv);
            this.decryptedKeys = JSON.stringify({
                key: key,
                iv: iv
            })
        },

        //  encryption
        encryptedData(publicKey, data) {
            // New JSEncrypt object
            let encryptor = new this.$rsa();
            // Setting public key
            encryptor.setPublicKey(publicKey);
            // Encrypted data
            return encryptor.encrypt(data);
        },
        // Decrypt
        decryptData(privateKey,data){
            // New JSEncrypt object
            let decrypt= new this.$rsa();
            // Set private key
            decrypt.setPrivateKey(privateKey);
            // Declassified data
            return decrypt.decrypt(data);
        },

        prvKeyValidation(file){
            if (this.prvKeyFile){
                var fileReader = new FileReader();
                var that = this
                fileReader.onload = function(fileLoadedEvent){
                    var textFromFileLoaded = fileLoadedEvent.target.result;
                    let prvKeyText = textFromFileLoaded

                    // Check Validation
                    let result = that.$helpers.prvKeyValidator(that.pubKeyPEM, prvKeyText)
                    if (!result) {
                        that.prvKeyFile = null
                        that.pemError = true
                        that.$helpers.showMessage('error', that.$t('prv_pem_invalid'))
                    }
                };

                fileReader.readAsText(that.prvKeyFile, "UTF-8");
            }
        },

        initSecurity(){
            this.generateKey()
            .then(resp => {
                this.$store.dispatch('setPubKey', {
                    public_key: this.pubKeyPEM
                })
                .then(resp => {
                    this.$store.dispatch('setPrvKey', {
                        private_key: this.prvKeyPEM
                    })
                    .then(resp => {
                        this.initKeys = false
                        
                    })
                })
            }) 
        },

        getPrvKey(){
            this.$store.dispatch('getKeys')
            .then(resp => {
                this.prvKeyText = resp.private_key
                this.loading = false
            })
            .catch (err => {
                if (err.response.status === 402) this.paymentRequireDialog = true
                else this.$helpers.showError(err)
            })
        },

        loginOtp(){
            if (!this.otp){
                Vue.$toast.error(this.$t('enter_otp'))
                return
            }
        
            this.otpLoading = true
            this.$store.dispatch('tresorOtp', {
                otp: this.otp,
            })
            .then(resp => {
                this.initKeys = true
                this.otpDialog = false
                this.getMe()
            })
            .catch(err => {
                this.$helpers.showError(err)
                this.otpLoading= false
            })
        },

    }
};
</script>