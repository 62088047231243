<template>
  <v-container fluid tag="section" class="pa-8">
    <!-- Head Buttons -->
    <v-row>
      <v-col>
        <v-row>
          <v-col cols="12" class="mb-3">
            <p class="text-h5 main-font-bold main-title-color">{{ $t('support') }}</p>
          </v-col>
        </v-row>
      </v-col>
      <v-col align="right" justify="end">
        <router-link :to="{ name: 'newSupport' }">
          <v-btn large color="white">
            <span class="primary--text"
              ><v-icon class="mr-2">mdi-plus</v-icon> {{ $t('new_ticket') }}</span
            >
          </v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="tickets"
          :search="search"
          :loading="loading"
          :loading-text="$t('loading')"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
              'items-per-page-text': $t('row_per_page')
          }"
        >
          <template v-slot:item.tracking_code="{ item }">
            <router-link
              :to="{ name: 'showSupport', params: { id: item.id } }"
              class="primary--text"
              >{{ item.tracking_code }}</router-link
            >
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip :color="getColor(item.status)" dark>
              {{ getStatus(item.status) }}
            </v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn icon fab color="primary">
              <v-icon
                @click="$router.push({ name: 'showSupport', params: { id: item.id } })"
              >
                mdi-eye
              </v-icon>
            </v-btn>
          </template>
          <template slot="no-data">
            <v-alert class="grey--text">
              {{ $t('empty_data') }}
            </v-alert>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data(){ 
    return {
      search: "",
      headers: [
        { text: "#", align: "start", value: "tracking_code" },
        { text: this.$t("department"), value: "department" },
        { text: this.$t("subject"), value: "subject" },
        { text: this.$t("status"), value: "status" },
        { text: this.$t("last_update"), value: "updated_at" },
        { text: this.$t("actions"), value: "actions", sortable: false },
      ],
      tickets: [],
      loading: true,
    }
  },

  created() {
    this.getTickets();
  },

  methods: {
    getTickets() {
      this.loading = true;
      this.$store.dispatch("getTickets").then((resp) => {
        let data = [];
        for (let index = 0; index < resp.length; index++) {
          const ticket = resp[index];
          data.push({
            id: ticket.id,
            tracking_code: ticket.tracking_code,
            department: ticket.category,
            subject: ticket.subject,
            status: ticket.status,
            updated_at: ticket.updated_at,
          });
        }
        this.tickets = data;
        this.loading = false;
      });
    },

    getColor(status) {
      switch (status) {
        case "pending":
          return "warning";
        case "open":
          return "success";
        case "closed":
          return "primary";
        default:
          return "dark";
      }
    },
    getStatus(status) {
      switch (status) {
        case "pending":
          return this.$t('pending');
        case "open":
          return this.$t('open');
        case "closed":
          return this.$t('closed');
        default:
          return this.$t('error');
      }
    },
  },
};
</script>