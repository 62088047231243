<template>
    <v-container
        fluid
        tag="section"
    >
         <v-row>
            <v-col cols="12">
                <p class="text-h5 main-font-bold">{{ $t('upload_new_file') }}</p>
                <p class="text-subtitle-1">{{ $t('upload_new_document_subtitle') }}</p>
            </v-col>
        </v-row>

        <v-row justify="center">
            <v-col cols="12">
                <v-form>
                    <v-container class="py-5 pt-10">
                        <v-row>
                            <transition
                                name="slide"
                                mode="out-in"
                            >
                                <v-col
                                    v-if="loading"
                                    cols="12"
                                    align="center"
                                    justify="center"
                                >
                                    <v-progress-linear
                                        :value="percent"
                                        height="25"
                                        striped
                                    >
                                        <strong>{{ Math.ceil(percent) }}%</strong>
                                    </v-progress-linear>
                                    <v-row class="mt-3">
                                        <v-col
                                            align="center"
                                            justify="center"
                                        >
                                            <p>
                                                {{ $t('uploading') }} (<span class="error--text">{{ $t('dont_close_page') }}</span>)
                                            </p>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </transition>

                            <v-col cols="12">
                                <v-file-input
                                    v-model="file"
                                    color="primary"
                                    counter
                                    :label="$t('new_file')"
                                    :placeholder="$t('select_file')"
                                    prepend-icon="mdi-paperclip"
                                    outlined
                                    :disabled="loading"
                                    :show-size="1024"
                                >
                                    <template v-slot:selection="{ index, text }">
                                        <v-chip
                                            v-if="index < 2"
                                            color="primary"
                                            dark
                                            label
                                            small
                                        >
                                            {{ text }}
                                        </v-chip>

                                        <span
                                            v-else-if="index === 2"
                                            class="text-overline grey--text text--darken-3 mx-2"
                                        >
                                            +{{ files.length - 2 }} {{ $t('file') }}(s)
                                        </span>
                                    </template>
                                </v-file-input>
                            </v-col>

                            <v-col cols="12">
                                <v-text-field
                                    outlined
                                    :label="$t('file_desc')"
                                    prepend-icon="mdi-card-text-outline"
                                    maxlength="200"
                                    counter
                                    :disabled="loading"
                                    v-model="desc"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="2"
                                justify="left"
                                align="left"
                            >
                                <v-btn
                                    color="grey"
                                    text
                                    fab
                                    @click="$router.push({name: 'mediaIndex'})"
                                    x-large
                                >
                                    <v-icon>
                                        mdi-arrow-left
                                    </v-icon>
                                </v-btn>
                            </v-col>
                            <v-col
                                cols="10"
                                justify="right"
                                align="right"
                            >
                                <v-btn
                                    :loading="loading"
                                    :disabled="loading || (!file || !desc)"
                                    color="primary"
                                    class="ma-2 white--text px-16"
                                    @click="uploadFile"
                                    x-large
                                >
                                    {{ $t('upload') }}
                                    <v-icon
                                        right
                                        dark
                                    >
                                        mdi-cloud-upload
                                    </v-icon>
                                </v-btn>
                            </v-col>                            
                        </v-row>
                    </v-container>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from "axios";
import Vue from "vue";

export default {
    data() {
        return {
            loading: false,
            percent: 0,
            desc: null,
            file: null,
        };
    },

    methods: {
        onProgress(percent) {
            this.percent = percent
        },       
        uploadFile() {
            var formData = new FormData();
            formData.append("file", this.file);
            formData.append("desc", this.desc);
            this.loading = true;

            if (this.$route.params.collection == 'wunsch') {
                formData.append("category_id", this.$route.params.category_id);
            }
            
            const url = `/dashboard/documents/${this.$route.params.collection}`;

            let that = this
            let config = {
                onUploadProgress(progressEvent) {
                    var percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    that.onProgress(percentCompleted);
                }
            };
            axios.post(url, formData, config)
            .then(resp => {
                this.loading = false;
                Vue.$toast.success(this.$t('file_stored'))
                this.$router.push({name: 'mediaIndex', params:{type: this.$route.params.type}})
            })
            .catch(error => {
                this.loading = false;
                this.$helpers.showError(error)
            });
        }
    }
};
</script>