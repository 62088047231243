<template>
    <v-container fluid tag="section" class="pt-3 px-8 pb-8">
        <!-- Head Buttons -->
        <v-row >
            <v-col cols="10" class="pt-3 pl-8 pr-8 pb-8">
                <p class="text-h5 main-font-bold pb-7"><span class="pr-5">{{ $t('tresor') }}</span> <HelpVideo video="tresor" title="Der Tresor"></HelpVideo>

                    <!-- <router-link :to="{ name: 'newTresor' }" class="ml-5">
                        <v-btn large color="white">
                            <span class="primary--text"><v-icon class="mr-2">mdi-plus</v-icon> New File</span>
                        </v-btn>
                    </router-link> -->
                </p>
                <p class="text-subtitle-1">{{ $t('tresor_subtitle') }}</p>
            </v-col>
        </v-row>

        <!-- Medias -->
        <transition name="slide" mode="out-in">
            <v-row v-if="!loading" :key="1">
                <v-col cols="12" v-if="files ? files.length : false">
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">#</th>
                                    <th class="text-left">{{ $t('name') }}</th>
                                    <th class="text-left">{{ $t('description') }}</th>
                                    <th class="text-left">{{ $t('size') }}</th>
                                    <th class="text-left">{{ $t('date') }}</th>
                                    <th class="text-center">{{ $t('actions') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(file, i) in files" :key="file.id">
                                    <td>{{ i + 1 }}</td>
                                    <td>{{ file.name }}</td>
                                    <td>{{ file.desc }}</td>
                                    <td>{{ getSize(file.size) }}</td>
                                    <td>{{ file.created_at }}</td>
                                    <td class="text-center">
                                        <v-btn v-if="!trustedPanel" medium text fab color="grey" @click="lastAction = { action: 'delete', payload: file.id }; deleteTresorFile(file.id)" :loading="deleteTresorFileLoading == file.id" :disabled="deleteTresorFileLoading == file.id"><v-icon>mdi-delete</v-icon></v-btn>
                                        <v-btn medium text fab color="primary" @click="lastAction = { action: 'download', payload: file };downloadTresorFile(file)" :loading="downloadTresorFileLoading == file.slug" :disabled="downloadTresorFileLoading ? true : false"><v-icon>mdi-cloud-download</v-icon></v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
                <!-- Add New File Button -->
                <v-col justify="center" align="center" cols="12" class="mt-10 mb-16">
                    <router-link :to="{name: 'newTresor'}">
                        <v-icon x-large color="primary">mdi-cloud-upload-outline</v-icon>
                    </router-link>
                    <p class="text-subtitle-1 mt-5">{{ $t('upload_new_file') }}</p>
                </v-col>
            </v-row>
            <v-row v-else class="mb-5 mt-5" :key="0">
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
            </v-row>
        </transition>

        <!-- Delete Media Confirm Dialog -->
        <v-dialog
            v-model="deleteConformDialog"
            persistent
            max-width="290"
        >
            <v-card>
                <v-card-title class="text-h5">
                    {{ $t('are_you_sure') }}
                </v-card-title>

                <v-card-text>
                    {{ $t('delete_file_alert') }}
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="error darken-1"
                        text
                        @click="deleteMedia(selectedMedia)"
                    >
                        {{ $t('yes') }}
                    </v-btn>

                    <v-btn
                        color="primary darken-1"
                        text
                        @click="deleteConformDialog = false; selectedMedia = null;"
                    >
                        {{ $t('no') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Delete File Confirm Dialog -->
        <v-dialog
            v-model="deleteConformDialog"
            persistent
            max-width="290"
        >
            <v-card>
                <v-card-title class="text-h5">
                    {{ $t('are_you_sure') }}
                </v-card-title>

                <v-card-text>
                    {{ $t('delete_file_alert') }}
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="error darken-1"
                        text
                        @click="deleteTresorFile(selectedMedia)"
                    >
                        {{ $t('yes') }}
                    </v-btn>

                    <v-btn
                        color="primary darken-1"
                        text
                        @click="deleteConformDialog = false; selectedMedia = null;"
                    >
                        {{ $t('no') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Init Keys Dialog -->
        <v-dialog
            v-model="initKeys"
            persistent
            max-width="600"
        >
            <v-card>
                <v-card-title class="text-h5">
                    {{ $t('init_security_keys') }}
                </v-card-title>

                <v-card-text align="center" class="mt-5">
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                    <div class="text-body-1 mt-2">{{ $t('please_wait') }}</div>
                </v-card-text>

            </v-card>
        </v-dialog>

        <!-- Payment Required Dialog -->
        <v-dialog
            v-model="paymentRequireDialog"
            max-width="450"
            persistent
        >
            <v-card>
                <v-card-title class="text-h5">
                    {{ $t('need_payment') }}
                </v-card-title>

                <v-card-text>
                    {{ $t('need_payment_desc') }}
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="error darken-1"
                        text
                        @click="$router.push({ name: 'profileInfo' })"
                    >
                        {{ $t('later') }}
                    </v-btn>

                    <v-btn
                        color="primary darken-1"
                        text
                        @click="$router.push({ name: 'profilePlans' })"
                    >
                        {{ $t('buy_now') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Otp Dialog -->
        <v-dialog
            v-model="otpDialog"
            persistent
            max-width="600"
        >
            <v-card>
                <v-card-title class="text-h5">
                    {{ $t('otp_title') }}
                </v-card-title>

                <v-card-text align="center" class="mt-5">
                    <v-text-field 
                        class="pa-0"
                        :placeholder="$t('otp_code')"
                        v-model="otp" 
                        :loading="otpLoading"
                        :disabled="otpLoading"
                        outlined
                    ></v-text-field>
                </v-card-text>

                <v-card-text align="center" justify="center">
                    <v-row class="align-self-center">
                        <router-link :to="{ name: 'profileInfo' }" class="align-self-center">
                            <span class="error--text"><v-icon class="mr-2" color="error">mdi-arrow-left</v-icon> {{ $t('cancel') }}</span>
                        </router-link>
                        <v-spacer></v-spacer>
                        <v-btn fab color="primary" class="mx-2" @click="loginOtp" :disabled="otpLoading || !otp">
                            <v-icon>mdi-lock</v-icon>
                        </v-btn>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
import moment from 'moment'
import {Decrypt,Encrypt} from '@/plugins/utils.js'
import Vue from 'vue'
import HelpVideo from "../../../../widgets/HelpVideo"

export default {
    components:{
        moment,
        HelpVideo
    },
    data(){
        return {

            trustedPanel: false, // true: files of trusting panels 
            loading: true,
            files : null,
            
            deleteConformDialog: false,
            initKeys: false,
            getPrvPemDialog: false,
            fileTrustedUsersDialog: false,

            selectedMedia: null,
            pemError: null,

            downloadTresorFileLoading: false,
            deleteTresorFileLoading: false,
            getFileTrustedUsersLoading: false,
            deleteFileTrustedUserLoading: false,

            prvKeyFile: null,
            prvKeyText: null,
            pubKeyPEM: null,

            fileTrustedUsers: null,
            usersLoading: true,
            groupsLoading: true,
            users: null,
            groups: null,

            newFileTrustedUser: null,
            newFileTrustedGroup: null,
            newFileTrustedDate: {
                start_at: null,
                expire_at: null,
            },
            starts_date: false,
            expired_date: false,

            userItems: null,
            groupItems: null,
            addFileTrustedLoading: false,

            selectedFile: null,

            trustersMenu: false,
            trustersLoading: true,
            trusterUsers: null,
            truster: null,

            trustedMenuUsers: [],
            selectedTrustedUser: null,

            paymentRequireDialog: false,
            
            lastAction: null,

            prvKeyPEM: null,

            otp: null,
            otpDialog: false,
            otpLoading: false,
    
        }
    },
    mounted(){
      this.checkTresorFeature()
    },
    methods: {
      checkTresorFeature() {
        this.loading = true
        return this.$store.dispatch('checkTresorFeature')
            .then(resp => {
              this.loading = false
              if (!this.$store.getters.tresorToken) this.otpDialog = true
              else this.getMe()
            })
            .catch(err => {
              this.loading = false
              this.paymentRequireDialog = true
            })
      },
        getMe(){
            this.loading= true
            this.$store.dispatch('getMe')
            .then(resp => {
                this.pubKeyPEM = resp.public_key
                if (resp.public_key) {
                    this.getFiles()
                    this.getPrvKey()
                }
                else {
                    this.initKeys = true
                    this.initSecurity()
                }
            })
        },

        getFiles(){
            this.loading = true
            this.$store.dispatch('getFiles')
            .then(resp => {
                this.files = resp
                this.loading = false
            })
            .catch (err => {
                this.loading = false
                if (err.response.status === 402) this.paymentRequireDialog = true
                else this.$helpers.showError(err)
            })
        },

        downloadTresorFile(file){
            this.downloadTresorFileLoading = file.slug
            this.$store.dispatch('getFile', file.slug)
            .then(resp => {
                this.decryptKeys(file.key, resp, file.name)
            })
            .catch (err => {
                this.downloadTresorFileLoading = false
                if (err.response.status === 402) this.paymentRequireDialog = true
                else this.$helpers.showError(err)
            })
        },

        deleteTresorFile(id){
            if (!this.selectedMedia){
                this.selectedMedia = id
                this.deleteConformDialog = true                
            }
            else {
                this.deleteTresorFileLoading = id
                this.fabLoader = id
                this.deleteConformDialog = false
                this.$store.dispatch('deleteTresorFile', {id: id})
                .then(resp => {
                    this.fabLoader = false
                    this.deleteTresorFileLoading = false 
                    this.selectedMedia = null
                    this.getFiles()
                })
                .catch (err => {
                    this.deleteTresorFileLoading = false
                    this.fabLoader = false
                    if (err.response.status === 402) this.paymentRequireDialog = true
                    else this.$helpers.showError(err)
                })
            
            }
        },

        encodingPrvKey(){
            var fileReader = new FileReader();
            var that = this
            fileReader.onload = function(fileLoadedEvent){
                var textFromFileLoaded = fileLoadedEvent.target.result;
                that.prvKeyText = textFromFileLoaded

                // Check Validation
                let result = that.$helpers.prvKeyValidator(that.pubKeyPEM, textFromFileLoaded)
                if (!result) {
                    that.prvKeyFile = null
                    that.prvKeyText = null
                    that.pemError = true
                    that.$helpers.showMessage('error', that.$t('prv_pem_invalid'))
                    that.getPrvPemDialog = true
                }
                that.checkLastAction()
            };

            fileReader.readAsText(that.prvKeyFile, "UTF-8");
        },

        decryptKeys(keys, encFile, name){
            let key = this.decryptData(this.prvKeyPEM, keys.key);
            let iv = this.decryptData(this.prvKeyPEM, keys.iv)
            let decFile = Decrypt(encFile, key, iv)
            var element = document.createElement('a');
            element.setAttribute('href', decFile);
            element.setAttribute('download', name);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
            this.downloadTresorFileLoading = false
        },

        getSize(size){
            let s = Math.floor(size / 1024)
            let format = 'KB'
            if (s >= 1024) {
                s = Math.floor(s / 1024)
                format = 'MB'
            }
            if (s >= 1024) {
                s = Math.floor(s / 1024)
                format = 'GB'
            }
            return `${s} ${format}`
        },

        //  encryption
        encryptedData(publicKey, data) {
            // New JSEncrypt object
            let encryptor = new this.$rsa();
            // Setting public key
            encryptor.setPublicKey(publicKey);
            // Encrypted data
            return encryptor.encrypt(data);
        },

        // Decrypt
        decryptData(privateKey, data){
            // New JSEncrypt object
            let decrypt= new this.$rsa();
            // Set private key
            decrypt.setPrivateKey(privateKey);
            // Declassified data
            return decrypt.decrypt(data);
        },

        checkLastAction(){
            if (this.lastAction){
                switch (this.lastAction.action) {
                    case 'delete':
                        this.prvKeyFile ? this.deleteTresorFile(this.lastAction.payload) : this.getPrvPemDialog = true;
                        break;
                    case 'download':
                        this.prvKeyFile ? this.downloadTresorFile(this.lastAction.payload) : this.getPrvPemDialog = true;
                        break;
                    case 'access':
                        this.prvKeyFile ? this.getFileTrustedUsers(this.lastAction.payload) : this.getPrvPemDialog = true;
                        break;
                    case 'deleteCategory':
                        this.prvKeyFile ? this.deleteCategory() : this.getPrvPemDialog = true;
                        break;
                }
                this.lastAction = null
            }
        },

        initSecurity(){
            this.generateKey()
            .then(resp => {
                this.$store.dispatch('setPubKey', {
                    public_key: this.pubKeyPEM
                })
                .then(resp => {
                    this.$store.dispatch('setPrvKey', {
                        private_key: this.prvKeyPEM
                    })
                    .then(resp => {
                        this.initKeys = false
                        this.getFiles()
                        this.getPrvKey()
                    })
                })
            }) 
        },

        getPrvKey(){
            this.$store.dispatch('getKeys')
            .then(resp => {
                this.prvKeyPEM = resp.private_key
            })
            .catch (err => {
                if (err.response.status === 402) this.paymentRequireDialog = true
                else this.$helpers.showError(err)
            })
        },

        async generateKey() {
            return new Promise((resolve, reject) => {
                try {
                    let key = this.RSA.KEYUTIL.generateKeypair("RSA", 2048);
                    let pubKeyPEM = this.RSA.KEYUTIL.getPEM(key.pubKeyObj);
                    let prvKeyPEM = this.RSA.KEYUTIL.getPEM(
                        key.prvKeyObj,
                        "PKCS8PRV"
                    );
                    this.pubKeyPEM = pubKeyPEM
                    this.prvKeyPEM = prvKeyPEM
                    resolve(true)
                } catch (error) {
                    reject(error)
                }
            })
        },

        loginOtp(){
            if (!this.otp){
                Vue.$toast.error(this.$t('enter_otp'))
                return
            }
        
            this.otpLoading = true
            this.$store.dispatch('tresorOtp', {
                otp: this.otp,
            })
            .then(resp => {
                this.otpDialog = false
                this.getMe()
            })
            .catch(err => {
                this.$helpers.showError(err)
                this.otpLoading= false
            })
        },
    }
}
</script>