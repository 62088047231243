<template>
    <v-container
        fluid
        tag="section"
    >
        <v-row justify="center" class="pl-8 pr-8 pb-8 pt-3">
            <v-col cols="12">
                <v-row v-if="user">
                    <!-- Trusted User Informations -->
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12" md="12" class="mb-10">
                                <v-row>
                                    <v-col cols="auto" align="center" align-self="center">
                                        <input id="avatar_input" type="file" hidden @change="uploadAvatar" accept="image/png, image/jpeg, image/jpg">
                                        <v-badge
                                            bordered
                                            color="primary"
                                            icon="mdi-cloud-upload"
                                            overlap
                                            bottom
                                            avatar
                                        >
                                            <v-btn 
                                                fab 
                                                x-large 
                                                @click="chooseFile"
                                                :loading="uploading"
                                                :disabled="uploading"
                                            >
                                                <v-avatar size="100">
                                                    <v-img :src="$store.getters.user.user.avatar ? $store.getters.user.user.avatar : '/assets/img/avatar.png'">
                                                        <template v-slot:placeholder>
                                                            <v-row
                                                            class="fill-height ma-0"
                                                            align="center"
                                                            justify="center"
                                                            >
                                                            <v-progress-circular
                                                                indeterminate
                                                                color="primary"
                                                            ></v-progress-circular>
                                                            </v-row>
                                                        </template>
                                                    </v-img>
                                                </v-avatar>
                                            </v-btn>
                                        </v-badge>
                                    </v-col>
                                    <v-col align-self="center" justify="center">
                                        <div class="text-h6 main-font-bold main-title-color">{{ user.firstname }} {{ user.name }} {{ $store.getters.secondUser ? `( Password: ${user.password})`: ''}}</div>
                                        <div class="subtitle-1 font-weight-light main-grey mt-0 pt-0 pb-10"><span class="pr-5">{{ $t('personal_information') }}</span> <HelpVideo video="profil" title="Mein Profil"></HelpVideo></div>
                                        
<!--                                        <transition name="slide" mode="out-in">-->
<!--                                            <div class="subtitle-1 font-weight-light main-grey mt-0 pt-0" v-if="!inviteEmailState" :key="1">-->
<!--                                                {{ $t('invite_code') }}: <span class="font-weight-bold primary&#45;&#45;text">{{ inviteCode || '-&#45;&#45;' }}</span>-->
<!--                                                <CopyToClipboard :text="getUrl()" @copy="handleCopy">-->
<!--                                                    <v-btn color="info darken-2" class="ml-2" outlined small rounded>-->
<!--                                                        {{ $t('copy_invite_link') }} <v-icon class="ml-2" small>mdi-content-copy</v-icon>-->
<!--                                                    </v-btn>-->
<!--                                                </CopyToClipboard>-->
<!--                                                <v-btn v-if="!inviteEmailState" @click="inviteEmailState = true" color="info darken-2" class="ml-2" outlined small rounded>-->
<!--                                                    {{ $t('send_invite_email') }} <v-icon class="ml-2" small>mdi-content-copy</v-icon>-->
<!--                                                </v-btn>-->
<!--                                                        -->
<!--                                            </div>-->
<!--                                            <div class="subtitle-1 font-weight-light main-grey mt-1 pt-0" v-else :key="2">-->
<!--                                                <v-row>-->
<!--                                                    <v-col cols="6">-->
<!--                                                        <v-text-field-->
<!--                                                            outlined-->
<!--                                                            :label="$t('invite_email_lbl')"-->
<!--                                                            :placeholder="$t('invite_email_placeholder')"-->
<!--                                                            :disabled="sendEmail"-->
<!--                                                            :loading="sendEmail"-->
<!--                                                            dense-->
<!--                                                            v-model="inviteEmail"-->
<!--                                                            hide-details-->
<!--                                                            @keyup.enter="sendInviteEmail"-->
<!--                                                        />                                                       -->
<!--                                                    </v-col>-->
<!--                                                    <v-col cols="auto">-->
<!--                                                        <v-btn :disabled="sendEmail" color="error darken-2" class="ml-2" fab small outlined @click="inviteEmailState = false">-->
<!--                                                            <v-icon small>mdi-close</v-icon>-->
<!--                                                        </v-btn>-->
<!--                                                        <v-btn :loading="sendEmail" color="info darken-2" class="ml-2" outlined large rounded @click="sendInviteEmail">-->
<!--                                                            {{ $t('send') }} <v-icon class="ml-2" small>mdi-chevron-right</v-icon>-->
<!--                                                        </v-btn>-->
<!--                                                    </v-col>-->
<!--                                                </v-row>-->
<!--                                            </div>-->
<!--                                        </transition>-->

<!--                                        <div class="subtitle-1 font-weight-light main-grey mt-0 pt-0">-->
<!--                                            <a href="/versicherungsvergleich">-->
<!--                                                <v-btn color="primary darken-2" small rounded>Versicherungsvergleich</v-btn>-->
<!--                                            </a>-->
<!--                                        </div>-->

                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="9" class="change-infos">
                        <v-row>
                          <v-col cols="6" md="3" class="py-2">
                            <div class="text-subtitle-1 main-title-color">{{ $t('first_name') }}</div>
                            <transition name="slide" mode="out-in">
                              <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4" v-if="active != 'firstname'" @click="activeLoading ? null :active = 'firstname'">{{ user.firstname  ? user.firstname : $t('tap_to_edit') }}</div>
                              <v-text-field
                                  dense
                                  hide-details
                                  clearable
                                  class="pa-0"
                                  v-else append-icon="mdi-check-circle"
                                  clear-icon="mdi-close-circle"
                                  v-model="user.firstname"
                                  :loading="activeLoading == 'firstname'"
                                  :disabled="activeLoading == 'firstname'"
                                  @click:clear="active = false"
                                  @click:append="updateProfile('firstname')"
                                  @keyup.enter="updateProfile('firstname')"
                              >
                              </v-text-field>
                            </transition>
                          </v-col>
                            <v-col cols="6" md="3" class="py-2">
                                <div class="text-subtitle-1 main-title-color">{{ $t('last_name') }}</div>
                                <transition name="slide" mode="out-in">
                                    <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4" v-if="active != 'name'" @click="activeLoading ? null :active = 'name'">{{ user.name  ? user.name : $t('tap_to_edit') }}</div>
                                    <v-text-field 
                                        dense
                                        hide-details
                                        clearable
                                        class="pa-0"
                                        v-else append-icon="mdi-check-circle"
                                        clear-icon="mdi-close-circle"
                                        v-model="user.name" 
                                        :loading="activeLoading == 'name'"
                                        :disabled="activeLoading == 'name'"
                                        @click:clear="active = false"
                                        @click:append="updateProfile('name')"
                                        @keyup.enter="updateProfile('name')"
                                    >
<!--                                      <template v-slot:append>-->
<!--                                        <v-icon color="red">mdi-check-circle</v-icon>-->
<!--                                      </template>-->
                                    </v-text-field>
                                </transition>
                            </v-col>
                            <v-col cols="6" md="3" class="py-2">
                                <div class="text-subtitle-1 main-title-color">{{ $t('address') }}</div>
                                <transition name="slide" mode="out-in">
                                    <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4" v-if="active != 'address'" @click="activeLoading ? null :active = 'address'" v-html="user.address  ? prepareAddress(user.address) : $t('tap_to_edit')"></div>
                                    <v-textarea
                                            dense
                                            hide-details
                                            class="pa-0"
                                            rows="3"
                                            v-else
                                            append-outer-icon="mdi-check-circle"
                                            v-model="user.address"
                                            :loading="activeLoading == 'address'"
                                            :disabled="activeLoading == 'address'"
                                            @click:clear="active = false"
                                            @click:append-outer="updateProfile('address')"
                                    ></v-textarea>
                                </transition>
                            </v-col>
                            <v-col cols="6" md="3" class="py-2">
                                <div class="text-subtitle-1 main-title-color">{{ $t('birthplace') }}</div>
                                <transition name="slide" mode="out-in">
                                    <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4" v-if="active != 'birthplace'" @click="activeLoading ? null :active = 'birthplace'">{{ user.birthplace  ? user.birthplace : $t('tap_to_edit') }}</div>
                                    <v-text-field
                                            dense
                                            hide-details
                                            class="pa-0"
                                            v-else append-outer-icon="mdi-check-circle"
                                            v-model="user.birthplace"
                                            :loading="activeLoading == 'birthplace'"
                                            :disabled="activeLoading == 'birthplace'"
                                            @click:clear="active = false"
                                            @click:append-outer="updateProfile('birthplace')"
                                            @keyup.enter="updateProfile('birthplace')"
                                    ></v-text-field>
                                </transition>
                            </v-col>
                            <v-col cols="6" md="3" class="py-2">
                                <div class="text-subtitle-1 main-title-color">{{ $t('birthdate') }}</div>
                                <transition name="slide" mode="out-in">
                                    <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4" v-if="active != 'birthdate'" @click="activeLoading ? null :active = 'birthdate'">{{ user.birthdate  ? user.birthdate : $t('tap_to_edit') }}</div>
                                    <v-text-field
                                            dense
                                            hide-details
                                            class="pa-0"
                                            placeholder="dd.mm.yyyy"
                                            v-else append-outer-icon="mdi-check-circle"
                                            v-model="user.birthdate"
                                            :loading="activeLoading == 'birthdate'"
                                            :disabled="activeLoading == 'birthdate'"
                                            @click:clear="active = false"
                                            @click:append-outer="updateProfile('birthdate')"
                                            @keyup.enter="updateProfile('birthdate')"
                                    ></v-text-field>
                                </transition>
                            </v-col>
                            <v-col cols="6" md="3" class="py-2">
                                <div class="text-subtitle-1 main-title-color">{{ $t('age') }}</div>
                                <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4">{{ user.age  ? user.age : '--' }}</div>
                            </v-col>
<!--                            <v-col cols="6" md="3" class="py-2">-->
<!--                                <div class="text-subtitle-1 main-title-color">{{ $t('martial_status') }}</div>-->
<!--                                <transition name="slide" mode="out-in">-->
<!--                                    <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4" v-if="active != 'martial_status'" @click="activeLoading ? null :active = 'martial_status'">{{ user.martial_status != null  ? convertMartialStatus(user.martial_status) : $t('tap_to_edit') }}</div>-->
<!--                                    <v-select-->
<!--                                        dense-->
<!--                                        hide-details-->
<!--                                        class="pa-0"-->
<!--                                        v-else append-outer-icon="mdi-check-circle"-->
<!--                                        :items="[-->
<!--                                            { text : $t('verheiratet'), value: 1 },-->
<!--                                            { text : $t('single'), value: 0 },-->
<!--                                            { text : $t('widowed'), value: 2 },-->
<!--                                            { text : $t('divorced'), value: 3 },-->
<!--                                            { text : $t('registered_civil_partnership'), value: 4 },-->
<!--                                        ]"-->
<!--                                        v-model="user.martial_status"-->
<!--                                        :loading="activeLoading == 'martial_status'"-->
<!--                                        :disabled="activeLoading == 'martial_status'"-->
<!--                                        @click:clear="active = false"-->
<!--                                        @click:append-outer="updateProfile('martial_status')"-->
<!--                                        @keyup.enter="updateProfile('martial_status')"-->
<!--                                    ></v-select>-->
<!--                                </transition>-->
<!--                            </v-col>-->
                            <v-col cols="6" md="3" class="py-2">
                                <div class="text-subtitle-1 main-title-color">{{ $t('phone') }}</div>
                                <transition name="slide" mode="out-in">
                                    <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4" v-if="active != 'phone'" @click="activeLoading ? null :active = 'phone'">{{ user.phone  ? user.phone : $t('tap_to_edit') }}</div>
                                    <v-text-field
                                            dense
                                            hide-details
                                            class="pa-0"
                                            v-else append-outer-icon="mdi-check-circle"
                                            v-model="user.phone"
                                            :loading="activeLoading == 'phone'"
                                            :disabled="activeLoading == 'phone'"
                                            @click:clear="active = false"
                                            @click:append-outer="updateProfile('phone')"
                                            @keyup.enter="updateProfile('phone')"
                                    ></v-text-field>
                                </transition>
                            </v-col>
                            <v-col cols="6" md="3" class="py-2">
                                <div class="text-subtitle-1 main-title-color">{{ $t('indent_nr') }}</div>
                                <transition name="slide" mode="out-in">
                                    <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4" v-if="active != 'indent_nr'" @click="activeLoading ? null :active = 'indent_nr'">{{ user.indent_nr  ? user.indent_nr : $t('tap_to_edit') }}</div>
                                    <v-text-field 
                                        dense
                                        hide-details
                                        class="pa-0"
                                        v-else 
                                        append-outer-icon="mdi-check-circle"
                                        v-model="user.indent_nr" 
                                        :loading="activeLoading == 'indent_nr'"
                                        :disabled="activeLoading == 'indent_nr'"
                                        @click:clear="active = false"
                                        @click:append-outer="updateProfile('indent_nr')"
                                        @keyup.enter="updateProfile('indent_nr')"
                                    ></v-text-field>
                                </transition>
                            </v-col>
<!--                            <v-col cols="6" md="3" class="py-2">-->
<!--                                <div class="text-subtitle-1 main-title-color">{{ $t('height') }}</div>-->
<!--                                <transition name="slide" mode="out-in">-->
<!--                                    <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4" v-if="active != 'height'" @click="activeLoading ? null :active = 'height'">{{ user.height  ? user.height : $t('tap_to_edit') }}</div>-->
<!--                                    <v-text-field -->
<!--                                        dense-->
<!--                                        hide-details-->
<!--                                        class="pa-0"-->
<!--                                        type="number"-->
<!--                                        v-else -->
<!--                                        append-outer-icon="mdi-check-circle"-->
<!--                                        v-model="user.height"-->
<!--                                        :loading="activeLoading == 'height'"-->
<!--                                        :disabled="activeLoading == 'height'"-->
<!--                                        @click:clear="active = false"-->
<!--                                        @click:append-outer="updateProfile('height')"-->
<!--                                        @keyup.enter="updateProfile('height')"-->
<!--                                    ></v-text-field>-->
<!--                                </transition>-->
<!--                            </v-col>-->
<!--                            <v-col cols="6" md="3" class="py-2">-->
<!--                                <div class="text-subtitle-1 main-title-color">{{ $t('spouse') }}</div>-->
<!--                                <transition name="slide" mode="out-in">-->
<!--                                    <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4" v-if="active != 'spouse'" @click="activeLoading ? null :active = 'spouse'">{{ user.spouse  ? user.spouse : $t('tap_to_edit') }}</div>-->
<!--                                    <v-text-field -->
<!--                                        dense-->
<!--                                        hide-details-->
<!--                                        class="pa-0"-->
<!--                                        v-else append-outer-icon="mdi-check-circle"-->
<!--                                        v-model="user.spouse"-->
<!--                                        :loading="activeLoading == 'spouse'"-->
<!--                                        :disabled="activeLoading == 'spouse'"-->
<!--                                        @click:clear="active = false"-->
<!--                                        @click:append-outer="updateProfile('spouse')"-->
<!--                                        @keyup.enter="updateProfile('spouse')"-->
<!--                                    ></v-text-field>-->
<!--                                </transition>-->
<!--                            </v-col>-->
<!--                            <v-col cols="6" md="3" class="py-2">-->
<!--                                <div class="text-subtitle-1 main-title-color">{{ $t('job') }}</div>-->
<!--                                <transition name="slide" mode="out-in">-->
<!--                                    <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4" v-if="active != 'job'" @click="activeLoading ? null :active = 'job'">{{ user.job ? user.job : $t('tap_to_edit') }}</div>-->
<!--                                    <v-text-field -->
<!--                                        dense-->
<!--                                        hide-details-->
<!--                                        class="pa-0"-->
<!--                                        v-else append-outer-icon="mdi-check-circle"-->
<!--                                        v-model="user.job" -->
<!--                                        :loading="activeLoading == 'job'"-->
<!--                                        :disabled="activeLoading == 'job'"-->
<!--                                        @click:clear="active = false"-->
<!--                                        @click:append-outer="updateProfile('job')"-->
<!--                                        @keyup.enter="updateProfile('job')"-->
<!--                                    ></v-text-field>-->
<!--                                </transition>-->
<!--                            </v-col>-->
<!--                            <v-col cols="6" md="3" class="py-2">-->
<!--                                <div class="text-subtitle-1 main-title-color">{{ $t('gender') }}</div>-->
<!--                                <transition name="slide" mode="out-in">-->
<!--                                    <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4" v-if="active != 'gender'" @click="activeLoading ? null :active = 'gender'">{{ user.gender  ? $t(user.gender == 'other' ? 'other_gender' : user.gender) : $t('tap_to_edit') }}</div>-->
<!--                                    <v-select-->
<!--                                        dense-->
<!--                                        hide-details-->
<!--                                        class="pa-0"-->
<!--                                        :items="[-->
<!--                                            { text: $t('male'), value: 'male' },-->
<!--                                            { text: $t('female'), value: 'female' },-->
<!--                                            { text: $t('other_gender'), value: 'other' },-->
<!--                                        ]"-->
<!--                                        v-else append-outer-icon="mdi-check-circle"-->
<!--                                        v-model="user.gender"-->
<!--                                        :loading="activeLoading == 'gender'"-->
<!--                                        :disabled="activeLoading == 'gender'"-->
<!--                                        @click:clear="active = false"-->
<!--                                        @click:append-outer="updateProfile('gender')"-->
<!--                                        @keyup.enter="updateProfile('gender')"-->
<!--                                    ></v-select>-->
<!--                                </transition>-->
<!--                            </v-col>-->
<!--                            <v-col cols="6" md="3" class="py-2">-->
<!--                                <div class="text-subtitle-1 main-title-color">{{ $t('children') }}</div>-->
<!--                                <transition name="slide" mode="out-in">-->
<!--                                    <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4" v-if="active != 'children'" @click="activeLoading ? null :active = 'children'">{{ user.children  ? user.children : $t('tap_to_edit') }}</div>-->
<!--                                    <v-text-field -->
<!--                                        dense-->
<!--                                        hide-details-->
<!--                                        class="pa-0"-->
<!--                                        placeholder="name 1, name 2 , ..."-->
<!--                                        v-else append-outer-icon="mdi-check-circle"-->
<!--                                        v-model="user.children"-->
<!--                                        :loading="activeLoading == 'children'"-->
<!--                                        :disabled="activeLoading == 'children'"-->
<!--                                        @click:clear="active = false"-->
<!--                                        @click:append-outer="updateProfile('children')"-->
<!--                                        @keyup.enter="updateProfile('children')"-->
<!--                                    ></v-text-field>-->
<!--                                </transition>-->
<!--                            </v-col>-->
<!--                            <v-col cols="6" md="3" class="py-2">-->
<!--                                <div class="text-subtitle-1 main-title-color">E-Mail</div>-->
<!--                                <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4">{{ user.email  ? user.email : $t('tap_to_edit') }}</div>-->
<!--                            </v-col>-->


<!--                            <v-col cols="6" md="3" class="py-2">-->
<!--                                <div class="text-subtitle-1 main-title-color">{{ $t('nationality') }}</div>-->
<!--                                <transition name="slide" mode="out-in">-->
<!--                                    <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4" v-if="active != 'nationality'" @click="activeLoading ? null :active = 'nationality'">{{ user.nationality  ? user.nationality : $t('tap_to_edit') }}</div>-->
<!--                                    <v-text-field -->
<!--                                        dense-->
<!--                                        hide-details-->
<!--                                        class="pa-0"-->
<!--                                        v-else append-outer-icon="mdi-check-circle"-->
<!--                                        v-model="user.nationality"-->
<!--                                        :loading="activeLoading == 'nationality'"-->
<!--                                        :disabled="activeLoading == 'nationality'"-->
<!--                                        @click:clear="active = false"-->
<!--                                        @click:append-outer="updateProfile('nationality')"-->
<!--                                        @keyup.enter="updateProfile('nationality')"-->
<!--                                    ></v-text-field>-->
<!--                                </transition>-->
<!--                            </v-col>-->
<!--                            <v-col cols="6" md="3" class="py-2">-->
<!--                                <div class="text-subtitle-1 main-title-color">{{ $t('national_code') }}</div>-->
<!--                                <transition name="slide" mode="out-in">-->
<!--                                    <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4" v-if="active != 'national_code'" @click="activeLoading ? null :active = 'national_code'">{{ user.national_code  ? user.national_code : $t('tap_to_edit') }}</div>-->
<!--                                    <v-text-field -->
<!--                                        dense-->
<!--                                        hide-details-->
<!--                                        class="pa-0"-->
<!--                                        v-else append-outer-icon="mdi-check-circle"-->
<!--                                        v-model="user.national_code" -->
<!--                                        :loading="activeLoading == 'national_code'"-->
<!--                                        :disabled="activeLoading == 'national_code'"-->
<!--                                        @click:clear="active = false"-->
<!--                                        @click:append-outer="updateProfile('national_code')"-->
<!--                                        @keyup.enter="updateProfile('national_code')"-->
<!--                                    ></v-text-field>-->
<!--                                </transition>-->
<!--                            </v-col>-->
                        </v-row>
                    </v-col>
                    <v-col cols="3" class="d-none d-sm-flex py-2" align-self="end">
                        <v-img src="/assets/img/dashboard/profile-vector.png" contain max-height="300px">
                        </v-img>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import CopyToClipboard from 'vue-copy-to-clipboard'
import HelpVideo from "../../../../widgets/HelpVideo"

export default {
    data(){
        return {
            loading: true,            
            user: null,
            active: false,
            activeLoading: false,
            uploading: false,
            inviteCode: null,      

            inviteEmail: null,  
            inviteEmailState: false,
            sendEmail: false
        }
    },
    mounted(){
        this.getMe()
        this.getInvideCode()
    },
    components: {
      HelpVideo,
      CopyToClipboard
    },
    methods:{
        getMe(){
            this.loading = true
            this.$store.dispatch('getMe')
            .then(resp => {
                this.user = resp
                this.user.birthdate = this.convertDate(this.user.birthdate, '/')
                this.loading = false
                this.active = false
                this.activeLoading = false
            })
        },

        getInvideCode(){
            this.loading = true
            this.$store.dispatch('getInvideCode')
            .then(resp => {
                this.inviteCode = resp.token
            })
        },

        updateProfile(tag){
            this.activeLoading = tag
            const data = JSON.parse(JSON.stringify(this.user));
            data.birthdate = this.convertDate(data.birthdate, '.')
            this.$store.dispatch('updateProfile', data)
            .then(resp => {
                this.getMe()
            })
            .catch(error => {
                this.$helpers.showError(error)
                this.activeLoading = false
            })
        },

        chooseFile(){
            document.getElementById("avatar_input").click()
        },

        uploadAvatar(event){
            this.uploading = true
            let data = new FormData()
            data.append('avatar', event.target.files[0])
            this.$store.dispatch('uploadAvatar', data)
            .then(resp => {
                this.$store.dispatch('getMe')
                .then(resp => {
                    this.uploading = false
                })
            })
            .catch(err => {
                this.$helpers.showError(err)
                this.uploading = false
            })
        },

        convertDate(date, delimeter){
            if (date){
                let temp = date.split(delimeter)
                if (temp.length == 3){
                    return temp[2]+ (delimeter == '/' ? '.' : '/') + temp[1]+ (delimeter == '/' ? '.' : '/')+ temp[0]
                }
                return null
            }
            else return null
        },

        convertMartialStatus(key){
            switch (key) {
                case 0:
                    return this.$t('single')
                case 1:
                    return this.$t('verheiratet')
                case 2:
                    return this.$t('widowed')
                case 3:
                    return this.$t('divorced')
                case 4:
                    return this.$t('registered_civil_partnership')
            }
        },

        getUrl() {
            var Url = window.location.origin+ `/auth/register?c=${this.inviteCode}`;
            return Url
        },

        handleCopy (result) {
            this.$helpers.showMessage('success', this.$t('copied'))
        },

        sendInviteEmail(){
            if (!this.inviteEmail){
                this.$helpers.showMessage('error', this.$t('invite_email_empty'))
                return
            }
            this.sendEmail = true
            this.$store.dispatch('sendInviteEmail', {email: this.inviteEmail})
            .then(resp => {
               this.$helpers.showMessage('success', this.$t('invite_email_sended'))
               this.sendEmail = false
               this.inviteEmail = null
               this.inviteEmailState = false
            })
            .catch(err => {
                this.$helpers.showError(err)
                this.sendEmail = false
            })
        },
        prepareAddress(address) {
          return address.replace(/(\r\n|\r|\n)/g, '<br>')
        }
    }
};
</script>