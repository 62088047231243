<template>
  <v-row justify="center">
    <v-dialog
        :value="value"
        @input="$emit('input', $event)"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          Weiterleitung fehlgeschlagen
<!--          <v-spacer></v-spacer>-->
<!--          <v-btn icon @click="$emit('close-login-modal')">-->
<!--            <v-icon>mdi-close</v-icon>-->
<!--          </v-btn>-->
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="mt-2 red--text">
              Die automatische Weiterleitung konnte nicht durchgeführt werden da ein Popup-Blocker aktiviert ist.
              Bitte klicke auf den Button um zum Shop zu gelangen.
            </v-row>
            <v-row class="mt-10" justify="center">
              <v-btn
                  elevation="5"
                  :loading="loading"
                  :disabled="loading"
                  color="turquoise"
                  block
                  @click="redirectToShop()"
              >
                <span>Weiter zum Shop</span>
              </v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

export default {
  props: ['value', 'priceModel'],
  data(){
    return {
      loading:false,
      dialog: false
    }
  },
  mounted(){
  },
  methods: {
    redirectToShop() {
      this.loading = true
      window.open(this.priceModel.shop_link, '_blank');
    }
  }
}

</script>