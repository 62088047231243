import store from '../../../store/index'

export default (to, from, next) => {
    if (store.getters.isLoggedIn) {
        if (store.getters.user.user){
            next()
        }
        else next()
    } else {
        next({
            name: 'login',
            query: { redirect: to.fullPath }
        })
        return false
    }
}