<template>
  <div>
    <v-row align="center" class="my-10" v-show="plans === null">
      <v-col
          cols="12"
          align="center"
          justify="center"
      >
        <v-progress-circular
            indeterminate
            color="primary"
            class=""
        ></v-progress-circular>
      </v-col>
    </v-row>
  
    <v-row class="my-10 main-font" justify="center">
      <v-col cols="12" md="3" sm="12" v-for="(plan, index) in filteredPlans" :key="plan.id">
        <v-hover
            v-slot="{ hover }"
        >
          <v-card
              :elevation="hover ? 12 : 4"
              class="mx-auto"
              @click="$emit('open-register-modal', plan.is_free ? null : plan)"
          >
            <v-img
                class="white--text align-end"
                :src="'/assets/img/price_model/price_model_' + (index + 1) + '.jpg'"
                height="280"
            >
              <div class="price-title">{{ plan.name }}</div>
            </v-img>
  
            <v-card-text class="text--primary text-center bg-turquoise">
              <div class="price" v-if="plan.duration > 400">EUR {{ plan.price }},- <span class="per">/ einmalig</span></div>
              <div class="price" v-else-if="plan.price === 0">EUR {{ plan.price }},- <span class="per"></span></div>
              <div class="price" v-else>EUR {{ plan.price }},- <span class="per">/ Jahr</span></div>
<!--              <div class="duration" v-if="plan.duration < 400">EUR {{ plan.price }},- / Jahr</div>-->
<!--              <div class="duration primary-text">.</div>-->

<!--              <div class="price" v-if="plan.duration > 400">EUR {{ plan.price }},- <span class="per">/ einmalig</span></div>-->
<!--              <div class="price" v-else>EUR {{ plan.price_per_month.replace('.', ',') }} <span class="per">/ Monat</span></div>-->
<!--              <div class="duration" v-if="plan.duration < 400">EUR {{ plan.price }},- / Jahr</div>-->
<!--              <div class="duration primary-text" v-else>.</div>-->
            </v-card-text>
  
          </v-card>
        </v-hover>
  
        <div class="mt-10 font-weight-bold">LEISTUNGEN</div>
  
        <div class="mt-4 price-model">
          <ul>
            <li class="font-14">Laufzeit: {{ getPlanDuration(plan.duration) }}</li>
          </ul>
          <ul>
            <li v-for="feature in features" :key="feature.id" class="font-14">{{ feature.name }}: {{ getPlanFeature(plan.id, feature.code) }}</li>
          </ul>
        </div>
        
        <v-btn
            v-if="plan.is_free === 0"
            elevation="5"
            class="btn-default mt-5 mb-10"
            min-width="0"
            text
            @click="$emit('open-register-modal', plan)"
        >
          <span>Jetzt buchen</span>
        </v-btn>

        <v-btn
            v-if="plan.is_free === 1 && !$store.getters.isLoggedIn"
            elevation="5"
            class="btn-default mt-5 mb-10"
            min-width="0"
            text
            @click="$emit('open-register-modal')"
        >
          <span>Jetzt registrieren</span>
        </v-btn>

      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
    props: {
      onlyPaid: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        plans: null,
        features: [],
        priceModel: null
      }
    },
    mounted() {
      this.getFeatures()
    },
    computed: {
      // a computed getter
      filteredPlans() {
        return this.plans.filter(item => {
          let checkPaid = !(this.onlyPaid && item.is_free)
          if(this.$store.getters.isLoggedIn && item.is_free) checkPaid = false

          return item.display === 1 && checkPaid
        })
      }
    },
    methods: {
      getFeatures() {
        this.$store.dispatch("getFeaturesGuest")
            .then((resp) => {
              for (let index = 0; index < resp.length; index++) {
                const item = resp[index];
                if (item.code != 'tasks.count' && item.code != 'trusted.count')
                  this.features.push(item)
              }
              this.getPlans()
            })
      },
      getPlans(){
        this.$store.dispatch('getPlansGuest')
            .then(resp => {
              this.plans = resp
            })
      },
      getPlanFeature(plan_id, code){
        let result = false
        for (let index = 0; index < this.plans.length; index++) {
          const plan = this.plans[index];
          if(plan.id == plan_id){
            for (let k = 0; k < plan.features.length; k++) {
              const feature = plan.features[k];
              if (code == feature.code && feature.type != 'limit') result = true
              else if (code == feature.code) {
                if (code == 'files.size') result = feature.limit+ ' MB'
                else result = feature.code == 'duplicate_subscription' ? feature.limit + 1 : feature.limit
              }
            }
          }
        }

        if(result === true) result = 'Ja'
        if(result === false) result = 'Nein'

        return result
      },
      getPlanDuration(duration) {
        let dur = ''

        switch(duration) {
          case 90:
            dur = '3 Monate'
            break;
          case 365:
            dur = '1 Jahr'
            break;
          case 40000:
            dur = 'Ein Leben lang'
            break;
        }

        return dur;
      }
    }
}
</script>