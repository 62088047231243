<template>
    <v-container fluid tag="section">

        <v-row>
            <v-col cols="12" md="10" class="pt-3 pl-8 pr-8 pb-8">
                <p class="text-h5 main-font-bold pb-7">
                  <span class="pr-5">{{ $route.params.collection == 'botschaften' ? $t('collections_botschaften_title') : $t('collections_wunsch_title') }}</span>
                  <span v-if="$route.params.collection == 'botschaften'"> <HelpVideo video="botschaften" title="Persönliche Botschaften"></HelpVideo></span>
<!--                  <span v-else> <HelpVideo video="dokumente" title="Persönliche Unterlagen"></HelpVideo></span>-->
                </p>
                <p class="text-subtitle-1">{{ $route.params.collection == 'botschaften' ? $t('collections_botschaften_subtitle') : $t('collections_wunsch_title') }}</p>
            </v-col>
        </v-row>

        <!-- Head Buttons -->
        <v-row class="mt-5">
            <v-tabs v-model="tab" color="primary darken-1" :fixed-tabs="!$helpers.isMobile()" @change="getMedias" v-if="$route.params.collection == 'botschaften'">
                <v-tab @click="medias = null; $router.replace({ params :{ type: 'images'}});">{{ $t('photos') }} <v-icon class="ml-2">mdi-folder-image</v-icon></v-tab>
                <v-tab @click="medias = null; $router.replace({ params :{ type: 'documents'}});">{{ $t('documents') }} <v-icon class="ml-2">mdi-file-document-multiple</v-icon></v-tab>
                <v-tab @click="medias = null; $router.replace({ params :{ type: 'videos'}});">{{ $t('videos') }} <v-icon class="ml-2">mdi-movie-open</v-icon></v-tab>
<!--                <v-tab @click="medias = null; $router.replace({ params :{ type: 'sounds'}});">{{ $t('audios') }} <v-icon class="ml-2">mdi-cast-audio</v-icon></v-tab>-->
            </v-tabs>
            <v-col :cols="(categories ? categories.length : false) && $route.params.collection == 'wunsch' ? '11' : '12'">
                <v-tabs color="primary darken-1" show-arrows :grow="categories.length > 4" @change="getMedias" v-if="$route.params.collection == 'wunsch' && (categories ? categories.length : false)">
                    <v-tab class="white" v-for="c in categories" :key="c.id" @click="category = c.id; medias = null;">
                        <v-toolbar class="elevation-0 white" flat dense color="white">
                            {{ c.name }}
                            <v-btn 
                                small 
                                icon 
                                class="ml-5" 
                                v-if="category == c.id" 
                                @click="deleteCategoryConfirmDialog = true; deleteCategoryLoading = c.id"
                                :loading="deleteCategoryLoading == c.id"
                                :disabled="deleteCategoryLoading == c.id"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-btn small icon v-if="category == c.id" @click="newCategory = c; newCategoryDialog = true;">
                                <v-icon>mdi-circle-edit-outline</v-icon>
                            </v-btn>
                        </v-toolbar>
                    </v-tab>
                </v-tabs>
                <v-col cols="12" align="center" v-else-if="$route.params.collection == 'wunsch' && !loading && !paymentRequireDialog">
                    <v-btn large color="white" @click="newCategoryDialog = true" class="mr-2">
                        <span class="primary--text"><v-icon>mdi-plus</v-icon> {{ $t('create_new_category') }}</span>
                    </v-btn>
                </v-col>
            </v-col>
            <v-col v-if="(categories ? categories.length : false) && $route.params.collection == 'wunsch'" align="center">
                <v-btn fab small color="white" @click="newCategoryDialog = true">
                    <v-icon color="primary">mdi-plus</v-icon>
                </v-btn>
            </v-col>
            
            <transition name="slide" mode="out-in">
                 <v-col cols="12" v-if="$route.params.collection == 'wunsch' && (categories ? categories.length : false)">
                    <v-tabs v-model="tab" color="primary darken-1" centered class="mt-5" @change="getMedias">
                        <v-tab @click="medias = null; $router.replace({ params :{ type: 'images'}});"><v-icon>mdi-folder-image</v-icon></v-tab>
                        <v-tab @click="medias = null; $router.replace({ params :{ type: 'documents'}});"><v-icon>mdi-file-document-multiple</v-icon></v-tab>
                        <v-tab @click="medias = null; $router.replace({ params :{ type: 'videos'}});"><v-icon>mdi-movie-open</v-icon></v-tab>
<!--                        <v-tab @click="medias = null; $router.replace({ params :{ type: 'sounds'}});"><v-icon>mdi-cast-audio</v-icon></v-tab>-->
                    </v-tabs>  
                 </v-col>
            </transition>
        </v-row>

        <!-- Medias -->
        <transition name="slide" mode="out-in">
            <v-row v-if="medias && !loading" class="mt-10" :key="1">
                <!-- Images -->
                <v-col class="pa-1" v-if="medias.length ? medias[0].mime_type.startsWith('image') : false" cols="12" md="6">
                    <v-hover v-slot="{ hover }">
                        <v-img
                            :src="medias[0].display_url"
                            aspect-ratio="1.8"
                            contain
                            class="white"
                        >
                            <v-fade-transition>
                                <v-overlay
                                    v-if="hover"
                                    absolute
                                    color="#036358"
                                >
                                    <v-btn class="mr-1" x-small v-if="hover && !trustedPanel" fab color="grey" bottom left @click="deleteMedia(medias[0].id)"><v-icon color="white">mdi-delete</v-icon></v-btn>
                                    <a v-auth-href :href="medias[0].url" v-if="hover">
                                        <v-btn x-small class="mr-1" fab color="grey"><v-icon color="white">mdi-cloud-download</v-icon></v-btn>
                                    </a>
<!--                                    <v-btn x-small class="mr-1" v-if="hover && !trustedPanel" fab color="primary" @click="getMediaTrustedUsers(medias[0])" :loading="loading == medias[0].id" :disabled="loading"><v-icon color="white">mdi-key-variant</v-icon></v-btn>-->
                                    <v-btn x-small class="mr-1" v-if="hover" fab color="grey" @click="openImage(medias[0])"><v-icon color="white">mdi-magnify</v-icon></v-btn>
                                </v-overlay>
                            </v-fade-transition>

                            <template v-slot:placeholder>
                            <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                            >
                                <v-progress-circular
                                    indeterminate
                                    color="primary"
                                ></v-progress-circular>
                            </v-row>
                            </template>
                        </v-img>
                    </v-hover>
                </v-col>
                <v-col v-if="medias.length ? medias[0].mime_type.startsWith('image') : false" cols="12" md="6">
                    <v-row>                   
                        <v-col
                            v-for="media in medias.slice(1, 10)" :key="media.id"
                            class="d-flex child-flex pa-1"
                            cols="4"
                        >
                            <v-hover v-slot="{ hover }">
                                <v-img
                                    :src="media.display_url"
                                    aspect-ratio="1.7778"
                                    class="white"
                                >
                                    <v-fade-transition>
                                        <v-overlay
                                            v-if="hover"
                                            absolute
                                            color="#036358"
                                        >
                                            <v-btn class="mr-1" x-small v-if="hover && !trustedPanel" fab color="grey" bottom left @click="deleteMedia(media.id)"><v-icon color="white">mdi-delete</v-icon></v-btn>
                                            <a v-auth-href :href="media.url" v-if="hover">
                                                <v-btn x-small class="mr-1" fab color="grey"><v-icon color="white">mdi-cloud-download</v-icon></v-btn>
                                            </a>
<!--                                            <v-btn x-small class="mr-1" v-if="hover && !trustedPanel" fab color="primary" @click="getMediaTrustedUsers(media)" :loading="loading == media.id" :disabled="loading"><v-icon color="white">mdi-key-variant</v-icon></v-btn>-->
                                            <v-btn x-small class="mr-1" v-if="hover" fab color="grey" @click="openImage(media)"><v-icon color="white">mdi-magnify</v-icon></v-btn>
                                        </v-overlay>
                                    </v-fade-transition>
                                    
                                        
                                    <template v-slot:placeholder>
                                        <v-row
                                            class="fill-height ma-0"
                                            align="center"
                                            justify="center"
                                        >
                                            <v-progress-circular
                                                indeterminate
                                                color="primary"
                                            ></v-progress-circular>
                                        </v-row>
                                    </template>
                                </v-img>
                            </v-hover>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="pa-1" v-if="medias.length ? (medias[0].mime_type.startsWith('image') && medias.length > 10) : false" cols="12">
                    <v-row>                   
                        <v-col
                            v-for="media in medias.slice(10)" :key="media.id"
                            class="d-flex child-flex"
                            cols="4"
                        >
                            <v-hover v-slot="{ hover }">
                                <v-img
                                    :src="media.display_url"
                                    aspect-ratio="1.7778"
                                    class="white"
                                >                    
                                    <v-fade-transition>
                                        <v-overlay
                                            v-if="hover"
                                            absolute
                                            color="#036358"
                                        >
                                            <v-btn class="mr-1" x-small v-if="hover && !trustedPanel" fab color="grey" bottom left @click="deleteMedia(media.id)"><v-icon color="white">mdi-delete</v-icon></v-btn>
                                            <a v-auth-href :href="media.url" v-if="hover">
                                                <v-btn x-small class="mr-1" fab color="grey"><v-icon color="white">mdi-cloud-download</v-icon></v-btn>
                                            </a>
<!--                                            <v-btn x-small class="mr-1" v-if="hover && !trustedPanel" fab color="primary" @click="getMediaTrustedUsers(media)" :loading="loading == media.id" :disabled="loading"><v-icon color="white">mdi-key-variant</v-icon></v-btn>-->
                                            <v-btn x-small class="mr-1" v-if="hover" fab color="grey" @click="openImage(media)"><v-icon color="white">mdi-magnify</v-icon></v-btn>
                                        </v-overlay>
                                    </v-fade-transition>

                                    <template v-slot:placeholder>
                                        <v-row
                                            class="fill-height ma-0"
                                            align="center"
                                            justify="center"
                                        >
                                            <v-progress-circular
                                                indeterminate
                                                color="primary"
                                            ></v-progress-circular>
                                        </v-row>
                                    </template>
                                </v-img>
                            </v-hover>
                        </v-col>
                    </v-row>
                </v-col>

                <!-- Videos -->
                <v-col v-if="medias.length ? medias[0].mime_type.startsWith('video') : false" cols="12" md="6">
                    <v-row class="pos-abs zindex-100">
                        <v-btn class="ml-2 mt-2" x-small v-if="!trustedPanel" fab color="grey" bottom left @click="deleteMedia(medias[0].id)"><v-icon color="white">mdi-delete</v-icon></v-btn>
                        <a v-auth-href :href="medias[0].url"><v-btn x-small class="mt-2" fab color="grey"><v-icon color="white">mdi-cloud-download</v-icon></v-btn></a>
<!--                        <v-btn x-small class="mt-2" v-if="!trustedPanel" fab color="primary" @click="getMediaTrustedUsers(medias[0])" :loading="loading == medias[0].id" :disabled="loading"><v-icon color="white">mdi-key-variant</v-icon></v-btn>-->
                    </v-row>
                    <video controls class="videoInsert">
                        <source :src="medias[0].display_url" :type="medias[0].mime_type">                           
                    </video>                    
                </v-col>
                <v-col v-if="medias.length ? medias[0].mime_type.startsWith('video') : false" cols="12" md="6">
                    <v-row>                   
                        <v-col
                            v-for="media in medias.slice(1)" :key="media.id"
                            class="d-flex child-flex"
                            cols="6"
                        >
                            <video controls class="videoInsert" height="200">
                                <source :src="media.display_url" :type="media.mime_type">                           
                            </video>
                            <v-row class="pos-abs zindex-100">
                                <v-btn class="ml-2 mt-2" x-small v-if="!trustedPanel" fab color="grey" bottom left @click="deleteMedia(media.id)"><v-icon color="white">mdi-delete</v-icon></v-btn>
                                <a v-auth-href :href="media.url"><v-btn x-small class="mt-2" fab color="grey"><v-icon color="white">mdi-cloud-download</v-icon></v-btn></a>
<!--                                <v-btn x-small class="mt-2" v-if="!trustedPanel" fab color="primary" @click="getMediaTrustedUsers(media)" :loading="loading == media.id" :disabled="loading"><v-icon color="white">mdi-key-variant</v-icon></v-btn>-->
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>

                <!-- Audios -->
                <v-col v-if="medias.length ? medias[0].mime_type.startsWith('audio') : false" cols="12">
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">#</th>
                                    <th class="text-left">{{ $t('play') }}</th>
                                    <th class="text-left">{{ $t('name') }}</th>
                                    <th class="text-left">{{ $t('description') }}</th>
                                    <th class="text-left">{{ $t('size') }}</th>
                                    <th class="text-left">{{ $t('date') }}</th>
                                    <th class="text-center">{{ $t('actions') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(media, i) in medias" :key="media.id">
                                    <td>{{ i + 1 }}</td>
                                    <th class="py-1">
                                        <audio controls>
                                            <source :src="media.display_url" :type="media.mime_type">
                                        </audio>
                                    </th>
                                    <td>{{ media.name }}</td>
                                    <td>{{ media.desc }}</td>
                                    <td>{{ getSize(media.size) }}</td>
                                    <td>{{ media.created_at }}</td>
                                    <td class="text-center">
                                        <v-btn class="ml-2" x-small v-if="!trustedPanel" fab color="grey" bottom left @click="deleteMedia(media.id)"><v-icon color="white">mdi-delete</v-icon></v-btn>
                                        <a v-auth-href :href="media.url"><v-btn x-small class="ml-2" fab color="grey"><v-icon color="white">mdi-cloud-download</v-icon></v-btn></a>
<!--                                        <v-btn x-small class="ml-2" v-if="!trustedPanel" fab color="primary" @click="getMediaTrustedUsers(media)" :loading="loading == media.id" :disabled="loading"><v-icon color="white">mdi-key-variant</v-icon></v-btn>-->
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>

                <!-- Documents -->
                <v-col v-if="medias.length ? medias[0].mime_type.startsWith('application') : false" cols="12">
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">#</th>
                                    <th class="text-left">{{ $t('name') }}</th>
                                    <th class="text-left">{{ $t('description') }}</th>
                                    <th class="text-left">{{ $t('size') }}</th>
                                    <th class="text-left">{{ $t('date') }}</th>
                                    <th class="text-center">{{ $t('actions') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(media, i) in medias" :key="media.id">
                                    <td>{{ i + 1 }}</td>
                                    <td>{{ media.name }}</td>
                                    <td>{{ media.desc }}</td>
                                    <td>{{ getSize(media.size) }}</td>
                                    <td>{{ media.created_at }}</td>
                                    <td class="text-center">
                                        <v-btn class="ml-2" x-small v-if="!trustedPanel" fab color="grey" bottom left @click="deleteMedia(media.id)"><v-icon color="white">mdi-delete</v-icon></v-btn>
                                        <a v-auth-href :href="media.url"><v-btn x-small class="ml-2" fab color="grey"><v-icon color="white">mdi-cloud-download</v-icon></v-btn></a>
<!--                                        <v-btn x-small class="ml-2" v-if="!trustedPanel" fab color="primary" @click="getMediaTrustedUsers(media)" :loading="loading == media.id" :disabled="loading"><v-icon color="white">mdi-key-variant</v-icon></v-btn>-->
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>

                <!-- Add New File Button -->
                <transition name="slide" mode="out-in">
                    <v-col v-if="!trustedPanel && (($route.params.collection == 'wunsch' && (categories ? categories.length : false)) || $route.params.collection != 'wunsch')" justify="center" align="center" cols="12" class="mt-10 mb-16">
                        <router-link v-if="$route.params.collection == 'wunsch'" :to="{name: $route.params.type == 'documents' ? 'newDocument' : 'newMedia', params:{ category_id: category, type: $route.params.type }}">
                            <v-icon x-large color="primary">mdi-cloud-upload-outline</v-icon>
                        </router-link>
                        <router-link v-else :to="{name: $route.params.type == 'documents' ? 'newDocument' : 'newMedia', params: { type: $route.params.type }}">
                            <v-icon x-large color="primary">mdi-cloud-upload-outline</v-icon>
                        </router-link>
                        <p class="text-subtitle-1 mt-5">{{ $t('upload_new_file') }}</p>
                    </v-col>
                    <v-col v-else-if="trustedPanel && !medias.length" class="mt-10 mb-16" justify="center" align="center" cols="12">
                        <v-icon x-large color="grey">mdi-alert-rhombus-outline</v-icon>
                        <h1 class="mt-5 text--grey text-subtitle-2">{{ $t('no_file') }}</h1>
                    </v-col>
                </transition>
            </v-row>
            <v-row v-else class="mb-5 mt-10" :key="0">
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
            </v-row>
        </transition>

        <!-- Delete Media Confirm Dialog -->
        <v-dialog
            v-model="deleteConformDialog"
            persistent
            max-width="290"
        >
            <v-card>
                <v-card-title class="text-h5">
                    {{ $t('are_you_sure') }}
                </v-card-title>

                <v-card-text>
                    {{ $t('delete_file_alert') }}
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="error darken-1"
                        text
                        @click="deleteMedia(selectedMedia)"
                    >
                        Yes
                    </v-btn>

                    <v-btn
                        color="primary darken-1"
                        text
                        @click="deleteConformDialog = false; selectedMedia = null;"
                    >
                        No
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Media Trusted Users Dialog -->
        <v-dialog
            v-model="mediaTrustedUsersDialog"
            max-width="900"
        >
            <v-card>
                <v-card-title class="text-h5">
                    {{ $t('trusted_dialog') }}
                </v-card-title>

                <v-card-text align="center" justify="center">
                    {{ $t('accessed_users_to_file') }}
                </v-card-text>

                <v-card-text v-if="mediaTrustedUsers">
                    <v-row v-if="mediaTrustedUsers.length">
                        <v-col cols="12" md="4" v-for="user in mediaTrustedUsers" :key="user.id">
                            <v-card elevation="5">
                                <v-card-text align="center" justify="center">
                                    <v-avatar size="70">
                                        <img :src="user.avatar ? user.avatar : '/assets/img/avatar.png'">
                                    </v-avatar>
                                </v-card-text>
                                <v-card-text align="center" justify="center">
                                    <div class="text-subtitle-1">{{ user.firstname }} {{ user.name }}</div>
                                    <div class="text-caption">@{{ user.username ? user.username : '----' }}</div>
                                </v-card-text>
                                <v-card-text align="left">
                                    <div class="text-caption"><v-icon class="mr-2">mdi-clock-outline</v-icon>Start at: {{ user.starts_at ? user.starts_at : 'From Death' }}</div>
                                    <div class="text-caption"><v-icon class="mr-2">mdi-clock-outline</v-icon>Expired at: {{ user.expires_at ? user.expires_at : 'To The End' }}</div>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn
                                        color="grey"
                                        text
                                        fab
                                        :disabled="deleteMediaTrustedUserLoading"
                                        :loading="deleteMediaTrustedUserLoading == user.id"
                                        @click="deleteMediaTrustedUser(user.id)"
                                    >
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-divider class="mt-5 mb-5"></v-divider>
                    <v-row>
                        <v-col justify="center" align="center" cols="12">
                            <v-row>
                                <v-col cols="12">
                                    <span class="text-subtitle-1">{{ $t('add_new') }}</span>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-select
                                        hide-details
                                        :disabled="addMediaTrustedLoading || newMediaTrustedGroup"
                                        v-model="newMediaTrustedUser"
                                        :items="userItems"
                                        :label="$t('trusted_user')"
                                        prepend-icon='mdi-account-group'
                                        color="primary"
                                        outlined
                                        clearable
                                        light
                                        @click="newMediaTrustedGroup = null"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-select
                                        hide-details
                                        :disabled="addMediaTrustedLoading || newMediaTrustedUser"
                                        v-model="newMediaTrustedGroup"
                                        :items="groupItems"
                                        :label="$t('trusted_group')"
                                        prepend-icon='mdi-account-group'
                                        color="primary"
                                        outlined
                                        clearable
                                        light
                                        @click="newMediaTrustedUser = null"
                                    ></v-select>
                                </v-col>
                                <!-- Starts From -->
                                <v-col cols="12" md="6">
                                    <v-menu
                                        ref="start_at"
                                        v-model="starts_date"
                                        :return-value.sync="date"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="newMediaTrustedDate.start_at"
                                            :label="$t('start_at')"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            outlined
                                            clearable
                                            :hint="$t('start_at_hint')"
                                            persistent-hint
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="newMediaTrustedDate.start_at"
                                            no-title
                                            scrollable
                                        >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="starts_date = false"
                                        >
                                            {{ $t('close') }}
                                        </v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <!-- Expired To -->
                                <v-col cols="12" md="6">
                                    <v-menu
                                        ref="expire_at"
                                        v-model="expired_date"
                                        :return-value.sync="date"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="newMediaTrustedDate.expire_at"
                                            :label="$t('expire_at')"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            outlined
                                            color="primary"
                                            v-bind="attrs"
                                            v-on="on"
                                            clearable
                                            :hint="$t('expire_at_hint')"
                                            persistent-hint
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="newMediaTrustedDate.expire_at"
                                            no-title
                                            scrollable
                                        >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="expired_date = false"
                                        >
                                            {{ $t('close') }}
                                        </v-btn>
                                       
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" align="center" justify="center">
                                    <v-btn
                                        color="white"
                                        elevation="5"
                                        :loading="addMediaTrustedLoading"
                                        @click="addMediaTrusted"
                                        :disabled="!newMediaTrustedUser && !newMediaTrustedGroup"
                                        large
                                        class="px-10"
                                    >
                                        {{ $t('add') }}<v-icon color="primary darken-1 ml-2">mdi-plus</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary darken-1"
                        text
                        @click="mediaTrustedUsersDialog = false;"
                    >
                        {{ $t('close') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Create New Category -->
        <v-dialog
            v-model="newCategoryDialog"
            max-width="500"
        >
            <v-card>
                <v-card-title class="text-h5">
                    {{ $t('create_new_category') }}:
                </v-card-title>

                <v-card-text align="center" justify="center">
                    <v-row class="mt-5">
                        <v-col cols="12" md="8">
                            <v-text-field
                                outlined
                                :label="$t('category_name')"
                                maxlength="200"
                                counter
                                :disabled="newCategoryLoading"
                                :loading="newCategoryLoading"
                                v-model="newCategory.name"
                                @keyup.enter="newCategory ? (newCategory.id ? updateCategory() : addNewCategory()) : null"
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-btn
                                color="white"
                                elevation="5"
                                fab
                                :disabled="!newCategory || newCategoryLoading"
                                :loading="newCategoryLoading"
                                @click="newCategory.id ? updateCategory() : addNewCategory()"
                            >
                                <v-icon color="primary darken-1" v-if="newCategory.id">mdi-check</v-icon>
                                <v-icon color="primary darken-1" v-else>mdi-plus</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    
                </v-card-text>                

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary darken-1"
                        text
                        @click="newCategoryDialog = false;"
                    >
                        {{ $t('close') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Delete Category Confirm Dialog -->
        <v-dialog
            v-model="deleteCategoryConfirmDialog"
            persistent
            max-width="290"
        >
            <v-card>
                <v-card-title class="text-h5">
                    {{ $t('are_you_sure') }}
                </v-card-title>

                <v-card-text>
                    {{ $t('delete_category_alert') }}
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="error darken-1"
                        text
                        @click="deleteCategory"
                    >
                        {{ $t('yes') }}
                    </v-btn>

                    <v-btn
                        color="primary darken-1"
                        text
                        @click="deleteCategoryConfirmDialog = false; deleteCategoryLoading = false;"
                    >
                        {{ $t('no') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Payment Required Dialog -->
        <v-dialog
            v-model="paymentRequireDialog"
            max-width="450"
            persistent
        >
            <v-card>
                <v-card-title class="text-h5">
                    {{ $t('need_payment') }}
                </v-card-title>

                <v-card-text>
                    {{ $t('need_payment_desc') }}
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="error darken-1"
                        text
                        @click="$router.push({ name: 'profileInfo' })"
                    >
                        {{ $t('later') }}
                    </v-btn>

                    <v-btn
                        color="primary darken-1"
                        text
                        @click="$router.push({ name: 'profilePlans' })"
                    >
                        {{ $t('buy_now') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Image Dialog -->
        <v-dialog
            v-model="imageDialog"
            transition="dialog-bottom-transition"
            max-width="60%"
            :scrollable="false"
        >
            <v-card>
                <v-card-text>
                    <v-img contain :src="imageDialogContent ? imageDialogContent.display_url : ''" max-height="100%">
                        <template v-slot:placeholder>
                            <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                            >
                                <v-progress-circular
                                    indeterminate
                                    color="primary"
                                ></v-progress-circular>
                            </v-row>
                        </template>
                    </v-img>
                </v-card-text>
                <v-card-actions class="justify-end" v-if="imageDialog">
                    <v-btn class="mr-1" x-small v-if="!trustedPanel" fab color="grey" bottom left @click="deleteMedia(imageDialogContent ? imageDialogContent.id : null)"><v-icon color="white">mdi-delete</v-icon></v-btn>
                    <a v-auth-href :href="imageDialogContent.url">
                        <v-btn x-small class="mr-1" fab color="grey"><v-icon color="white">mdi-cloud-download</v-icon></v-btn>
                    </a>
<!--                    <v-btn x-small class="mr-1" v-if="!trustedPanel" fab color="primary" @click="getMediaTrustedUsers(imageDialogContent ? imageDialogContent : null)" :loading="loading" :disabled="loading"><v-icon color="white">mdi-key-variant</v-icon></v-btn>-->

                    <v-btn
                        fab
                        x-small
                        color="grey"
                        @click="imageDialog = false; imageDialogContent = null;"
                    >
                        <v-icon color="white">mdi-close</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>

import HelpVideo from "../../../../../widgets/HelpVideo"

export default {
    components:{
      HelpVideo
    },
    data(){
        return {
            trustedPanel: false, // true: files of trusting panels 
            loading: true,
            medias : null,
            tab: 0,

            category: null,
            categories: null,
            newCategory: {
                id: null,
                name: null
            },
            newCategoryDialog: false,
            newCategoryLoading: false,
            deleteCategoryConfirmDialog: false,
            deleteCategoryLoading: false,

            fabLoader: false,
            deleteConformDialog: false,
            selectedMedia: null,

            downloadMediaLoading: false,
            deleteMediaLoading: false,
            newMediaTrustedUser: null,
            newMediaTrustedGroup: null,
            newMediaTrustedDate: {
                start_at: null,
                expire_at: null,
            },
            starts_date: false,
            expired_date: false,

            addMediaTrustedLoading: false,
            mediaTrustedUsersDialog: false,
            mediaTrustedUsers: null,
            mediaTrustedGroups: null,

            usersLoading: true,
            groupsLoading: true,
            users: null,
            groups: null,

            userItems: null,
            groupItems: null,

            downloadTresorMediaLoading: false,
            deleteTresorMediaLoading: false,
            getMediaTrustedUsersLoading: false,
            deleteMediaTrustedUserLoading: false,

            trustersMenu: false,
            trustersLoading: true,
            trusterUsers: null,
            truster: null,

            trustedMenuUsers: [],
            selectedTrustedUser: null,

            paymentRequireDialog: false,

            defaultCategories: [
                'Wie möchte ich beerdigt werden?',
                'Was soll es zu essen geben?',
                'Wie soll der Sarg/die Urne aussehen?',
                'Freunde aus UP einladen?',
            ],

            imageDialog: false,
            imageDialogContent: null,
        }
    },
    watch:{
        $route(to, from) {
            this.getMedias()
            if (from.params.collection != to.params.collection){
                this.getTrustedUsers()
                this.getTrustedGroups()
                this.getTrusters()
                if (this.$route.params.collection == 'wunsch') this.getCategories()
                this.trustedPanel = false
                this.loading = true
                this.medias  = null
                this.type = 'images'

                this.category = null
                this.categories = null
                this.newCategory = {
                    id: null,
                    name: null
                }
                this.newCategoryDialog = false
                this.newCategoryLoading = false
                this.deleteCategoryConfirmDialog = false
                this.deleteCategoryLoading = false

                this.fabLoader = false
                this.deleteConformDialog = false
                this.selectedMedia = null

                this.downloadMediaLoading = false
                this.deleteMediaLoading = false
                this.newMediaTrustedUser = null
                this.newMediaTrustedGroup = null
                this.newMediaTrustedDate = {
                    start_at: null,
                    expire_at: null,
                }
                this.starts_date = false
                this.expired_date = false

                this.addMediaTrustedLoading = false
                this.mediaTrustedUsersDialog = false
                this.mediaTrustedUsers = null
                this.mediaTrustedGroups = null

                this.usersLoading = true
                this.groupsLoading = true
                this.users = null
                this.groups = null

                this.userItems = null
                this.groupItems = null

                this.downloadTresorMediaLoading = false
                this.deleteTresorMediaLoading = false
                this.getMediaTrustedUsersLoading = false
                this.deleteMediaTrustedUserLoading = false

                this.trustersMenu = false
                this.trustersLoading = true
                this.trusterUsers = null
                this.truster = null

                this.trustedMenuUsers = []
                this.selectedTrustedUser = null

                this.paymentRequireDialog = false

                this.defaultCategories = [
                    'Wie möchte ich beerdigt werden?',
                    'Was soll es zu essen geben?',
                    'Wie soll der Sarg/die Urne aussehen?',
                    'Freunde aus UP einladen?',
                ]

                this.imageDialog = false
                this.imageDialogContent = null
            }
        },
        '$route.params.collection'(){
            this.$router.go({name : 'mediaIndex', params:{ collection: this.$route.params.collection, type: 'images' }})
        }
    },
    mounted(){
        if (!['images', 'videos', 'documents', 'sounds'].includes(this.$route.params.type)) {
            this.$router.push({name: 'dashboard/profile/info'})
            return
        }
        this.type = this.$route.params.type
        switch (this.$route.params.type) {
            case 'images':
                this.tab = 0
                break;
            case 'documents':
                this.tab = 1
                break;
            case 'videos':
                this.tab = 2
                break;
            case 'sounds':
                this.tab = 3
                break;
        }
        this.getMedias()
        this.getTrustedUsers()
        this.getTrustedGroups()
        this.getTrusters()
        if (this.$route.params.collection == 'wunsch') this.getCategories()
    },
    methods: {
        getMedias(){
            if (this.trustedPanel) {
                this.getConfidingMedias()
                return
            }
            this.loading = true
            var type = null
            if (this.$route.params.type != 'all') type = this.$route.params.type
            if (type == 'documents') {
                this.$store.dispatch('getDocuments', {
                    collection: this.$route.params.collection,
                    category: this.$route.params.collection == 'wunsch' ? this.category : null,
                })
                .then(resp => {
                    this.medias = resp
                    this.loading = false
                })
                .catch (err => {
                    this.loading = false
                    if (err.response.status === 402) this.paymentRequireDialog = true
                    else this.$helpers.showError(err)
                })
            }
            else {
                this.$store.dispatch('getMedias', {
                    collection: this.$route.params.collection, 
                    type: type,
                    category: this.$route.params.collection == 'wunsch' ? this.category : null
                })
                .then(resp => {
                    this.medias = resp
                    this.loading = false
                })
                .catch (err => {
                    this.loading = false
                    if (err.response.status === 402) this.paymentRequireDialog = true
                    else this.$helpers.showError(err)
                })
            }
        },

        deleteMedia(id){
            if (!this.selectedMedia || !this.deleteConformDialog){
                this.selectedMedia = id
                this.deleteConformDialog = true                
            }
            else {
                this.deleteMediaLoading = id
                this.fabLoader = id
                this.deleteConformDialog = false
                this.$store.dispatch('deleteMedia', id)
                .then(resp => {
                    this.fabLoader = false
                    this.deleteMediaLoading = false 
                    this.selectedMedia = null
                    this.getMedias()
                })
                .catch (err => {
                    this.deleteMediaLoading = false
                    this.fabLoader = false
                    if (err.response.status === 402) this.paymentRequireDialog = true
                    else this.$helpers.showError(err)
                })
            }
        },

        downloadMedia(media){
            this.downloadMediaLoading = media.id
            var element = document.createElement('a');
            element.setAttribute('href', media.display_url);
            element.setAttribute('download', media.name+ '.'+ (media.mime_type).replace("image/", ""));
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
            this.downloadMediaLoading = false
        },

        checkMediaType(media, type){
            if (type == 'image') {
                if (media.startsWith('image')) return true
                return false
            }

            if (type == 'video') {
                if (media.startsWith('video')) return true
                return false
            }

            if (type == 'audio') {
                if (media.startsWith('audio')) return true
                return false
            }

            return false
        },

        getMediaTrustedUsers(media){
            this.getMediaTrustedUsersLoading = media.id               

            if (this.$route.params.type == 'documents') {
                this.$store.dispatch('getDocumentTrustedUsers', media.id)
                .then(resp => {
                    this.mediaTrustedUsers = resp
                    this.mediaTrustedUsersDialog = true
                    this.getMediaTrustedUsersLoading = false
                    this.selectedMedia = media
                })
                .catch (err => {
                    this.getMediaTrustedUsersLoading = false
                    if (err.response.status === 402) this.paymentRequireDialog = true
                    else this.$helpers.showError(err)
                    this.selectedMedia = null
                })
            }
            else {
                this.$store.dispatch('getMediaTrustedUsers', media.id)
                .then(resp => {
                    this.mediaTrustedUsers = resp
                    this.mediaTrustedUsersDialog = true
                    this.getMediaTrustedUsersLoading = false
                    this.selectedMedia = media
                })
                .catch (err => {
                    this.getMediaTrustedUsersLoading = false
                    if (err.response.status === 402) this.paymentRequireDialog = true
                    else this.$helpers.showError(err)
                    this.selectedMedia = null
                })
            }
        },

        getSize(size){
            let s = Math.floor(size / 1024)
            let format = 'KB'
            if (s >= 1024) {
                s = Math.floor(s / 1024)
                format = 'MB'
            }
            if (s >= 1024) {
                s = Math.floor(s / 1024)
                format = 'GB'
            }
            return `${s} ${format}`
        },

        getConfidingMedias(){
            if (!this.trustedPanel) {
                this.getMedias()
                return
            }
            this.loading = true
            var type = null
            if (this.$route.params.type != 'all') type = this.$route.params.type
            if (type == 'documents') {
                this.$store.dispatch('getConfidingDocuments', {collection: this.$route.params.collection, truster_id: this.trustedPanel.id})
                .then(resp => {
                    this.medias = resp
                    this.loading = false
                })
                .catch (err => {
                    this.loading = false
                    if (err.response.status === 402) this.paymentRequireDialog = true
                    else this.$helpers.showError(err)
                })
            }
            else {
                this.$store.dispatch('getConfidingMedias', {collection: this.$route.params.collection, type: type, truster_id: this.trustedPanel.id})
                .then(resp => {
                    this.medias = resp
                    this.loading = false
                })
                .catch (err => {
                    this.loading = false
                    if (err.response.status === 402) this.paymentRequireDialog = true
                    else this.$helpers.showError(err)
                })
            }
        },

        getTrusters(){
            this.trustersLoading = true
            this.$store.dispatch('getTrusterUsers')
            .then(resp => {
                this.trusterUsers = resp
                this.trustersLoading = false
                let items = []
                for (let index = 0; index < resp.length; index++) {
                    const user = resp[index];
                    if (user.status != 'requested') items.push(user)
                }
                this.trustedMenuUsers = items
            })
        },

        getTrustedUsers(){
            this.usersLoading = true
            this.$store.dispatch('getTrustedUsers')
            .then(resp => {
                this.users = resp
                this.usersLoading = false
                let items = []
                for (let index = 0; index < resp.length; index++) {
                    const user = resp[index];
                    items.push({text: user.name, value: user.id})
                }
                this.userItems = items
            })
        },

        getTrustedGroups(){
            this.groupsLoading = true
            this.$store.dispatch('getTrustedGroups')
            .then(resp => {
                this.groups = resp
                this.groupsLoading = false
                let items = []
                for (let index = 0; index < resp.length; index++) {
                    const group = resp[index];
                    items.push({text: group.name, value: group.id})
                }
                this.groupItems = items
            })
        },

        addMediaTrusted(){
            this.addMediaTrustedLoading = true

            let data = null
            if (this.newMediaTrustedUser) data = { 
                id: this.selectedMedia.id, 
                trusted_users: [this.newMediaTrustedUser],
                start_at: this.newMediaTrustedDate.start_at ? this.newMediaTrustedDate.start_at.replaceAll('-', '/') : null,
                expire_at: this.newMediaTrustedDate.expire_at ? this.newMediaTrustedDate.expire_at.replaceAll('-', '/') : null,
            }
            else data = { 
                id: this.selectedMedia.id, 
                trusted_groups: [this.newMediaTrustedGroup],
                start_at: this.newMediaTrustedDate.start_at ? this.newMediaTrustedDate.start_at.replaceAll('-', '/') : null,
                expire_at: this.newMediaTrustedDate.expire_at ? this.newMediaTrustedDate.expire_at.replaceAll('-', '/') : null,
            }

            if (this.$route.params.type == 'documents') {
                this.$store.dispatch('setDocumentTrustedUsers', data)
                .then(resp => {
                    this.getMediaTrustedUsers(this.selectedMedia)
                    this.newMediaTrustedUser = null
                    this.newMediaTrustedGroup = null
                    this.newMediaTrustedDate = {
                        start_at: null,
                        expire_at: null,
                    }
                    this.addMediaTrustedLoading = false
                })
                .catch (err => {
                    this.addMediaTrustedLoading = false
                    if (err.response.status === 402) this.paymentRequireDialog = true
                    else this.$helpers.showError(err)
                })
            }
            else {
                this.$store.dispatch('setMediaTrustedUsers', data)
                .then(resp => {
                    this.getMediaTrustedUsers(this.selectedMedia)
                    this.newMediaTrustedUser = null
                    this.newMediaTrustedGroup = null
                    this.addMediaTrustedLoading = false
                })
                .catch (err => {
                    this.addMediaTrustedLoading = false
                    if (err.response.status === 402) this.paymentRequireDialog = true
                    else this.$helpers.showError(err)
                })
            }
        },

        deleteMediaTrustedUser(id){
            if (confirm('Are you sure?')){
                if (this.$route.params.type == 'documents') {
                    this.deleteMediaTrustedUserLoading = id
                    this.$store.dispatch('deleteDocumentTrustedUsers', { id: this.selectedMedia.id, trusted_users: [id] })
                    .then(resp => {
                        this.deleteMediaTrustedUserLoading = false
                        this.getMediaTrustedUsers(this.selectedMedia)
                    })
                    .catch (err => {
                        this.deleteMediaTrustedUserLoading = false
                        if (err.response.status === 402) this.paymentRequireDialog = true
                        else this.$helpers.showError(err)
                    })
                }
                else {
                    this.deleteMediaTrustedUserLoading = id
                    this.$store.dispatch('deleteMediaTrustedUsers', { id: this.selectedMedia.id, trusted_users: [id] })
                    .then(resp => {
                        this.deleteMediaTrustedUserLoading = false
                        this.getMediaTrustedUsers(this.selectedMedia)
                    })
                    .catch (err => {
                        this.deleteMediaTrustedUserLoading = false
                        if (err.response.status === 402) this.paymentRequireDialog = true
                        else this.$helpers.showError(err)
                    })
                }
            }
        },

        changePanel(user){
            this.trustersMenu = false
            if (!user) this.trustedPanel = false
            else this.trustedPanel = user

            // Get Confings Media
            if (this.trustedPanel) this.getConfidingMedias()
            // Get My Medias
            else this.getMedias()
        },

        getCategories(){
            this.loading = true
            this.$store.dispatch('getCategories', 'medias')
            .then(resp => {
                this.categories = resp
                if (resp.length && !this.category) this.category = resp[0].id

                // Create Default Categories
                if (!resp.length){
                    for (let index = 0; index < this.defaultCategories.length; index++) {
                        const cat = this.defaultCategories[index];
                        this.$store.dispatch('storeCategories', { 
                            type: 'medias', 
                            name: cat
                        })
                        if (index == this.defaultCategories.length - 1) this.getCategories()
                    }
                }
                else {
                    this.loading = false
                }
            })
        },

        addNewCategory(){
            this.newCategoryLoading = true
            this.$store.dispatch('storeCategories', { 
                type: 'medias', 
                name: this.newCategory.name
            })
            .then(resp => {
                this.newCategory = {
                    id: null,
                    name: null
                }
                this.newCategoryLoading = false
                this.newCategoryDialog = false
                this.getCategories()
            })
            .catch (err => {
                this.newCategoryLoading = false
                if (err.response.status === 402) this.paymentRequireDialog = true
                else this.$helpers.showError(err)
            })
        },

        updateCategory(){
            this.newCategoryLoading = true
            this.$store.dispatch('updateCategories', this.newCategory)
            .then(resp => {
                this.newCategory = {
                    id: null,
                    name: null
                }
                this.newCategoryLoading = false
                this.newCategoryDialog = false
                this.getCategories()
            })
            .catch (err => {
                this.newCategoryLoading = false
                if (err.response.status === 402) this.paymentRequireDialog = true
                else this.$helpers.showError(err)
            })
        },

        deleteCategory(){
            this.$store.dispatch('deleteCategories', {
                id: this.category,
                type: 'wunch'
            })
            .then(resp => {
                this.category = null
                this.deleteCategoryLoading = false
                this.deleteCategoryConfirmDialog = false
                this.getCategories()
            })
            .catch (err => {
                this.deleteCategoryLoading = false
                this.deleteCategoryConfirmDialog = false
                if (err.response.status === 402) this.paymentRequireDialog = true
                else this.$helpers.showError(err)
            })
        },

        openImage(media){
            this.imageDialogContent = media
            this.imageDialog = true
        }
    }
}
</script>