<template>
    <v-container class="auth-form">
        <v-row class="mt-1">
            <h1 class="auth-title">{{ $t('otp_title') }}</h1>    
            <h6>{{ $t('otp_setup') }}</h6>    
        </v-row>

        <v-row class="mt-8" v-if="secret">
            <v-col cols="12" >
                <v-card align="center">
                    <v-card-text v-html="secret.qrcode"></v-card-text>

                    <v-card-text>
                        <div>Secret Code:</div>
                        <div class="text-h5">{{ secret.secret }}</div>
                    </v-card-text>
                </v-card>
            </v-col>
            
            <v-text-field
                :label="$t('otp_code')"
                :disabled="loading"
                filled
                rounded
                :name="Math.random()"
                v-model="form.otp"
            ></v-text-field>
        </v-row>

        <v-row class="mt-8" v-if="tfa">
            <v-text-field
                :label="$t('otp_code')"
                :disabled="loading"
                filled
                rounded
                autofocus
                :name="Math.random()"
                v-model="form.otp"
                @keyup.enter="verify"
            ></v-text-field>
        </v-row>
       
        <v-row class="mt-2" justify="center">
            <v-btn
                :loading="loading"
                :disabled="loading || !form.otp"
                color="white"
                block
                rounded
                @click="verify"
            >

              Weiter
<!--                <v-icon light>-->
<!--                    mdi-lock-->
<!--                </v-icon>-->
            </v-btn>
        </v-row>
    </v-container>
</template>

<script>
import Vue from "vue";

export default {
    data(){
        return {
            loading:false,
            form: {
                otp: null
            },
            secret: null,
            tfa: false
        }
    },
    mounted(){
        if (!this.$route.params.action) this.$router.push({name: 'login'})
        else if (this.$route.params.action.action == 'REGISTER_OTP') this.getSecret()
        else this.tfa = true
    },
    methods: {
        getSecret(){
            this.$store.dispatch('getOtpSecret', this.$route.params.action.token)
            .then(resp => {
                this.secret = resp
            })
            .catch(err => {
                this.$helpers.showError(err)
            })
        },
        verify(){
            if (!this.form.otp){
                Vue.$toast.error(this.$t('enter_otp'))
                return
            }
        
            this.loading = true
            this.$store.dispatch(this.tfa ? 'loginOtp' : 'verifyOtp', {
                otp: this.form.otp,
                secret: this.tfa ? null : this.secret.secret,
                token: this.$route.params.action.token
            })
            .then(resp => {
                Vue.$toast.success(this.$t('success_login'))
                window.location.href = '/dashboard/profile/info'
                // console.log(this.$route.params.id);
                // if (this.$route.params.id)
                //     this.$router.push({ name: 'profilePlans', params:{ id: this.$route.params.id }})
                // else if (this.$route.params.redirect)
                //     window.location = this.$route.params.redirect
                // else
                //     this.$router.go({name: 'profile'})
            })
            .catch(err => {
                console.log(err);
                this.$helpers.showError(err)
                this.loading= false
            })
        }
    }
}
</script>