<template>
    <section>
      <Header @open-login-modal="showLogin = true" @open-register-modal="showRegister = true" />
      <v-container>
        <TextWithImage
            :cols="[8,4]"
            image-col="2"
            title="Ihre Daten sind bei uns sicher"
            text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore"
            image="/assets/img/home/5.png"
            image-width="500"
            button=""
        >
        </TextWithImage>
      </v-container>

      <Footer></Footer>

      <login-dialog @close-login-modal="showLogin = false" @open-register-modal="showRegister = true" v-model="showLogin"></login-dialog>
      <register-dialog @close-register-modal="showRegister = false" @open-login-modal="showLogin = true" v-model="showRegister"></register-dialog>

    </section>
</template>

<script>
import TextWithImage from "../../widgets/TextWithImage.vue";
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import LoginDialog from "../../widgets/LoginDialog.vue";
import RegisterDialog from "../../widgets/RegisterDialog.vue";

export default {
    components:{
      TextWithImage,
      Header,
      Footer,
      LoginDialog,
      RegisterDialog
    }, 
    data(){
        return {
          showLogin: false,
          showRegister: false
        }
    },
    created() {
      this.setMetaTags()
    },
    mounted() {
    },
    methods: {

    }
}
</script>