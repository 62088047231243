<template>
    <v-container class="auth-form">
        <v-row class="mt-1">
            <h1 class="auth-title">{{ $t('recover_password') }}</h1>    
        </v-row>

        <v-row class="mt-10" v-if="!$route.params.token">
            <v-text-field
                :label="$t('email')"
                :disabled="loading"
                filled
                rounded
                v-model="form.email"
                @keyup.enter="reset"
            ></v-text-field>
        </v-row>

        <v-row class="mt-10" v-if="$route.params.token">
            <v-text-field 
                filled
                rounded
                :placeholder="$t('new_password')"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                :hint="$t('password_hint')"
                v-model="form.password" 
                @click:append="show1 = !show1"
            ></v-text-field>
        </v-row>

        <v-row class="mt-10" v-if="$route.params.token">
            <v-text-field 
                filled
                rounded
                :placeholder="$t('confirm_new_password')"
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show2 ? 'text' : 'password'"
                :hint="$t('password_hint')"
                v-model="form.password_confirmation" 
                @click:append="show2 = !show2"
            ></v-text-field>
        </v-row>
       
        <v-row class="mt-2" justify="center">
            <v-btn
                :loading="loading"
                :disabled="loading"
                color="white"
                class="ma-2"
                fab
                @click="$route.params.token ? setNewPassword() : reset()"
            >
                <v-icon light>
                    mdi-lock
                </v-icon>
            </v-btn>
        </v-row>
        <v-row class="mt-2">
            <v-col justify="center" align="center">
                <p><router-link :to="{name: 'login'}" class="primary--text">{{ $t('login') }}</router-link> {{ $t('with_your_account') }}</p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Vue from "vue";

export default {
    data(){
        return {
            loading:false,
            form: {},
            show1: false,
            show2: false
        }
    },
    methods: {
        reset(){
            if (!this.form.email){
                Vue.$toast.error(this.$t('enter_email'))
                return
            }
            this.loading = true
            this.$store.dispatch('resetPassword', this.form)
            .then(resp => {
                Vue.$toast.success(this.$t('recover_send_reset_email'))
                this.form = {}
                this.$router.push({name: 'login'})
                this.loading = false
            })
            .catch(err => {
                this.$helpers.showError(err)
                this.loading= false
            })
        },

        setNewPassword(){
            this.loading = true
            this.form.token = this.$route.params.token
            this.$store.dispatch('setNewPassword', this.form)
            .then(resp => {
                Vue.$toast.success(this.$t('password_changed'))
                this.$router.push({name: 'login'})
            })
            .catch(err => {
                this.$helpers.showError(err)
                this.loading= false
            })
        }
    }
}
</script>