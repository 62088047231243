<template>
    <v-container fluid tag="section" class="pt-3 px-8 pb-8">
        <!-- Head Buttons -->
        <v-row >
            <v-col cols="12" class="pt-3 pl-8 pr-8 pb-8">
              <p class="text-h5 main-font-bold pb-7"><span class="pr-5">{{ $t('documenttemps') }}</span> <HelpVideo video="dokumente" title="Dokumentvorlagen"></HelpVideo></p>
                <p class="text-subtitle-1" v-html="$t('documenttemps_subtitle')"></p>
            </v-col>        
        </v-row>

        <!-- Medias -->
        <transition name="slide" mode="out-in">
            <v-row v-if="!loading" :key="1">
                <v-col cols="12" v-if="files ? files.length : false">
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">#</th>
                                    <th class="text-left">{{ $t('name') }}</th>
<!--                                    <th class="text-left">{{ $t('description') }}</th>-->
<!--                                    <th class="text-left">{{ $t('size') }}</th>-->
<!--                                    <th class="text-left">{{ $t('date') }}</th>-->
                                    <th class="text-center">{{ $t('Download') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(file, i) in files" :key="file.id">
                                    <td>{{ i + 1 }}</td>
                                    <td>{{ file.name }}</td>
<!--                                    <td>{{ file.desc }}</td>-->
<!--                                    <td>{{ getSize(file) }}</td>-->
<!--                                    <td>{{ file.created_at }}</td>-->
                                    <td class="text-center">
                                        <a :href="file.url" target="_blank" download>
                                            <v-btn medium text fab color="primary"><v-icon>mdi-cloud-download</v-icon></v-btn>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
            <v-row v-else class="mb-5 mt-5" :key="0">
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
            </v-row>            
        </transition>        

        <!-- Payment Required Dialog -->
        <v-dialog
            v-model="paymentRequireDialog"
            max-width="450"
            persistent
        >
            <v-card>
                <v-card-title class="text-h5">
                    {{ $t('need_payment') }}
                </v-card-title>

                <v-card-text>
                    {{ $t('need_payment_desc') }}
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="error darken-1"
                        text
                        @click="$router.push({ name: 'profileInfo' })"
                    >
                        {{ $t('later') }}
                    </v-btn>

                    <v-btn
                        color="primary darken-1"
                        text
                        @click="$router.push({ name: 'profilePlans' })"
                    >
                        {{ $t('buy_now') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>

import HelpVideo from "../../../../widgets/HelpVideo"

export default {
    components: {
      HelpVideo
    },
    data(){
        return {
            loading: true,
            files : null,
            downloadFileLoading: false,
            paymentRequireDialog: false
        }
    },
    mounted(){
        this.getDocumentsTemplates()
    },
    methods: {
        getDocumentsTemplates(){
            this.loading = true
            this.$store.dispatch('getDocumenttemps')
            .then(resp => {
                this.files = resp
                this.loading = false
            })
            .catch(err => {
                this.loading = false
                if (err.response.status === 402) this.paymentRequireDialog = true
                else this.$helpers.showError(err)
            })
        },

        getSize(size){
          console.log(size)
            let s = Math.floor(size / 1024)
            let format = 'KB'
            if (s >= 1024) {
                s = Math.floor(s / 1024)
                format = 'MB'
            }
            if (s >= 1024) {
                s = Math.floor(s / 1024)
                format = 'GB'
            }
            return `${s} ${format}`
        },
    }
}
</script>