<template>
    <section>
      <Header @open-login-modal="showLogin = true" @open-register-modal="showRegister = true" />
      <v-container>
        <TextWithImage
            :cols="[8,4]"
            image-col="2"
            title="Geras24 - Eine Partnerschaft für das Leben"
            text="Geras24 ist der digitale Ort der Zukunft. Wir liefern den modernen Vertriebskanal für unsere Partner. Geras 24 bindet die Mitglieder ein Leben lang, deshalb sind Partnerschaften mit uns so wertvoll.

<br><br>Geras24 ist der perfekte Ort, um Versicherungen zu vertreiben auf eine angenehme Art. Wir sichern unsere Mitglieder ab und brauchen dich dazu als Partner. Wir haben viele Ideen, um Versicherungen transparent an den Mann zu bringen. Melde dich und lasse dich von unserer Mission inspirieren.

<br><br>Die Medien haben in den letzten 20 Jahren gemerkt, dass neue Vertriebswege nötig sind. Besonders die Presse hat immer mehr Einbußen durch das Internet. Geras24 ist der zuverlässige Partner, um genau diese beiden Dinge zu verbinden. Unsere Todesanzeigen gehen durch das ganze Land. Wir suchen dich als Partner, um eine Kooperation bei Todesanzeigen zu gestalten. Wir haben viel vor und gehen davon aus, dass du dabei sein willst. Schreibe uns an und wir erzählen dir unsere Vision.
"
            image="/assets/img/home/5.png"
            image-width="500"
            button="Kontaktiere uns"
            link="/kontakt"
        >
        </TextWithImage>
      </v-container>

      <Footer></Footer>

      <login-dialog @close-login-modal="showLogin = false" @open-register-modal="showRegister = true" v-model="showLogin"></login-dialog>
      <register-dialog @close-register-modal="showRegister = false" @open-login-modal="showLogin = true" v-model="showRegister"></register-dialog>

    </section>
</template>

<script>
import TextWithImage from "../../widgets/TextWithImage.vue";
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import LoginDialog from "../../widgets/LoginDialog.vue";
import RegisterDialog from "../../widgets/RegisterDialog.vue";

export default {
    components:{
      TextWithImage,
      Header,
      Footer,
      LoginDialog,
      RegisterDialog
    }, 
    data(){
        return {
          showLogin: false,
          showRegister: false
        }
    },
    created() {
      this.setMetaTags()
    },
    mounted() {
    },
    methods: {

    }
}
</script>