<template>
    <v-container
        fluid
        tag="section"
    >
        <v-row justify="center" class="pl-8 pr-8 pb-8 pt-3">
            <v-col cols="12">
                <p class="text-h5 main-font-bold main-title-color">{{ $t('keys_title') }}</p>
                <div class="subtitle-1 font-weight-light main-grey">
                    {{ $t('keys_subtitle1') }}
                </div>
                <div class="subtitle-1 font-weight-light main-grey">
                    {{ $t('keys_subtitle2') }}
                </div>
                <div class="subtitle-1 font-weight-light error--text">
                    <v-icon color="error">mdi-alert</v-icon> {{ $t('keys_subtitle3') }}
                </div>
                <div class="subtitle-1 font-weight-light error--text">
                    <v-icon color="error">mdi-alert</v-icon> {{ $t('keys_subtitle4') }}
                </div>
            </v-col>
            <v-col cols="12">
                <transition name="slide" mode="out-in">
                    <v-row v-if="!loading" class="mt-10 mb-10" :key="1">
                        <v-col cols="12">
                            <v-row v-if="pubKeyPEM">
                                <v-col cols="12" md="6">
                                    <v-textarea
                                        outlined
                                        readonly
                                        success
                                        :label="$t('public_key')"
                                        :value="pubKeyPEM"
                                    ></v-textarea>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-textarea
                                        outlined
                                        readonly
                                        :label="$t('private_key')"
                                        error
                                        :disabled="!prvKeyPEM"
                                        :value="prvKeyPEM"
                                    ></v-textarea>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col align='right' justify="right">
                                    <v-btn large color="primary" @click="generateKey" v-if="!pubKeyPEM">
                                        <v-icon class="mr-2">mdi-key-change</v-icon> {{ $t('generate_key_pairs') }}
                                    </v-btn>
                                    <v-btn v-else-if="revoke" large color="error" @click="requestToRevoke" :loading="revokeLoading" :disabled="revokeLoading">
                                        <v-icon class="mr-2">mdi-alert</v-icon> {{ $t('revoke_keys') }}
                                    </v-btn>
                                    <v-btn v-else large color="error" @click="downloadKey" >
                                        <v-icon class="mr-2">mdi-download-lock</v-icon> {{ $t('download_keys') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row v-else class="mt-10 mb-5" :key="0" align="center" justify="center">
                        <v-progress-linear indeterminate color="primary"></v-progress-linear>
                        <h4 class="mt-3">{{ $t('please_wait') }}</h4>
                    </v-row>
                </transition>
            </v-col>
        </v-row>


        <!-- Get Private Pem file Dialog -->
        <v-dialog
            v-model="getPrvPemDialog"
            max-width="500"
        >
            <v-card>
                <v-card-title class="text-h5">
                    {{ $t('private_key') }}!
                </v-card-title>

                <v-card-text align="center" justify="center">
                    {{ $t('choose_prv_key') }}
                </v-card-text>

                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-file-input
                                v-model="prvKeyFile"
                                color="primary darken-1"
                                :label="$t('prv_pem_file')"
                                :placeholder="$t('select_file')"
                                prepend-icon="mdi-key"
                                outlined
                                :disabled="loading"
                                :error="!prvKeyFile && pemError"
                            >
                            </v-file-input>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="error darken-1"
                        text
                        @click="getPrvPemDialog = false; revokeLoading = false"
                    >
                        {{ $t('cancel') }}
                    </v-btn>

                    <v-btn
                        color="primary darken-1"
                        text
                        :disabled="!prvKeyFile"
                        @click="getPrvPemDialog = false; requestToRevoke()"
                    >
                        {{ $t('save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>‍
    </v-container>
</template>

<script>

export default {
    data() {
        return {
            loading: true,
            prvKeyPEM: null,
            pubKeyPEM: null,
            prvKeyHEX: null,
            myUrl: '',
            myFilename: '',
            revoke: false,
            revokeData: null,
            revokeLoading: false,
            deleteConformDialog: false,

            getPrvPemDialog: false,
            prvKeyFile: null,
            prvKeyText: null,
            pemError: null,
        };
    },
    mounted(){
        this.getMe()
    },
    methods: {
        getMe(){
            this.loading = true
            this.$store.dispatch('getMe')
            .then(resp => {
                this.pubKeyPEM = resp.public_key
                if (resp.public_key) {
                    this.prvKeyPEM = this.$t('prv_dont_access')
                    this.revoke = true
                }
                this.loading = false
            })
        },

        generateKey() {
            if (!this.revokeLoading) this.loading = true

            setTimeout(() => {
                let password = this.$helpers.generatePassword(128)
                let key = this.RSA.KEYUTIL.generateKeypair("RSA", 2048);
                let pubKeyPEM = this.RSA.KEYUTIL.getPEM(key.pubKeyObj);
                let prvKeyPEM = this.RSA.KEYUTIL.getPEM(
                    key.prvKeyObj,
                    "PKCS8PRV"
                );
                this.pubKeyPEM = pubKeyPEM
                this.prvKeyPEM = prvKeyPEM

                setTimeout(() => {
                    this.loading = false

                    if (this.revokeLoading){
                        this.revoke = false

                        this.revokeData.public_key = this.pubKeyPEM

                        // Encode Accessed Files Keys
                        let accessFiles = this.revokeData.fileAccess
                        let newAccessFiles = []
                        for (let i = 0; i < accessFiles.length; i++) {
                            const accessFile = accessFiles[i];
                            // Encode key
                            let key = this.encryptedData(this.pubKeyPEM, accessFile.key.key)
                            let iv = this.encryptedData(this.pubKeyPEM, accessFile.key.iv)

                            accessFile.key = JSON.stringify({
                                key: key,
                                iv: iv,
                            })
                            newAccessFiles.push(accessFile)

                        }
                        this.revokeData.fileAccess = newAccessFiles

                        // Encode Files Keys
                        let files = this.revokeData.files
                        let newFiles = []
                        for (let i = 0; i < files.length; i++) {
                            const f = files[i];
                            // Encode key
                            let key = this.encryptedData(this.pubKeyPEM, f.key.key)
                            let iv = this.encryptedData(this.pubKeyPEM, f.key.iv)

                            f.key = JSON.stringify({
                                key: key,
                                iv: iv,
                            })
                            newFiles.push(f)
                        }
                        this.revokeData.files = newFiles
                    }
                }, 1500);
            }, 2500);
        },

        downloadKey(){
            if (this.revokeLoading){
                this.updateKeys()
                return
            }
            this.loading = true
            // Set Public Key To DB
            this.$store.dispatch('setPubKey', { public_key: this.pubKeyPEM })
            .then(resp => {
                this.loading = false
                // Create File & Download
                var element = document.createElement('a');
                element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.prvKeyPEM));
                element.setAttribute('download', 'PrivateKey.pem');
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);

                this.prvKeyPEM = null
            })
            .catch(err => {
                this.loading = false
                this.$helpers.showError(err)
            })
        },

         requestToRevoke(){
            this.revokeLoading = true
            // Check Private Key
            if (!this.prvKeyFile) {
                this.getPrvPemDialog = true
                return
            }
            this.getPrvPemDialog = false
            this.encodingPrvKey()
            
        },

        sendRequestToRevoke(){
            this.$store.dispatch('getRequestToRevokeKeys')
            .then(resp => {
                this.revokeData = resp
                // Decode Accessed Files Keys
                let accessFiles = resp.fileAccess
                let newAccessFiles = []
                for (let i = 0; i < accessFiles.length; i++) {
                    const accessFile = accessFiles[i];
                    // Decode key
                    let key = this.decryptData(this.prvKeyText, accessFile.key.key)
                    let iv = this.decryptData(this.prvKeyText, accessFile.key.iv)

                    accessFile.key = {
                        key: key,
                        iv: iv,
                    }
                    newAccessFiles.push(accessFile)

                }
                this.revokeData.fileAccess = newAccessFiles

                // Decode Files Keys
                let files = resp.files
                let newFiles = []
                for (let i = 0; i < files.length; i++) {
                    const f = files[i];
                    // Decode key
                    let key = this.decryptData(this.prvKeyText, f.key.key)
                    let iv = this.decryptData(this.prvKeyText, f.key.iv)

                    f.key = {
                        key: key,
                        iv: iv,
                    }
                    newFiles.push(f)
                }
                this.revokeData.files = newFiles

                // Generate New Keys
                this.generateKey()
                
            })
            .catch(err => {
                this.$helpers.showError(err)
                this.revokeLoading = false
            })
        },

        updateKeys(){
            this.getVerifyKey()
            .then(resp => {
                let verifyKeyDecoded = this.decryptData(this.prvKeyText, resp.verify_key)
            
                this.$helpers.showMessage('info', this.$t('downloading'))

                this.downloadKeyLoading = true
                this.$store.dispatch('updateKeys', {
                    data: this.revokeData,
                    verifyKey: verifyKeyDecoded
                })
                .then(resp => {
                    // Create File & Download
                    var element = document.createElement('a');
                    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.prvKeyPEM));
                    element.setAttribute('download', 'NewPrivateKey.pem');
                    element.style.display = 'none';
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);

                    this.prvKeyPEM = null
                    this.revokeLoading = false
                    this.revokeData = null
                    this.revoke = true
                    this.$helpers.showMessage('success', this.$t('key_updated'))
                    this.downloadKeyLoading = false
                })
                .catch(err => {
                    this.$helpers.showError(err)
                    this.revokeLoading = false
                    this.downloadKeyLoading = false
                })
            })
            .catch(err => {
                this.$helpers.showError(err)
            })
        },

        //  encryption
        encryptedData(publicKey, data) {
            // New JSEncrypt object
            let encryptor = new this.$rsa();
            // Setting public key
            encryptor.setPublicKey(publicKey);
            // Encrypted data
            return encryptor.encrypt(data);
        },
        // Decrypt
        decryptData(privateKey,data){
            // New JSEncrypt object
            let decrypt= new this.$rsa();
            // Set private key
            decrypt.setPrivateKey(privateKey);
            // Declassified data
            return decrypt.decrypt(data);
        },

        encodingPrvKey(){
            var fileReader = new FileReader();
            var that = this
            fileReader.onload = function(fileLoadedEvent){
                var textFromFileLoaded = fileLoadedEvent.target.result;
                that.prvKeyText = textFromFileLoaded

                // Check Validation
                let result = that.$helpers.prvKeyValidator(that.pubKeyPEM, textFromFileLoaded)
                if (!result) {
                    that.prvKeyFile = null
                    that.prvKeyText = null
                    that.pemError = true
                    that.$helpers.showMessage('error', that.$t('prv_pem_invalid'))
                    that.getPrvPemDialog = true
                }
                else {
                    that.sendRequestToRevoke()
                }
            };

            fileReader.readAsText(that.prvKeyFile, "UTF-8");
        },
        
        async getVerifyKey(){
            return new Promise((resolve, reject) => {
                 this.$store.dispatch('getVerifyKey')
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
            })
        }
    }
};
</script>